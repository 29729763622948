.des-text {
  overflow: auto;
  width: 100%;
  height: 55px;
  font-size: 12px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 1.43;
  color: #7e84a3;
}

.text {
  display: contents;
  width: 100%;
}

.read-or-hide {
  color: #1976d2;
  cursor: pointer;
}

.sta-green-text {
  margin-left: 1.5px;
  display: flex;
  align-items: center;
  margin-top: 2px;
  font-size: 13px;
}

.detail-admin-gigs {
  margin-left: auto;
  margin-top: 70px;
}

@media only screen and (min-width: 320px) and (max-width: 798px) {
  .detail-admin-gigs {
    margin-top: 40px;
  }
}

.status-gig-admin-details {
  display: flex;
  border-radius: 5px;
  font-size: 10px;
  font-family: Arial;
  font-weight: 700;
  font-style: normal;
  line-height: 11px;
  text-transform: capitalize;
  height: 27px;
  width: 74px;
  text-align: center;
  justify-content: center;
}

.status-active {
  border: 1.5px solid #63a46c;
  color: #63a46c;
}

.status-inactive {
  border: 1.5px solid #ff4140;
  color: #ff4140;
}

.status-draft {
  border: 1.5px solid #f9d100;
  color: #f9d100;
}

.status-closed {
  border: 1.5px solid #595757;
  color: #595757;
}

.new-post-editor-gig-details {
  min-height: 300px;
}

.read-more-state {
  display: none;
}

.read-more-target {
  opacity: 0;
  max-height: 0;
  font-size: 0;
  transition: 0.25s ease;
}

.read-more-state:checked ~ .read-more-wrap .read-more-target {
  opacity: 1;
  font-size: inherit;
  max-height: 999em;
}

.read-more-state ~ .read-more-trigger:before {
  content: 'Show more';
}

.read-more-state:checked ~ .read-more-trigger:before {
  content: 'Show less';
}

.read-more-trigger {
  cursor: pointer;
  display: inline-block;
  padding: 0 0.5em;
  color: #666;
  font-size: 0.9em;
  line-height: 2;
  border: 1px solid #ddd;
  border-radius: 0.25em;
}

.gig-details-main-container {
  width: 100%;
}

.gig-details-main-container-1 {
  display: flex;
  margin: 10px 20px 30px 0px;
  justify-content: space-between;
  gap: 25px;
}

@media only screen and (min-width: 300px) and (max-width: 1200px) {
  .gig-details-main-container-1 {
    width: 100%;
    margin-top: 100px;
    display: block;
  }
}

.gig-detail-see-more {
  width: 100%;
}

.Profile-Container-gig-detail {
  padding: 20px;
  margin-bottom: 20px;
}

@media only screen and (min-width: 300px) and (max-width: 400px) {
  .Profile-Container-gig-detail {
    padding: 2px;
  }
}

.Profile-Name-gig-detail {
  margin-top: 5px;
  color: #001e00;
  font-family: Poppins;
  font-style: normal;
  font-size: 20px;
  font-weight: 700;
}

@media only screen and (min-width: 300px) and (max-width: 400px) {
  .Profile-Name-gig-detail {
    font-size: 20px;
  }
}

.Gig-link-and-FieldContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.Field-Container-gig-details {
  margin-left: 18px;
  width: 100%;
}

@media only screen and (min-width: 300px) and (max-width: 400px) {
  .Field-Container-gig-details {
    margin-left: 0px;
  }
}

.FieldOf-SpecializationText {
  font-family: Poppins;
  color: #001e00;
  font-style: normal;
  font-size: 22px;
  font-weight: 400;
  margin-left: 0px;
  margin-bottom: -5px;
  width: 100%;
  word-break: break-word;
}

@media only screen and (min-width: 300px) and (max-width: 500px) {
  .FieldOf-SpecializationText {
    font-family: Poppins;
    color: #001e00;
    font-style: normal;
    font-size: 18px;
    font-weight: 400;
    margin-left: 0px;
  }
}

@media only screen and (min-width: 500px) and (max-width: 790px) {
  .FieldOf-SpecializationText {
    font-family: Poppins;
    color: #001e00;
    font-style: normal;
    font-size: 18px;
    font-weight: 400;
    margin-top: -10px;
  }
}

.FieldOf-JobType {
  font-family: Poppins;
  color: #001e00;
  font-style: normal;
  font-size: 15px;
  font-weight: 400;
  margin-left: 0px;
  margin-bottom: -5px;
  width: 100%;
  word-break: break-word;
}

@media only screen and (min-width: 300px) and (max-width: 500px) {
  .FieldOf-JobType {
    font-family: Poppins;
    color: #001e00;
    font-style: normal;
    font-size: 15px;
    font-weight: 400;
    margin-left: 0px;
  }
}

@media only screen and (min-width: 500px) and (max-width: 790px) {
  .FieldOf-JobType {
    font-family: Poppins;
    color: #001e00;
    font-style: normal;
    font-size: 15px;
    font-weight: 400;
    margin-top: -10px;
  }
}

.Created-AtText {
  margin-top: 18px;
  color: #5e6d55;
  font-family: Poppins;
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 14px;
}

@media only screen and (min-width: 300px) and (max-width: 390px) {
  .Created-AtText {
    color: #5e6d55;
    font-family: Poppins;
    font-style: normal;
    font-size: 10px;
    font-weight: 400;
    margin-bottom: 20px;
  }
}

@media only screen and (min-width: 390px) and (max-width: 500px) {
  .Created-AtText {
    color: #5e6d55;
    font-family: Poppins;
    font-style: normal;
    font-size: 12px;
    font-weight: 400;
    margin-bottom: 19px;
  }
}

@media only screen and (min-width: 500px) and (max-width: 790px) {
  .Created-AtText {
    color: #5e6d55;
    font-family: Poppins;
    font-style: normal;
    font-size: 15px;
    font-weight: 400;
    margin-bottom: 12px;
  }
}

.Gig-link-gig-details {
  border: 1px solid #d5e0d5;
  width: 260px;
  height: 110px;
  padding: 0px 10px 0px 20px;
}

@media only screen and (min-width: 500px) and (max-width: 800px) {
  .Gig-link-gig-details {
    width: 200px;
    height: 110px;
    margin-top: -32px;
  }
}

@media only screen and (min-width: 380px) and (max-width: 500px) {
  .Gig-link-gig-details {
    width: 170px;
    height: 110px;
    margin-top: -28px;
  }
}

@media only screen and (min-width: 321px) and (max-width: 380px) {
  .Gig-link-gig-details {
    width: 150px;
    height: 90px;
    margin-top: -10px;
  }
}

@media only screen and (min-width: 300px) and (max-width: 321px) {
  .Gig-link-gig-details {
    width: 130px;
    height: 90px;
    margin-top: -15px;
  }
}

.Gig-link-Text {
  color: #000000;
  font-family: inter;
  font-style: normal;
  font-size: 15px;
  font-weight: 400;
  margin: 5px;
}

@media only screen and (min-width: 300px) and (max-width: 400px) {
  .Gig-link-Text {
    font-size: 10px;
    margin: 13px;
  }
}

.Copy-Gig-link-Btn {
  color: #0160bb;
  font-style: inter;
  font-style: normal;
  font-size: 15px;
  font-weight: 400;
  text-transform: none;
  cursor: pointer;
}

@media only screen and (min-width: 300px) and (max-width: 400px) {
  .Copy-Gig-link-Btn {
    font-size: 10px;
  }
}

@media only screen and (min-width: 400px) and (max-width: 500px) {
  .Copy-Gig-link-Btn {
    font-size: 12px;
  }
}

.Applied-Box {
  border: 1px solid #01ab66;
  display: flex;
  border-radius: 5px;
  width: 152px;
  height: 46px;
  justify-content: space-evenly;
  align-items: center;
}

@media only screen and (min-width: 300px) and (max-width: 428px) {
  .Applied-Box {
    width: 90px;
    height: 40px;
  }
}

@media only screen and (min-width: 428px) and (max-width: 758px) {
  .Applied-Box {
    width: 120px;
    height: 40px;
  }
}

@media only screen and (min-width: 758px) and (max-width: 767px) {
  .Applied-Box {
    width: 90px;
    height: 40px;
  }
}

.Applied-Text {
  font-size: 20px;
  font-weight: 500;
  font-family: Poppins;
  color: #01ab66;
}

@media only screen and (min-width: 300px) and (max-width: 322px) {
  .Applied-Text {
    font-size: 13px;
  }
}

@media only screen and (min-width: 377px) and (max-width: 428px) {
  .Applied-Text {
    font-size: 15px;
  }
}

@media only screen and (min-width: 322px) and (max-width: 377px) {
  .Applied-Text {
    font-size: 12px;
  }
}

@media only screen and (min-width: 428px) and (max-width: 700px) {
  .Applied-Text {
    font-size: 15px;
  }
}

@media only screen and (min-width: 700px) and (max-width: 767px) {
  .Applied-Text {
    font-size: 18px;
  }
}

.No-Longer-Apct {
  border: 1px solid #ff0000;
  display: flex;
  border-radius: 5px;
  width: 450px;
  height: 46px;
  justify-content: space-evenly;
}

@media only screen and (min-width: 300px) and (max-width: 322px) {
  .No-Longer-Apct {
    width: 168px;
    height: 35px;
    margin-top: 62px;
  }
}

@media only screen and (min-width: 322px) and (max-width: 377px) {
  .No-Longer-Apct {
    width: 200px;
    height: 40px;
    margin-top: 37px;
  }
}

@media only screen and (min-width: 377px) and (max-width: 427px) {
  .No-Longer-Apct {
    width: 243px;
    height: 40px;
    margin-top: 37px;
  }
}

@media only screen and (min-width: 427px) and (max-width: 500px) {
  .No-Longer-Apct {
    width: 250px;
    height: 45px;
  }
}

@media only screen and (min-width: 500px) and (max-width: 580px) {
  .No-Longer-Apct {
    width: 290px;
    height: 45px;
  }
}

@media only screen and (min-width: 580px) and (max-width: 680px) {
  .No-Longer-Apct {
    width: 320px;
    height: 45px;
  }
}

@media only screen and (min-width: 680px) and (max-width: 880px) {
  .No-Longer-Apct {
    width: 380px;
    height: 45px;
    margin-top: 35px;
  }
}

@media only screen and (min-width: 880px) and (max-width: 928px) {
  .No-Longer-Apct {
    width: 380px;
    height: 45px;
  }
}

@media only screen and (min-width: 928px) and (max-width: 1380px) {
  .No-Longer-Apct {
    width: 400px;
    height: 45px;
    margin-top: 58px;
  }
}

@media only screen and (min-width: 1380px) and (max-width: 1880px) {
  .No-Longer-Apct {
    width: 460px;
    height: 45px;
    margin-top: 58px;
  }
}

@media only screen and (min-width: 1880px) and (max-width: 2280px) {
  .No-Longer-Apct {
    width: 580px;
    height: 45px;
  }
}

.No-Longer-Apct-Text {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 500;
  color: #ff0000;
  margin-top: 9px;
}

@media only screen and (min-width: 1380px) and (max-width: 1880px) {
  .No-Longer-Apct-Text {
    font-size: 15px;
    margin-top: 9px;
    padding: 0px;
  }
}

@media only screen and (min-width: 928px) and (max-width: 1380px) {
  .No-Longer-Apct-Text {
    font-size: 10px;
    margin-top: 9px;
    padding: 0px;
  }
}

@media only screen and (min-width: 680px) and (max-width: 928px) {
  .No-Longer-Apct-Text {
    font-size: 10px;
    margin-top: 9px;
    padding: 0px;
  }
}

@media only screen and (min-width: 580px) and (max-width: 680px) {
  .No-Longer-Apct-Text {
    font-size: 12px;
    margin-top: 9px;
    padding: 0px;
  }
}

@media only screen and (min-width: 427px) and (max-width: 500px) {
  .No-Longer-Apct-Text {
    font-size: 10px;
    margin-top: 5px;
    padding: 0px;
  }
}

@media only screen and (min-width: 377px) and (max-width: 427px) {
  .No-Longer-Apct-Text {
    font-size: 9px;
    margin-top: 5px;
    padding: 0px;
  }
}

@media only screen and (min-width: 322px) and (max-width: 477px) {
  .No-Longer-Apct-Text {
    font-size: 8px;
    margin-top: 7px;
    padding: 0px;
  }
}

@media only screen and (min-width: 300px) and (max-width: 321px) {
  .No-Longer-Apct-Text {
    font-size: 7px;
    margin-top: 5px;
    padding: 0px;
  }
}

.Project-Detail-Container {
  padding: 20px;
}

@media only screen and (min-width: 300px) and (max-width: 400px) {
  .Project-Detail-Container {
    padding: 0px;
  }
}

.project-title-see-more-btn {
  cursor: pointer;
  border: white;
  background-color: white;
  font-family: Poppins, 'sansSerif';
  color: #1976d2;
  font-size: 15.5px;
  font-weight: 500;
  margin-left: -6px;
}

.PD-title-Text {
  color: #000000;
  font-family: Poppins;
  font-style: normal;
  font-size: 18px;
  font-weight: 700;
}

.Skills-Container-gig-details {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  padding: 20px;
  gap: 10px;
}

@media only screen and (min-width: 300px) and (max-width: 330px) {
  .Skills-Container-gig-details {
    grid-template-columns: repeat(2, 1fr);
    padding: 0px;
    margin-left: -14px;
  }
}

@media only screen and (min-width: 330px) and (max-width: 390px) {
  .Skills-Container-gig-details {
    grid-template-columns: repeat(2, 1fr);
    padding: 0px;
    margin-left: -14px;
  }
}

@media only screen and (min-width: 390px) and (max-width: 790px) {
  .Skills-Container-gig-details {
    grid-template-columns: repeat(2, 1fr);
    padding: 0px;
  }
}

@media only screen and (min-width: 300px) and (max-width: 790px) {
  .Skills-Required {
    margin-left: 15px;
  }
}

.Skills-Required-Flex {
  display: flex;
  gap: 5px;
  align-items: center;
}

.Skills-Required-title-Text {
  font-family: Poppins;
  font-style: normal;
  font-size: 15px;
  font-weight: 400;
}

@media only screen and (min-width: 300px) and (max-width: 400px) {
  .Skills-Required-title-Text {
    font-size: 14px;
    font-weight: 400;
    gap: 20px;
  }
}

.Skills-Required-Location {
  margin: -8px 0px 0px 25px;
  color: #5e6d55;
  font-family: Poppins;
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  margin-left: 29px;
  margin-top: 7px;
}

@media only screen and (min-width: 320px) and (max-width: 500px) {
  .Skills-Required-Location {
    font-size: 13px;
    font-weight: 400;
    margin-left: 30px;
    gap: 20px;
  }
}

.Skills-Required-subText {
  margin: -8px 0px 0px 25px;
  color: #5e6d55;
  font-family: Poppins;
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  margin-left: 29px;
  margin-top: 7px;
}

@media only screen and (min-width: 320px) and (max-width: 500px) {
  .Skills-Required-subText {
    font-size: 13px;
    font-weight: 400;
    gap: 20px;
  }
}

.Skill-Required-Budget {
  margin: -8px 0px 0px 25px;
  color: #5e6d55;
  font-family: Poppins;
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  margin-left: 29px;
  margin-top: 7px;
}

@media only screen and (min-width: 320px) and (max-width: 500px) {
  .Skill-Required-Budget {
    font-size: 13px;
    font-weight: 400;
    gap: 20px;
    margin-left: 30px;
  }
}

.Skills-Required-main-Container {
  padding: 20px;
}

@media only screen and (min-width: 320px) and (max-width: 400px) {
  .Skills-Required-main-Container {
    padding: 0px;
  }
}

.Skills-Required-Container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 0px;
  align-items: center;
  gap: 20px;
}

@media only screen and (min-width: 320px) and (max-width: 400px) {
  .Skills-Required-Container {
    display: grid;
    justify-content: flex-start;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    padding: 0px;
    align-items: center;
    margin-right: 20px;
  }
}

@media only screen and (min-width: 400px) and (max-width: 426px) {
  .Skills-Required-Container {
    display: grid;
    justify-content: flex-start;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    padding: 0px;
    align-items: center;
    margin-right: 20px;
  }
}

@media only screen and (min-width: 426px) and (max-width: 526px) {
  .Skills-Required-Container {
    display: grid;
    justify-content: flex-start;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    padding: 0px;
    align-items: center;
    margin-right: 20px;
  }
}

@media only screen and (min-width: 526px) and (max-width: 820px) {
  .Skills-Required-Container {
    display: grid;
    justify-content: flex-start;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    padding: 0px;
    align-items: center;
    margin-right: 20px;
  }
}

.Title-Text {
  color: #000000;
  font-family: Poppins;
  font-style: normal;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 15px;
}

.tools-tech-Text {
  color: #000000;
  font-family: Poppins;
  font-style: normal;
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 15px;
  margin-top: 21px;
}

.Tools-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 0px;
  align-items: center;
  gap: 20px;
}

@media only screen and (min-width: 320px) and (max-width: 400px) {
  .Tools-container {
    display: grid;
    justify-content: flex-start;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    padding: 0px;
    align-items: center;
    margin-right: 20px;
  }
}

@media only screen and (min-width: 400px) and (max-width: 426px) {
  .Tools-container {
    display: grid;
    justify-content: flex-start;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    padding: 0px;
    align-items: center;
    margin-right: 20px;
  }
}

@media only screen and (min-width: 426px) and (max-width: 526px) {
  .Tools-container {
    display: grid;
    justify-content: flex-start;
    width: 100%;
    grid-template-columns: repeat(2, 1fr);
    padding: 0px;
    align-items: center;
    margin-right: 20px;
  }
}

@media only screen and (min-width: 526px) and (max-width: 820px) {
  .Tools-container {
    display: grid;
    justify-content: flex-start;
    width: 100%;
    grid-template-columns: repeat(3, 1fr);
    padding: 0px;
    align-items: center;
    margin-right: 20px;
  }
}

.Tand-Ccontainer {
  padding: 20px;
}

@media only screen and (min-width: 300px) and (max-width: 400px) {
  .Tand-Ccontainer {
    padding: 0px;
  }
}

.TandC-title-Text {
  color: #000000;
  font-family: Poppins;
  font-style: normal;
  font-size: 18px;
  font-weight: 700;
  margin-top: 21px;
}

.TandC-Sub-Text {
  color: #001e00;
  font-family: Poppins;
  font-style: normal;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
}

.TandC-Sub-Text-Title {
  color: #001e00;
  font-family: Poppins;
  font-style: normal;
  font-size: 17px;
  font-weight: 600;
  margin-top: 13px;
}

.Attactment-Container-gig-detail {
  padding: 20px;
}

@media only screen and (min-width: 319px) and (max-width: 390px) {
  .Attactment-Container-gig-detail {
    margin-left: -19px;
  }
}

.Attactment-title-Text-gig {
  color: #000000;
  font-family: Poppins;
  font-style: normal;
  font-size: 18px;
  font-weight: 700;
}

.attachment-icon-gig-details {
  display: flex;
  align-items: center;
}

.Att-Btn-Text {
  color: #0543ac;
}

@media only screen and (min-width: 319px) and (max-width: 376px) {
  .Att-Btn-Text {
    color: #0543ac;
    margin-left: 20px;
    font-size: 6px;
  }
}

@media only screen and (min-width: 376px) and (max-width: 426px) {
  .Att-Btn-Text {
    color: #0543ac;
    margin-left: 2px;
    font-size: 8px;
  }
}

.att-not-provided-msg {
  font-size: 14px;
}

.Cover-Letter-Container {
  padding: 20px;
}

@media only screen and (min-width: 300px) and (max-width: 400px) {
  .Cover-Letter-Container {
    padding: 1px;
  }
}

.Cover-Letter-title-Text {
  color: #000000;
  font-family: Poppins;
  font-style: normal;
  font-size: 18px;
  font-weight: 500;
  display: flex;
  margin-bottom: 20px;
}

.Cover-Letter-SubText {
  color: #b4b4b4;
  font-family: Poppins;
  font-style: normal;
  font-size: 18px;
  font-weight: 500;
  margin-left: 7px;
}

.error-msg-cover-letter {
  color: #d32f2f;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.66px;
  margin-top: 13px;
}

.drag-drop-gig-details {
  margin-top: 4px;
}

.file-upload-icon-detail{
  margin-top: -2px;
}

.Add-Attachment-Text {
  color: #ff8008;
  font-family: Poppins;
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  text-transform: none;
  align-items: center;
  display: flex;
  border: 1.5px dashed #ff8008;
  border-radius: 10px;
  padding-bottom: 11px;
  padding-top: 11px;
  padding-left: 10px;
  padding-right: 10px;
  margin-top: 13px;
  margin-left: 12px;
}

@media only screen and (min-width: 300px) and (max-width: 400px) {
  .Add-Attachment-Text {
    font-size: 13px;
  }
}

.Add-Attachment-TextAfter-Upload {
  color: #ff8008;
  font-family: Poppins;
  font-style: normal;
  font-size: 16px;
  font-weight: 400;
  text-transform: none;
  align-items: center;
  display: flex;
  border: 1.5px dashed #ff8008;
  border-radius: 10px;
  padding-bottom: 11px;
  padding-top: 11px;
  padding-left: 24px;
  padding-right: 10px;
  width: 241px;
  margin-top: 20px;
}

@media only screen and (min-width: 300px) and (max-width: 400px) {
  .Add-Attachment-TextAfter-Upload {
    font-size: 13px;
  }
}

.break-line-gig-details {
  border: 1px solid #d5e0d5;
  width: 100%;
  height: 0px;
  margin: 5px;
}

.Back-Btn-Main-Container {
  justify-content: flex-end;
  display: flex;
}

.Back-Apply-Checkbox-Flex {
  display: flex;
  justify-content: space-around;
  margin-top: 20px;
  align-items: center;
  width: 32%;
}

@media only screen and (min-width: 300px) and (max-width: 322px) {
  .Back-Apply-Checkbox-Flex {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    align-items: center;
    width: 100%;
    gap: 1px;
  }
}

@media only screen and (min-width: 322px) and (max-width: 400px) {
  .Back-Apply-Checkbox-Flex {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    align-items: center;
    width: 150%;
  }
}

@media only screen and (min-width: 400px) and (max-width: 582px) {
  .Back-Apply-Checkbox-Flex {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    align-items: center;
    width: 100%;
  }
}

@media only screen and (min-width: 582px) and (max-width: 1142px) {
  .Back-Apply-Checkbox-Flex {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
    align-items: center;
    width: 100%;
    gap: 10px;
  }
}

.Checkbox-Text-flex {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media only screen and (min-width: 300px) and (max-width: 322px) {
  .Checkbox-Text-flex {
    width: 30%;
    height: 25px;
    margin-left: -20px;
  }
}

@media only screen and (min-width: 322px) and (max-width: 388px) {
  .Checkbox-Text-flex {
    width: 100%;
    height: 25px;
    margin-left: 60px;
  }
}

@media only screen and (min-width: 388px) and (max-width: 584px) {
  .Checkbox-Text-flex {
    width: 100%;
    height: 25px;
    margin-left: 70px;
  }
}

@media only screen and (min-width: 584px) and (max-width: 800px) {
  .Checkbox-Text-flex {
    width: 30%;
    height: 35px;
    margin-left: 0px;
  }
}

.CL-text {
  color: #ff8008;
  font-family: Poppins;
  font-size: 15px;
}

@media only screen and (min-width: 300px) and (max-width: 330px) {
  .CL-text {
    font-size: 13px;
  }
}

@media only screen and (min-width: 330px) and (max-width: 398px) {
  .CL-text {
    font-size: 13px;
  }
}

@media only screen and (min-width: 398px) and (max-width: 583px) {
  .CL-text {
    font-size: 13px;
  }
}

@media only screen and (min-width: 583px) and (max-width: 800px) {
  .CL-text {
    font-size: 15px;
  }
}

.SubText-skills-gig-details {
  gap: 10px;
  display: flex;
  width: auto;
  justify-content: space-around;
  border: 1px solid #bdbdbd;
  background-color: white;
  border-radius: 15px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 7px;
  padding-right: 7px;
}

.SubText-skills-gig-details-undefined{
  gap: 10px;
  display: flex;
  width: max-content;
  justify-content: space-around;
  border: 1px solid #bdbdbd;
  background-color: white;
  border-radius: 15px;
  padding-top: 4px;
  padding-bottom: 4px;
  padding-left: 12px;
  padding-right: 12px;
}

.main-container-gig-details-page {
  display: flex;
  margin: 0px 0px 30px 0px;
  justify-content: space-between;
  width: 100%;
}


.main-container-gig-details-page {
  display: flex;
  margin: 0px 0px 30px 0px;
  justify-content: space-between;
  width: 100%;
}

@media only screen and (min-width: 300px) and (max-width: 400px) {
  .main-container-gig-details-page {
    display: flex;
    margin: 0px 0px 0px 0px;
    flex-direction: column;
  }
}

@media only screen and (min-width: 400px) and (max-width: 790px) {
  .main-container-gig-details-page {
    display: flex;
    flex-direction: column;
  }
}

.Detail-View-Container {
  width: 100%;
  height: 100%;
  border: 1px solid #dad7d7;
  border-radius: 10px;
  box-shadow: 1px 2px 9px lightgrey;
  margin-top: 30px;
}

.Edge-gig {
  padding: 30px;
}

.gig-details-BackBtn {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  margin-top: 30px;
  align-items: center;
}

.public-view-details {
  width: 100%;
  height: 100%;
  border: 1px solid #dad7d7;
  border-radius: 10px;
  box-shadow: 1px 2px 9px lightgrey;
}

.public-view-details-1 {
  width: 82%;
  height: 100%;
  border: 1px solid #dad7d7;
  border-radius: 10px;
  box-shadow: 1px 2px 9px lightgrey;
  margin-top: 53px;
}

@media only screen and (min-width: 300px) and (max-width: 322px) {
  .public-view-details-1 {
    width: 300px;
    margin-bottom: 20px;
    align-items: center;
    margin-left: -239px;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 322px) and (max-width: 376px) {
  .public-view-details-1 {
    width: 350px;
    margin-bottom: 20px;
    align-items: center;
    margin-left: -238px;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 376px) and (max-width: 426px) {
  .public-view-details-1 {
    width: 390px;
    margin-bottom: 20px;
    align-items: center;
    margin-left: -233px;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 426px) and (max-width: 480px) {
  .public-view-details-1 {
    width: 420px;
    margin-left: -236px;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 580px) {
  .public-view-details-1 {
    width: 480px;
    margin-left: -236px;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 580px) and (max-width: 680px) {
  .public-view-details-1 {
    width: 600px;
    margin-left: -217px;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 680px) and (max-width: 790px) {
  .public-view-details-1 {
    width: 700px;
    margin-left: -217px;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 790px) and (max-width: 890px) {
  .public-view-details-1 {
    width: 800px;
    margin-left: -217px;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 890px) and (max-width: 1090px) {
  .public-view-details-1 {
    width: 950px;
    margin-left: -210px;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 1090px) and (max-width: 1112px) {
  .public-view-details-1 {
    width: 1000px;
    margin-left: -210px;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 1112px) and (max-width: 1402px) {
  .public-view-details-1 {
    width: 1200px;
    margin-left: -210px;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 300px) and (max-width: 322px) {
  .public-view-details {
    width: 300px;
    margin-bottom: 20px;
    align-items: center;
    margin-left: -239px;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 322px) and (max-width: 376px) {
  .public-view-details {
    width: 350px;
    margin-bottom: 20px;
    align-items: center;
    margin-left: -238px;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 376px) and (max-width: 426px) {
  .public-view-details {
    width: 390px;
    margin-bottom: 20px;
    align-items: center;
    margin-left: -233px;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 426px) and (max-width: 480px) {
  .public-view-details {
    width: 420px;
    margin-left: -236px;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 480px) and (max-width: 580px) {
  .public-view-details {
    width: 480px;
    margin-left: -236px;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 580px) and (max-width: 680px) {
  .public-view-details {
    width: 600px;
    margin-left: -217px;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 680px) and (max-width: 790px) {
  .public-view-details {
    width: 700px;
    margin-left: -217px;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 790px) and (max-width: 890px) {
  .public-view-details {
    width: 800px;
    margin-left: -217px;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 890px) and (max-width: 1090px) {
  .public-view-details {
    width: 950px;
    margin-left: -210px;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 1090px) and (max-width: 1112px) {
  .public-view-details {
    width: 1000px;
    margin-left: -210px;
    margin-top: 20px;
  }
}

@media only screen and (min-width: 1112px) and (max-width: 1402px) {
  .public-view-details {
    width: 1200px;
    margin-left: -210px;
    margin-top: 20px;
  }
}

.Edge-pub-pagging {
  padding: 55px;
}

@media only screen and (min-width: 300px) and (max-width: 400px) {
  .Edge-pub-pagging {
    padding: 25px;
    margin-right: 0px;
  }
}

@media only screen and (min-width: 400px) and (max-width: 426px) {
  .Edge-pub-pagging {
    padding: 5px;
    margin-right: 0px;
  }
}

@media only screen and (min-width: 426px) and (max-width: 787px) {
  .Edge-pub-pagging {
    padding: 12px;
    margin-right: 0px;
  }
}

.Field-Of-Specialization-Text {
  font-family: Poppins;
  color: #001e00;
  font-style: normal;
  font-size: 28px;
  font-weight: 400;
  margin-left: 15px;
  margin-bottom: -5px;
  width: 90%;
}

@media only screen and (min-width: 300px) and (max-width: 390px) {
  .Field-Of-Specialization-Text {
    font-family: Poppins;
    color: #001e00;
    font-style: normal;
    font-size: 20px;
    font-weight: 400;
    margin-left: 0px;
  }
}

@media only screen and (min-width: 390px) and (max-width: 790px) {
  .Field-Of-Specialization-Text {
    font-family: Poppins;
    color: #001e00;
    font-style: normal;
    font-size: 20px;
    font-weight: 400;
    margin-left: 15px;
  }
}

.GiglinkandField-Container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.Field-Container-Public {
  margin-left: 18px;
}

@media only screen and (min-width: 300px) and (max-width: 400px) {
  .Field-Container-Public {
    margin-left: 0px;
  }
}

.BackApply-Checkbox-Flex {
  display: flex;
  justify-content: space-around;
  width: 50%;
  margin-top: 20px;
  align-items: center;
}

@media only screen and (min-width: 300px) and (max-width: 322px) {
  .BackApply-Checkbox-Flex {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    align-items: center;
    gap: 1px;
  }
}

@media only screen and (min-width: 322px) and (max-width: 400px) {
  .BackApply-Checkbox-Flex {
    display: flex;
    justify-content: center;
    width: 80%;
    align-items: center;
  }
}

@media only screen and (min-width: 400px) and (max-width: 1142px) {
  .BackApply-Checkbox-Flex {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    align-items: center;
    gap: 10px;
  }
}

.Inactive-Closedgig {
  width: 100%;
  height: 604px;
  background-color: #f2f2f2;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 100px 100px 100px -100px;
}

@media only screen and (min-width: 300px) and (max-width: 322px) {
  .Inactive-Closedgig {
    width: 254px;
    height: 600px;
    background-color: #f2f2f2;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 100px 50px 50px -215px;
  }
}

@media only screen and (min-width: 322px) and (max-width: 376px) {
  .Inactive-Closedgig {
    width: 300px;
    height: 600px;
    background-color: #f2f2f2;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 100px 50px 50px -220px;
  }
}

@media only screen and (min-width: 376px) and (max-width: 429px) {
  .Inactive-Closedgig {
    width: 350px;
    height: 554px;
    background-color: #f2f2f2;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 100px 50px 50px -220px;
  }
}

@media only screen and (min-width: 429px) and (max-width: 500px) {
  .Inactive-Closedgig {
    width: 400px;
    height: 554px;
    background-color: #f2f2f2;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 100px 50px 50px -220px;
  }
}

@media only screen and (min-width: 500px) and (max-width: 590px) {
  .Inactive-Closedgig {
    width: 500px;
    height: 554px;
    background-color: #f2f2f2;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 100px 50px 50px -220px;
  }
}

@media only screen and (min-width: 590px) and (max-width: 720px) {
  .Inactive-Closedgig {
    width: 500px;
    height: 554px;
    background-color: #f2f2f2;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 100px 50px 50px -180px;
  }
}

@media only screen and (min-width: 720px) and (max-width: 890px) {
  .Inactive-Closedgig {
    width: 500px;
    height: 604px;
    background-color: #f2f2f2;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 100px 100px 100px -100px;
  }
}

.Link-Text-public-view {
  font-family: Poppins;
  font-size: 25px;
  font-weight: 700;
  color: #000000;
}

@media only screen and (min-width: 300px) and (max-width: 322px) {
  .Link-Text-public-view {
    font-family: Poppins;
    font-size: 12px;
    padding: 5px;
    font-weight: 700;
    color: #000000;
  }
}

@media only screen and (min-width: 322px) and (max-width: 376px) {
  .Link-Text-public-view {
    font-family: Poppins;
    font-size: 13px;
    padding: 5px;
    font-weight: 700;
    color: #000000;
  }
}

@media only screen and (min-width: 376px) and (max-width: 429px) {
  .Link-Text-public-view {
    font-family: Poppins;
    font-size: 15px;
    padding: 5px;
    font-weight: 700;
    color: #000000;
  }
}

@media only screen and (min-width: 429px) and (max-width: 500px) {
  .Link-Text-public-view {
    font-family: Poppins;
    font-size: 18px;
    padding: 5px;
    font-weight: 700;
    color: #000000;
  }
}

.Inactive-Closedgig-Text {
  font-family: Poppins;
  font-size: 20px;
  font-weight: 400;
  color: #000000;
}

@media only screen and (min-width: 300px) and (max-width: 322px) {
  .Inactive-Closedgig-Text {
    font-family: Poppins;
    font-size: 12px;
    padding: 5px;
    font-weight: 400;
    color: #000000;
  }
}

@media only screen and (min-width: 322px) and (max-width: 376px) {
  .Inactive-Closedgig-Text {
    font-family: Poppins;
    font-size: 13px;
    padding: 5px;
    font-weight: 400;
    color: #000000;
  }
}

@media only screen and (min-width: 376px) and (max-width: 429px) {
  .Inactive-Closedgig-Text {
    font-family: Poppins;
    font-size: 15px;
    padding: 5px;
    font-weight: 400;
    color: #000000;
  }
}

@media only screen and (min-width: 429px) and (max-width: 500px) {
  .Inactive-Closedgig-Text {
    font-family: Poppins;
    font-size: 18px;
    padding: 5px;
    font-weight: 400;
    color: #000000;
  }
}

.login-pop-up {
  margin-top: 20px;
  margin-bottom: 20px;
}

.Sign-up-main-container {
  width: 340px;
  border-radius: 10px;
  background-color: #e6f6ff;
  margin-top: 50px;
}

@media only screen and (min-width: 300px) and (max-width: 426px) {
  .Sign-up-main-container {
    width: 330px;
    height: fit-content;
    border-radius: 10px;
    background-color: #e6f6ff;
    margin-left: -220px;
    margin-right: -0px;
  }
}

@media only screen and (min-width: 426px) and (max-width: 800px) {
  .Sign-up-main-container {
    width: 600px;
    height: fit-content;
    border-radius: 10px;
    background-color: #e6f6ff;
    margin-left: -220px;
    margin-right: -0px;
  }
}

.sign-up-login-text {
  text-align: center;
  color: #001e00;
  font-family: Arial, Helvetica, sans-serif;
  font-style: normal;
  font-size: 18px;
  font-weight: 400;
  margin-top: 0px;
  padding: 10px;
}

.MuiButton-root.google-text-btn .not-user-text {
  width: 100%;
  margin-left: 0px;
  font-family: 'Poppins', 'sansSerif';
  font-weight: 500;
}

.MuiButton-root.google-text-btn .not-user-logo {
  margin-left: -5px;
  margin-top: 7px;
}

.MuiButton-root.google-text-btn {
  background-color: #ffffff;
  border: 1.5px solid #d3d3d3;
  color: #5e5e5e;
  font-size: 12.5px;
  text-transform: none;
  margin-top: 30px;
  width: 100%;
  height: 38px;
  border-radius: 5px;
}

.MuiButton-root.google-text-btn:hover {
  background-color: #e8e8e8;
}

@media only screen and (min-width: 320px) and (max-width: 620px) {
 .MuiButton-root.google-text-btn .not-user-text {
    width: 100%;
    margin-left: 0px;
  }

   .MuiButton-root.google-text-btn .not-user-logo {
    margin-left: -5px;
    margin-top: 7px;
  }

  .MuiButton-root.google-text-btn {
    background-color: #ffffff;
    border: 1.5px solid #d3d3d3;
    color: #5e5e5e;
    font-size: 12.5px;
    text-transform: none;
    margin-top: 30px;
    width: 100%;
    height: 38px;
    border-radius: 5px;
  }
}

.MuiButton-root.copy-btn-gig-details{
color: #0160bb;
font-style: inter;
font-style: normal;
font-size: 15px;
font-weight: 400;
text-transform: none;
cursor: pointer;
}


