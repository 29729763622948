.css-1bbrl74 {
  font-family: Poppins;
}

.font-fix{
  font-family: Poppins;
  font-size: 21px;
  font-weight: 500;
  text-transform: capitalize;
  margin-top: 0px;
  margin-bottom: 20px;
}
