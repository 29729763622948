.MuiButton-root.activate-btn {
  width: 95px;
  height: 32px;
  background-color: #0543ab;
  color: #ffffff;
  border-radius: 7px;
  text-transform: capitalize;
  font-size: 14px;
}

.MuiButton-root.activate-btn:hover {
  background-color: #0543ab;
}
