.main-home-page-container {
  background-color: white;
  width: 100%;
  height: 100%;
}

@media only screen and (min-width: 330px) and (max-width: 750px) {
  .main-home-page-container {
    background-color: white;
    width: 96%;
    height: 100%;
    padding-left: 10px;
    padding-right: 0px;
    padding-top: 50px;
    padding-bottom: 10px;
    margin-left: 0px;
  }
}

.div1-container {
  width: 88%;
  display: flex;
  justify-content: space-between;
  margin-top: 10vh;
  margin-bottom: 20px;
  margin-left: 170px;
}

.homePage1-img {
  margin-top: -29%;
  margin-left: 20px;
}

.d1-component {
  width: 450px;
  max-width: 450px;
  margin-top: 60px;
}

.d1-component h1 {
  line-height: 50px;
  font-size: 40px;
  color: #003FAA;
  font-weight: 400;
  font-family: inter;
  width: 350px;
  max-width: 350px;
}

.d1-component p {
  color: #E67844;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.37px;
  font-family: inter;
  width: 420px;
  max-width: 420px;
}

.MuiButton-root.discover-btn-homepage {
  background-color: #003FAA;
  width: 135px;
  height: 44px;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 5px;
  line-height: 25px;
  font-weight: 400;
  font-size: 15px;
  font-style: inter;
  color: #FCFCFC;
}

.MuiButton-root.discover-btn-homepage:hover {
  background-color: #003FAA;
}

.MuiButton-root.how-we-homepage {
  background-color: #003FAA;
  width: 140px;
  height: 44px;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 5px;
  line-height: 25px;
  font-weight: 400;
  font-size: 15px;
  font-style: inter;
  color: #FCFCFC;
}

.MuiButton-root.how-we-homepage:hover {
  background-color: #003FAA;
}

.MuiButton-root.Register-homepage {
  background-color: #003FAA;
  width: 125px;
  height: 44px;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 5px;
  line-height: 25px;
  font-weight: 400;
  font-size: 15px;
  font-style: inter;
  color: #FCFCFC;
}

.MuiButton-root.Register-homepage:hover {
  background-color: #003FAA;
}

.Clients-Endorsers {
  font-size: 15px;
  font-weight: 400;
  line-height: 24px;
  font-family: inter;
  color: #003FAA;
  margin-top: 40px;
  margin-bottom: 15px;
  width: 100%;
}

.main-page-image-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: -12px;
}

.member-benefit {
  justify-content: center;
  display: flex;
  margin-top: 30px;
  color: #003FAA;
  font-size: 27px;
  font-weight: 400;
  line-height: 30px;
  font-style: inter;
  font-family: inter;
  margin-bottom: 30px;
}

.switch-btn-homepage {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding-bottom: 2%;
}

.engineer-text-homepage {
  color: #000000;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  font-style: inter;
  font-family: inter;
}

.onoffswitch {
  position: relative;
  width: 93px;
}

.onoffswitch-checkbox {
  display: none;
}

.onoffswitch-label {
  display: block;
  overflow: hidden;
  cursor: pointer;
  border-radius: 50px;
  border: 3px solid #003FAA;
}

.onoffswitch-inner {
  display: block;
  width: 200%;
  margin-left: -100%;
  transition: margin 0.3s ease-in 0s;
  opacity: 1;
}

.onoffswitch-inner:before,
.onoffswitch-inner:after {
  display: block;
  float: left;
  width: 50%;
  height: 26px;
  padding: 0;
  line-height: 26px;
  font-size: 14px;
  color: white;
  font-family: Trebuchet, Arial, sans-serif;
  font-weight: bold;
  box-sizing: border-box;
}

.onoffswitch-inner:before {
  content: "";
  padding-left: 17px;
  opacity: 1;
  color: #F01818;
}

.onoffswitch-inner:after {
  content: "";
  padding-right: 17px;
  opacity: 1;
  color: #631616;
  text-align: right;
}

.onoffswitch-switch {
  display: block;
  width: 20px;
  margin: 5px;
  background: #E67844;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 63px;
  border-radius: 50px;
  transition: all 0.3s ease-in 0s;
}

.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-inner {
  margin-left: 0;
}

.onoffswitch-checkbox:checked+.onoffswitch-label .onoffswitch-switch {
  right: 0px;
  background-color: #E67844;
}

.onoffswitch-checkbox:checked+.onoffswitch-label {
  border: 3px solid #003FAA;
}

.business-text-homepage {
  color: #000000;
  font-size: 13px;
  font-weight: 400;
  line-height: 20px;
  font-style: inter;
  font-family: inter;
}

.how-we-work-container {
  margin-top: 20px;
  margin-left: 160px;
  /* margin-right: 8%; */
  display: flex;
  justify-content: space-between;
  gap: 135px;
  padding-top: 60px;
}

.how-we-work-container-2 {
  margin-top: 30px;
  margin-left: 160px;
  display: flex;
  justify-content: space-between;
  gap: 0px;
  align-items: center;
}

.homepage-img-2 {
  margin-top: -65px;
}

.how-we-work-text-container {
  width: 50%;
}

.how-we-work-text-heading {
  color: #003FAA;
  font-weight: 400;
  font-style: inter;
  font-size: 28px;
  line-height: 30px;
}

.how-we-work-text-paragraph {
  color: #E67844;
  font-weight: 400;
  font-style: inter;
  font-size: 14px;
  line-height: 26px;
}

.innovation-heading {
  width: 40%;
  margin-right: 14%;
}

.our-process-heading {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  color: #E67844;
  font-weight: 400;
  line-height: 18px;
  font-size: 11px;
  font-family: inter;
  font-style: inter;
}

.how-we-work-switch-btn {
  justify-content: center;
  display: flex;
  margin-top: 1px;
  color: #003FAA;
  font-size: 27px;
  font-weight: 400;
  line-height: 30px;
  font-style: inter;
  font-family: inter;
  margin-bottom: 21px;
}

.how-we-work-business-container {
  position: relative;
  margin-top: 30px;
  margin-left: 160px;
  margin-right: 140px;
  display: flex;
  justify-content: space-evenly;
  grid-gap: 50px;
  gap: 50px;
}

.how-we-work-business-card-1 {
  width: 260px;
  height: 350px;
  min-height: 350px;
  max-width: 260px;
}

.how-we-work-business-1 {
  color: #003FAA;
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  font-family: inter;
  margin-top: 35px;
}

.how-we-work-business-para-1 {
  color: #003FAA;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  font-family: inter;
  margin-top: 2px;
}

.how-we-work-business-card-2 {
  width: 260px;
  height: 350px;
  min-height: 350px;
  max-width: 260px;
}

.how-we-work-business-2 {
  color: #003FAA;
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  font-family: inter;
  margin-top: 35px;
}

.how-we-work-business-para-2 {
  color: #003FAA;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  font-family: inter;
  margin-top: 2px;
}

.how-we-work-business-card-3 {
  width: 260px;
  height: 350px;
  min-height: 350px;
  max-width: 260px;
}

.how-we-work-business-3 {
  color: #003FAA;
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  font-family: inter;
  margin-top: 35px;
}

.how-we-work-business-para-3 {
  color: #003FAA;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  font-family: inter;
  margin-top: 2px;
}

.how-we-work-business-card-4 {
  width: 260px;
  height: 350px;
  min-height: 350px;
  max-width: 260px;
}

.how-we-work-business-4 {
  color: #003FAA;
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  font-family: inter;
  margin-top: 31px;
}

.how-we-work-business-para-4 {
  color: #003FAA;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  font-family: inter;
  margin-top: 2px;
}

.how-we-work-engineer-container {
  position: relative;
  margin-top: 30px;
  margin-left: 160px;
  margin-right: 140px;
  display: flex;
  justify-content: space-evenly;
  grid-gap: 50px;
  gap: 50px;
}

.how-we-work-engineer-card-1 {
  width: 260px;
  height: 350px;
  min-height: 350px;
  max-width: 260px;
}

.how-we-work-engineer-1 {
  color: #003FAA;
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  font-family: inter;
  margin-top: 35px;
}

.how-we-work-engineer-para-1 {
  color: #003FAA;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  font-family: inter;
  margin-top: 2px;
}

.how-we-work-engineer-card-2 {
  width: 260px;
  height: 350px;
  min-height: 350px;
  max-width: 260px;
}

.how-we-work-engineer-2 {
  color: #003FAA;
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  font-family: inter;
  margin-top: 35px;
}

.how-we-work-engineer-para-2 {
  color: #003FAA;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  font-family: inter;
  margin-top: 2px;
}

.how-we-work-engineer-card-3 {
  width: 260px;
  height: 350px;
  min-height: 350px;
  max-width: 260px;
}

.how-we-work-engineer-3 {
  color: #003FAA;
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  font-family: inter;
  margin-top: 35px;
}

.how-we-work-engineer-para-3 {
  color: #003FAA;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  font-family: inter;
  margin-top: 2px;
}

.how-we-work-engineer-card-4 {
  width: 260px;
  height: 350px;
  min-height: 350px;
  max-width: 260px;
}

.how-we-work-engineer-4 {
  color: #003FAA;
  font-size: 18px;
  font-weight: 700;
  line-height: 30px;
  font-family: inter;
  margin-top: 31px;
}

.how-we-work-engineer-para-4 {
  color: #003FAA;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
  font-family: inter;
  margin-top: 2px;
}

.business-card-container {
  display: flex;
  align-items: center;
  gap: 55px;
  padding-left: 160px;
  padding-right: 160px;
  justify-content: center;
}

.business-card-1 {
  background-color: #F1F6FB;
  width: 420px;
  max-width: 420px;
  max-height: 240px;
  height: 240px;
  border-radius: 10px;
}

.img-number-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 7%;
  margin-left: 10%;
  margin-right: 10%;
}

.business-01 {
  color: #E67844;
  font-weight: 700;
  font-size: 50px;
  font-family: inter;
  line-height: 90px;
}

.paragraph-business-1 {
  margin-left: 10%;
  margin-right: 10%;
  font-size: 21px;
  font-weight: 700;
  line-height: 30px;
  font-family: inter;
  color: #003FAA;
  margin-top: 0px;
  margin-bottom: 3px;
}

.text-business-1 {
  margin-left: 10%;
  margin-right: 10%;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  font-family: inter;
  color: #003FAA;
  margin-top: 0px;
}

.business-card-2 {
  background-color: #F1F6FB;
  width: 420px;
  max-width: 420px;
  max-height: 240px;
  height: 240px;
  border-radius: 10px;
}

.img-number-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 7%;
  margin-left: 10%;
  margin-right: 10%;
}

.business-02 {
  color: #E67844;
  font-weight: 700;
  font-size: 50px;
  font-family: inter;
  line-height: 90px;
}

.paragraph-business-2 {
  margin-left: 10%;
  margin-right: 10%;
  font-size: 21px;
  font-weight: 700;
  line-height: 30px;
  font-family: inter;
  color: #003FAA;
  margin-top: 0px;
  margin-bottom: 3px;
}

.text-business-2 {
  margin-left: 10%;
  margin-right: 10%;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  font-family: inter;
  color: #003FAA;
  margin-top: 0px;
}

.business-card-3 {
  background-color: #F1F6FB;
  width: 420px;
  max-width: 420px;
  max-height: 240px;
  height: 240px;
  border-radius: 10px;
}

.img-number-3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 7%;
  margin-left: 10%;
  margin-right: 10%;
}

.business-03 {
  color: #E67844;
  font-weight: 700;
  font-size: 50px;
  font-family: inter;
  line-height: 90px;
}

.paragraph-business-3 {
  margin-left: 10%;
  margin-right: 10%;
  font-size: 21px;
  font-weight: 700;
  line-height: 30px;
  font-family: inter;
  color: #003FAA;
  margin-top: 0px;
  margin-bottom: 3px;
}

.text-business-3 {
  margin-left: 10%;
  margin-right: 10%;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  font-family: inter;
  color: #003FAA;
  margin-top: 0px;
}


.engineer-card-container {
  display: flex;
  align-items: center;
  gap: 55px;
  padding-left: 160px;
  padding-right: 8%;
}

.engineer-card-1 {
  background-color: #F1F6FB;
  width: 420px;
  max-width: 420px;
  max-height: 240px;
  height: 240px;
  border-radius: 10px;
}

.img-number-1 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 7%;
  margin-left: 10%;
  margin-right: 10%;
}

.engineer-01 {
  color: #E67844;
  font-weight: 700;
  font-size: 50px;
  font-family: inter;
  line-height: 90px;
}

.paragraph-engineer-1 {
  margin-left: 10%;
  margin-right: 10%;
  font-size: 21px;
  font-weight: 700;
  line-height: 30px;
  font-family: inter;
  color: #003FAA;
  margin-top: 0px;
  margin-bottom: 3px;
}

.text-engineer-1 {
  margin-left: 10%;
  margin-right: 10%;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  font-family: inter;
  color: #003FAA;
  margin-top: 0px;
}

.engineer-card-2 {
  background-color: #F1F6FB;
  width: 420px;
  max-width: 420px;
  max-height: 240px;
  height: 240px;
  border-radius: 10px;
}

.img-number-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 7%;
  margin-left: 10%;
  margin-right: 10%;
}

.engineer-02 {
  color: #E67844;
  font-weight: 700;
  font-size: 50px;
  font-family: inter;
  line-height: 90px;
}

.paragraph-engineer-2 {
  margin-left: 10%;
  margin-right: 10%;
  font-size: 21px;
  font-weight: 700;
  line-height: 30px;
  font-family: inter;
  color: #003FAA;
  margin-top: 0px;
  margin-bottom: 3px;
}

.text-engineer-2 {
  margin-left: 10%;
  margin-right: 10%;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  font-family: inter;
  color: #003FAA;
  margin-top: 0px;
}

.engineer-card-3 {
  background-color: #F1F6FB;
  width: 420px;
  max-width: 420px;
  max-height: 240px;
  height: 240px;
  border-radius: 10px;
}

.img-number-3 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 7%;
  margin-left: 10%;
  margin-right: 10%;
}

.engineer-03 {
  color: #E67844;
  font-weight: 700;
  font-size: 50px;
  font-family: inter;
  line-height: 90px;
}

.paragraph-engineer-3 {
  margin-left: 10%;
  margin-right: 10%;
  font-size: 21px;
  font-weight: 700;
  line-height: 30px;
  font-family: inter;
  color: #003FAA;
  margin-top: 0px;
  margin-bottom: 3px;
}

.text-engineer-3 {
  margin-left: 10%;
  margin-right: 10%;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  font-family: inter;
  color: #003FAA;
  margin-top: 0px;
}

.engineer-card-container {
  display: flex;
  align-items: center;
  gap: 55px;
  padding-left: 160px;
  padding-right: 160px;
  justify-content: center;
}

.meet-our-register-btn {
  margin-left: 160px;
  margin-right: 150px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.meet-our-heading {
  color: #003FAA;
  font-family: inter;
  font-weight: 400;
  font-size: 28px;
  line-height: 50px;
}

.register-btn-link {
  color: #0B213E;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: inter;
  text-decoration: none;
  border-bottom: 2.5px solid #ECBEAB;
  padding-bottom: 5px;
}

.sign-up-and-connected-container {
  background-color: #F1F6FB;
  height: 1285px;
  padding-top: 4%;
}

.Sign-up-as-a-business-heading {
  color: #E67844;
  padding-left: 7%;
  padding-top: 6.5%;
  font-weight: 700;
  line-height: 48px;
  font-size: 30px;
  font-style: inter;
  margin-bottom: 0px;
}

.para-btn-project-page {
  display: flex;
  padding-left: 7%;
  padding-top: 0%;
  padding-right: 8%;
  gap: 190px;
  align-items: baseline;
}

.Sign-up-as-a-business-para {
  color: #EDF1F4;
  font-weight: 400;
  line-height: 22px;
  font-size: 14px;
  font-style: inter;
  width: 65%;
}

.start-a-project-homepage {
  position: relative;
  margin-left: 160px;
  margin-right: 150px;
  /* background-color: #003FAA; */
  /* height: 365px; */
  /* border-radius: 15px; */
}

.MuiButton-root.start-project-btn-homepage {
  background-color: #FFDBCC;
  width: 145px;
  height: 44px;
  border-radius: 14px;
  cursor: pointer;
  margin-top: 5px;
  line-height: 28px;
  font-weight: 700;
  font-size: 15px;
  font-style: inter;
  color: #2354AE;
}

.MuiButton-root.start-project-btn-homepage:hover {
  background-color: #FFDBCC;
}

.gig-peoples-container {
  margin-left: 7%;
  margin-right: 7%;
  /* display: flex; */
  margin-top: 2%;
  align-items: center;
  gap: 35px;
}

.gig-peoples-1-container {
  background-color: #fff;
  display: flex !important;
  flex-direction: column;
  /* align-items: center; */
}

/* .slick-list {
  padding-left: 66px !important;
  padding-top: 95px !important;
} */

.people-1-name {
  color: #E67844;
  font-size: 15px;
  font-weight: 700;
  line-height: 29px;
  font-style: inter;
  margin-top: 12px;
  margin-bottom: 0px;
}

.people-1-para {
  color: #838383;
  font-size: 15px;
  font-weight: 400;
  line-height: 29px;
  font-style: inter;
  margin-top: 0px;
  margin-bottom: 0px;
}

.testimonials-container {
  background-color: #FFDBCC;
  height: 450px;
  margin-top: 6%;
}

.testimonials-heading {
  display: flex;
  justify-content: center;
  color: #003FAA;
  font-size: 40px;
  font-weight: 400;
  line-height: 73px;
  font-style: inter;
  padding-top: 2%;
}

.testimonials-card-container {
  margin-left: 5%;
  margin-right: 5%;
  /* display: flex; */
  gap: 25px;
  justify-content: center;
  /* overflow: auto;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 10px; */
}

.testimonials-card-1 {
  width: 100% !important;
  background-color: #fff;
  height: 260px;
  padding-top: 22px;
  padding-left: 15px;
  border-radius: 12px;
}

.slick-dots {
  bottom: -50px !important;
}

.testimonials-card-1-text {
  color: #202020;
  line-height: 25px;
  font-size: 14px;
  font-weight: 400;
  font-style: inter;
  margin-top: 0px;
  padding-right: 30px;
  height: 130px;
  overflow-x: auto;
}

.testimo-line-break {
  border-bottom: 1px solid #2020200f;
  width: 100%;
  margin-top: 25px;
}

.main-container-testimo {
  display: flex;
  margin-top: 12px;
  gap: 8px;
}

.main-container-testimo-1 {
  display: flex;
  margin-top: 12px;
  gap: 8px;
}

.testimonials-card-1-heading {
  color: #E67844;
  line-height: 29px;
  font-size: 16px;
  font-weight: 700;
  font-style: inter;
  margin-top: 0px;
  margin-bottom: 0px;
}

.testimonial-img-1{
  width: 100%;
}

.p-Senior-engineer {
  color: #838383;
  font-size: 10px;
  margin-top: -5px;
  font-weight: 400;
  line-height: 19px;
  font-style: inter;
}

.get-connected-text {
  color: #003FAA;
  margin-left: 160px;
  margin-right: 150px;
  margin-top: 80px;
  font-size: 36px;
  font-weight: 400;
  line-height: 72px;
  font-family: inter;
  font-style: inter;
  padding-top: 75px;
}

.get-connected-container {
  margin-left: 160px;
  margin-right: 150px;
  display: flex;
  gap: 65px;
}

.get-connected-input {
  width: 70%;
  background-color: #fff;
  height: 630px;
  border-radius: 26px;
  padding-top: 3%;
  padding-left: 4%;
  padding-right: 4%;
}

.MuiButton-root.get-in-touch-btn {
  background-color: #003FAA;
  width: 125px;
  height: 38px;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 5px;
  line-height: 19px;
  font-weight: 700;
  font-size: 12px;
  font-style: inter;
}

.MuiButton-root.get-in-touch-btn:hover {
  background-color: #003FAA;
}

.mail-text-container {
  width: 20%;
  margin-top: 0.5%;
}

.main-icon-and-text {
  display: flex;
  align-items: center;
  gap: 5px;
}

.email-us-text {
  color: #183B56;
  font-size: 14px;
  font-weight: 400;
  line-height: 31px;
  font-family: inter;
}

.email-us-text-1 {
  color: #5A7184;
  font-size: 11px;
  font-weight: 400;
  line-height: 21px;
  font-family: inter;
  margin-top: -5px;
  margin-bottom: 4px;
}

.email-link-open {
  color: #003FAA;
  font-size: 12px;
  font-weight: 700;
  line-height: 19px;
  font-style: inter;
  margin-top: 2px;
  text-decoration: none;
}

.contact-us-text-bottom {
  color: #5A7184;
  font-weight: 400;
  line-height: 21px;
  font-size: 12px;
  font-style: inter;
  margin-top: 30px;
  margin-bottom: 40px;
}

.faq-homepage-container {
  margin-left: 160px;
  margin-right: 8%;
  margin-top: 0px;
  display: flex;
  padding-top: 45px;
}

.faq-text-1 {
  width: 20%;
}

.faq-heading-homepage {
  color: #E67844;
  line-height: 58px;
  font-size: 30px;
  font-weight: 700;
  font-family: inter;
  font-style: inter;
  margin-bottom: 0px;
}

.faq-para-homepage {
  color: #003FAA;
  line-height: 31px;
  font-size: 17px;
  font-weight: 400;
  font-family: inter;
  font-style: inter;
  margin-top: -6px;
}

.accordion {
  width: 90%;
  max-width: 1000px;
  margin: 2rem auto;
}

.accordion-item {
  background-color: #fff;
  color: #111;
  border-radius: 10px;
  border-color: white;
  margin-bottom: 10px;
  border: 0.5px solid #E67844
}

.accordion-item-header {
  padding: 0.5rem 3rem 0.5rem 1rem;
  min-height: 3rem;
  line-height: 26px;
  font-weight: 700;
  font-size: 13px;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  color: #003FAA;
  font-family: inter;
  font-style: inter;
}

.accordion-item-header::after {
  content: "\002B";
  font-size: 1rem;
  position: absolute;
  right: 1rem;
}

.accordion-item-header.active::after {
  content: "\2212";
}

.accordion-item-body {
  max-height: 0;
  overflow: hidden;
  transition: max-height 0.2s ease-out;
}

.accordion-item-body-content {
  padding-left: 1rem;
  padding-right: 3rem;
  padding-bottom: 1rem;
  line-height: 20px;
  border-image: linear-gradient(to right, transparent, #34495e, transparent) 1;
  margin-top: -4px;
  font-size: 13px;
  font-family: inter;
  font-style: inter;
  font-weight: 400;
  color: #838383;
}

.input-or-sub-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding-left: 0%;
  gap: 5px;
  margin-top: 20px;
}

.faq-text-2 {
  width: 90%;
}

.class-image-signup {
  position: absolute;
  padding-left: 160px;
  padding-right: 150px;
}

@media only screen and (min-width: 330px) and (max-width: 750px) {

  .div1-container {
    width: 100%;
    display: block;
    justify-content: space-between;
    margin-top: 80px;
    margin-bottom: 8%;
    margin-right: 0px;
    margin-left: 0px;
  }

  .d1-component {
    width: 100%;
    max-width: 100%;
  }

  .d1-component h1 {
    line-height: 29px;
    font-size: 24px;
    color: #003FAA;
    font-weight: 500;
    font-family: inter;
  }

  .homePage1-img {
    margin-top: 45px;
    margin-left: -123px;
    width: 610px;
  }

  .member-benefit {
    justify-content: center;
    display: flex;
    margin-top: 50px;
    color: #003FAA;
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    font-style: inter;
    font-family: inter;
    margin-bottom: 30px;
  }

  .business-card-container {
    display: block;
    align-items: center;
    grid-gap: 55px;
    gap: 55px;
    padding-left: 4px;
    padding-right: 0px;
  }

  .business-card-1 {
    background-color: #F1F6FB;
    width: 100%;
    height: 275px;
    border-radius: 10px;
  }

  .business-card-2 {
    background-color: #F1F6FB;
    width: 100%;
    height: 240px;
    border-radius: 10px;
    margin-top: 20px;
  }

  .business-card-3 {
    background-color: #F1F6FB;
    width: 100%;
    height: 240px;
    border-radius: 10px;
    margin-top: 20px;
  }

  .engineer-card-container {
    display: block;
    align-items: center;
    grid-gap: 55px;
    gap: 55px;
    padding-left: 4px;
    padding-right: 0px;
  }

  .engineer-card-1 {
    background-color: #F1F6FB;
    width: 100%;
    height: 275px;
    border-radius: 10px;
  }

  .engineer-card-2 {
    background-color: #F1F6FB;
    width: 100%;
    height: 240px;
    border-radius: 10px;
    margin-top: 20px;
  }

  .engineer-card-3 {
    background-color: #F1F6FB;
    width: 100%;
    height: 275px;
    border-radius: 10px;
    margin-top: 20px;
  }

  .how-we-work-container {
    margin-top: 20px;
    margin-left: 5px;
    margin-right: 5px;
    display: inline-flex;
    justify-content: space-between;
    gap: 21px;
    flex-direction: column-reverse;
  }

  .how-we-work-text-container {
    width: 100%;
  }

  .how-we-work-text-heading {
    color: #003FAA;
    font-weight: 500;
    font-style: inter;
    font-size: 24px;
    line-height: 29px;
  }

  .our-process-heading {
    display: flex;
    justify-content: center;
    margin-top: 50px;
    color: #E67844;
    font-weight: 400;
    line-height: 19px;
    font-size: 16px;
    font-family: inter;
    font-style: inter;
  }

  .how-we-work-switch-btn {
    justify-content: center;
    display: flex;
    margin-top: 6px;
    color: #003FAA;
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    font-style: inter;
    font-family: inter;
    margin-bottom: 21px;
  }

  .how-we-work-business-container {
    margin-top: 40px;
    margin-left: 0px;
    margin-right: 0px;
    display: grid;
    justify-content: space-evenly;
    grid-gap: 0px;
    gap: 0px;
  }

  .how-we-work-business-card-1 {
    width: 100%;
  }

  .how-we-work-business-1 {
    color: #003FAA;
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
    font-family: inter;
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }

  .how-we-work-img-1-b {
    display: flex;
    justify-content: center;
  }

  .how-we-work-business-para-1 {
    color: #003FAA;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    font-family: inter;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-right: 2px;
    padding-left: 20px;
  }

  .how-we-work-business-card-2 {
    width: 100%;
    margin-top: 0px
  }

  .how-we-work-business-2 {
    color: #003FAA;
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
    font-family: inter;
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }

  .how-we-work-img-2-b {
    display: flex;
    justify-content: center;
  }

  .how-we-work-business-para-2 {
    color: #003FAA;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    font-family: inter;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-right: 2px;
    padding-left: 25px;
  }

  .how-we-work-business-card-3 {
    width: 100%;
    margin-top: 12px
  }

  .how-we-work-business-3 {
    color: #003FAA;
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
    font-family: inter;
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }

  .how-we-work-img-3-b {
    display: flex;
    justify-content: center;
  }

  .how-we-work-business-para-3 {
    color: #003FAA;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    font-family: inter;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-right: 5px;
    padding-left: 20px;
  }

  .how-we-work-business-card-4 {
    width: 100%;
    margin-top: 40px;
  }

  .how-we-work-business-4 {
    color: #003FAA;
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
    font-family: inter;
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }

  .how-we-work-img-4-b {
    display: flex;
    justify-content: center;
  }

  .how-we-work-business-para-4 {
    color: #003FAA;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    font-family: inter;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-right: 0px;
    padding-left: 25px;
  }

  .how-we-work-engineer-container {
    margin-top: 40px;
    margin-left: 0px;
    margin-right: 0px;
    display: grid;
    justify-content: space-evenly;
    grid-gap: 0px;
    gap: 0px;
  }

  .how-we-work-engineer-card-1 {
    width: 100%;
  }

  .how-we-work-engineer-1 {
    color: #003FAA;
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
    font-family: inter;
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }

  .how-we-work-img-1-e {
    display: flex;
    justify-content: center;
  }

  .how-we-work-engineer-para-1 {
    color: #003FAA;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    font-family: inter;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-right: 25px;
    padding-left: 25px;
  }

  .how-we-work-engineer-card-2 {
    width: 100%;
    margin-top: 50px;
  }

  .how-we-work-engineer-2 {
    color: #003FAA;
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
    font-family: inter;
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }

  .how-we-work-img-2-e {
    display: flex;
    justify-content: center;
  }

  .how-we-work-engineer-para-2 {
    color: #003FAA;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    font-family: inter;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-right: 40px;
    padding-left: 40px;
  }

  .how-we-work-engineer-card-3 {
    width: 100%;
    margin-top: 50px;
  }

  .how-we-work-engineer-3 {
    color: #003FAA;
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
    font-family: inter;
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }

  .how-we-work-img-3-e {
    display: flex;
    justify-content: center;
  }

  .how-we-work-engineer-para-3 {
    color: #003FAA;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    font-family: inter;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-right: 30px;
    padding-left: 30px;
  }

  .how-we-work-engineer-card-4 {
    width: 100%;
    margin-top: -20px;
  }

  .how-we-work-engineer-4 {
    color: #003FAA;
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
    font-family: inter;
    margin-top: 30px;
    display: flex;
    justify-content: center;
  }

  .how-we-work-img-4-e {
    display: flex;
    justify-content: center;
  }

  .how-we-work-engineer-para-4 {
    color: #003FAA;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    font-family: inter;
    margin-top: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding-right: 25px;
    padding-left: 25px;
  }

  .why-us-container {
    background-color: #F1F6FB;
    height: 955px;
    border-radius: 10px;
    margin-top: 10px;
    margin-left: 2px;
    margin-right: 0px;
    margin-bottom: 4%;
    border: 2px solid #E67844;
    width: 100%;
    padding-top: 10px;
  }

  .why-us-heading {
    display: flex;
    justify-content: center;
    margin-top: 7%;
    margin-bottom: 1.5%;
    font-family: inter;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    color: #E67844;
  }

  .why-us-text {
    display: flex;
    justify-content: center;
    font-family: inter;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #003FAA;
    padding-left: 3%;
    padding-right: 3%;
    text-align: center;
    margin-top: 13px;
  }

  .why-us-card-container {
    display: block;
    justify-content: center;
    grid-gap: 25px;
    gap: 25px;
    padding-left: 3%;
    padding-right: 3%;
    margin-top: 40px;
  }

  .why-us-card-1 {
    width: 100%;
    height: 150px;
    margin-top: 4%;
    border-radius: 15px;
  }

  .why-us-card-1-heading {
    margin-left: 35px;
    color: #E67844;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    font-style: inter;
    margin-top: -33px;
    margin-bottom: 0px;
  }

  .why-us-card-1-para {
    margin-left: 35px;
    margin-right: 10px;
    color: #003FAA;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    font-family: inter;
    margin-top: 10px;
  }

  .why-us-card-2 {
    width: 100%;
    height: 150px;
    margin-top: 4%;
    border-radius: 15px;
  }

  .why-us-card-2-heading {
    margin-left: 40px;
    color: #E67844;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    font-style: inter;
    margin-top: -33px;
    margin-bottom: 0px;
  }

  .why-us-card-2-para {
    margin-left: 40px;
    margin-right: 10px;
    color: #003FAA;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    font-family: inter;
    margin-top: 10px;
  }

  .why-us-card-3 {
    width: 100%;
    height: 150px;
    margin-top: 4%;
    border-radius: 15px;
  }

  .why-us-card-3-heading {
    margin-left: 35px;
    color: #E67844;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    font-style: inter;
    margin-top: -33px;
    margin-bottom: 0px;
  }

  .why-us-card-3-para {
    margin-left: 35px;
    margin-right: 10px;
    color: #003FAA;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    font-family: inter;
    margin-top: 10px;
  }

  .why-us-card-4 {
    width: 100%;
    height: 150px;
    margin-top: 50px;
    border-radius: 15px;
  }

  .why-us-card-4-heading {
    margin-left: 35px;
    color: #E67844;
    font-size: 20px;
    line-height: 24px;
    font-weight: 500;
    font-style: inter;
    margin-top: -33px;
    margin-bottom: 0px;
  }

  .why-us-card-4-para {
    margin-left: 35px;
    margin-right: 10px;
    color: #003FAA;
    font-size: 14px;
    line-height: 16px;
    font-weight: 400;
    font-family: inter;
    margin-top: 10px;
  }

  .meet-our-register-btn {
    margin-left: 0px;
    margin-right: 0px;
    display: block;
    justify-content: space-evenly;
    align-items: center;
    text-align: center;
  }

  .meet-our-heading {
    color: #003FAA;
    font-family: inter;
    font-weight: 600;
    font-size: 24px;
    line-height: 29px;
    margin-top: 35px;
  }

  .register-btn-link {
    color: #fff;
    font-size: 15px;
    font-weight: 400;
    line-height: 21px;
    font-family: inter;
  }

  /* .slick-initialized .slick-slide {
    display: flex !important;
    justify-content: center !important;
    margin-left: 50px !important;
  } */

  .testimonials-container {
    background-color: #FFDBCC;
    height: 400px;
    margin-top: 60px;
    margin-left: -10px;
    margin-right: -16px;
  }

  .testimonials-heading {
    display: flex;
    justify-content: center;
    color: #003FAA;
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    font-style: inter;
    padding-top: 7%;
    padding-bottom: 1%;
  }

  .how-we-work-text-paragraph {
    color: #E67844;
    font-weight: 400;
    font-style: inter;
    font-size: 16px;
    line-height: 19px;
    padding-right: 0px;
  }

  .homepage-img-2 {
    margin-top: 25px;
    display: flex;
    width: 100%;
    justify-content: center;
    margin-left: 3px;
  }

  .how-we-work-container-2 {
    margin-top: 7%;
    margin-left: 2%;
    margin-right: 2%;
    display: block;
    justify-content: space-between;
    grid-gap: 0px;
    gap: 0px;
    align-items: center;
  }

  .home-page-img-3 {
    width: 100%;
    margin-left: 3px;
    display: flex;
    justify-content: center;
  }

  .get-connected-container {
    margin-left: 5%;
    margin-right: 5%;
    display: block;
    grid-gap: 65px;
    gap: 65px;
  }

  .get-connected-input {
    width: 100%;
    background-color: #fff;
    height: 830px;
    border-radius: 8px;
    padding-top: 10%;
    padding-left: 4%;
    padding-right: 4%;
    margin-top: 40px;
  }

  .contact-us-text-bottom {
    color: #5A7184;
    font-weight: 400;
    line-height: 19px;
    font-size: 16px;
    font-style: inter;
    margin-top: 30px;
    margin-bottom: 40px;
  }

  .MuiButton-root.get-in-touch-btn {
    background-color: #003FAA;
    width: 100%;
    height: 48px;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 5px;
    line-height: 19px;
    font-weight: 600;
    font-size: 16px;
    font-style: inter;
  }

  .mail-text-container {
    width: 100%;
    margin-top: 15px;
  }

  .class-image-signup {
    position: absolute;
    padding-left: 5px;
    padding-right: 0px;
  }

  .signup-img-homepage-6 {
    width: 0px;
    height: 0px;
  }

  .start-a-project-homepage {
    position: relative;
    margin-left: 0px;
    margin-top: 90px;
    width: 100%;
    padding-left: 50px;
  }

  .MuiButton-root.start-project-btn-homepage {
    background-color: #FFDBCC;
    width: 154px;
    height: 44px;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 5px;
    line-height: 19px;
    font-weight: 500;
    font-size: 16px;
    font-style: inter;
    color: #2354AE;
  }

  .Sign-up-as-a-business-heading {
    color: #E67844;
    font-weight: 600;
    line-height: 29px;
    font-size: 26px;
    font-style: inter;
    margin-bottom: 0px;
    margin-left: -21px;
    margin-right: 0px;
  }

  .innovation-heading {
    width: 100%;
    margin-right: 0px;
  }

  .faq-homepage-container {
    display: block;
    width: 100%;
  }

  .faq-text-1 {
    width: 200px;
  }

  .faq-text-2 {
    width: 100%;
    /* margin-left: -17px; */
  }

  .accordion-item-header {
    padding: 0.5rem 3rem 0.5rem 1rem;
    min-height: 3rem;
    line-height: 26px;
    font-weight: 700;
    font-size: 16px;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    color: #003FAA;
    font-family: inter;
    font-style: inter;
  }

  .accordion-item-body-content {
    padding-left: 1rem;
    padding-right: 3rem;
    padding-bottom: 1rem;
    line-height: 20px;
    border-image: linear-gradient(to right, transparent, #34495e, transparent) 1;
    margin-top: -2px;
    font-size: 13px;
    font-family: inter;
    font-style: inter;
    font-weight: 400;
    color: #838383;
  }

  .faq-homepage-container {
    margin-left: 2px;
    margin-right: 0px;
    margin-top: 0px;
    padding-top: 30px;
  }
}

.newletter-container {
  display: flex;
  margin-left: 160px;
  margin-right: 150px;
  margin-top: 15px;
  margin-bottom: 6%;
  background-color: #E6EFFA;
  height: 305px;
  border-radius: 60px;
  gap: 40px;
}

.newletter-text-para {
  width: 50%;
  margin-left: 4%;
}

.newletter-text {
  font-family: inter;
  font-size: 24px;
  font-weight: 700;
  line-height: 40px;
  color: #E67844;
  margin-top: 7%;
  margin-bottom: 5px;
}

.newletter-paragraph {
  color: #838383;
  font-family: inter;
  font-size: 13px;
  font-weight: 400;
  line-height: 23px;
  margin-top: 2px;
}

.email-text-field-hpage {
  background-color: #FCFCFC;
  border: none;
}

.MuiButton-root.subscribe-btn-homepage {
  background-color: #003FAA;
  width: 105px;
  height: 54px;
  border-radius: 12px;
  cursor: pointer;
  line-height: 21px;
  font-weight: 400;
  font-size: 14px;
  font-style: inter;
  color: #FCFCFC;
  text-transform: capitalize;
}

.MuiButton-root.subscribe-btn-homepage:hover {
  background-color: #003FAA;
}

.newletter-img {
  width: 50%;
  display: flex;
  justify-content: center;
}

@media only screen and (min-width: 330px) and (max-width: 380px) {
  .menu-item-container{
    margin-top: 50px !important;
    margin-left: 200px !important;
  }
}

@media only screen and (min-width: 380px) and (max-width: 420px) {
  .menu-item-container{
    margin-top: 50px !important;
    margin-left: 225px !important;
  }
}

@media only screen and (min-width: 420px) and (max-width: 500px) {
  .menu-item-container{
    margin-top: 50px !important;
    margin-left: 240px !important;
  }
}

@media only screen and (min-width: 500px) and (max-width: 800px) {
  .menu-item-container{
    margin-top: 40px !important;
    margin-left: 600px !important;
  }
}

@media only screen and (min-width: 330px) and (max-width: 750px) {
  .Clients-Endorsers {
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
    font-family: inter;
    color: #003FAA;
    margin-top: 40px;
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

  .newletter-container {
    display: inline-flex;
    margin-left: 2px;
    margin-top: 0px;
    margin-bottom: 0px;
    background-color: #E6EFFA;
    height: 675px;
    border-radius: 20px;
    width: 100%;
    padding-top: 0px;
    padding-right: 0px;
    margin-right: 0px;
    padding-bottom: 60px;
    gap: 0px;
    flex-direction: column-reverse;
  }

  .newletter-text-para {
    width: 100%;
    padding-left: 20px;
  }

  .newletter-paragraph {
    color: #838383;
    font-family: inter;
    font-size: 15px;
    font-weight: 400;
    line-height: 20px;
    margin-top: 10px;
    width: 310px;
    max-width: 310px;
  }

  .newletter-img {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-top: 40px;
  }

  .MuiButton-root.subscribe-btn-homepage {
    background-color: #003FAA;
    width: 100%;
    height: 54px;
    padding-right: 0px;
    border-radius: 12px;
    cursor: pointer;
    line-height: 21px;
    font-weight: 400;
    font-size: 14px;
    font-style: inter;
    color: #FCFCFC;
    text-transform: capitalize;
    margin-top: 18px;
  }
}

.slick-track {
  display: flex !important;
  justify-content: center;
  gap: 70px;
}

.marquee-img {
  max-height: 100px;
  /* Adjust height as needed */
  margin-right: 0px;
  /* Adjust spacing between images */
}

.rfm-child{
  width: 100% !important;
  margin-left: -60px !important;
}

.first-name-text-home-page {
  color: #003FAA;
  font-weight: 700;
  font-size: 22px;
  line-height: 26px;
  font-style: inter;
  margin-bottom: 2px;
}

.line-1-img {
  position: absolute;
  margin-left: 130px;
  margin-right: 200px;
  margin-top: 15px;
}

.signup-img-homepage-1 {
  width: 0px;
  height: 0px;
}

.line-1-img-work {
  width: 0px;
}

@media only screen and (min-width: 330px) and (max-width: 750px) {
  .slick-prev {
    left: -17px !important;
    top: 140px !important;
  }

  .slick-next {
    right: -25px !important;
    top: 140px !important;
  }

  .accordion {
    width: 100%;
    max-width: 1000px;
    margin: 2rem auto;
  }

.slick-slider .slick-list {
  justify-content: center !important;
  align-items: center;
  overflow: hidden;
}

.slick-slider .slick-track {
  display: flex !important;
  align-items: center;
}

.slick-slide > div {
  justify-content: center !important;
  flex-direction: column;
}

.slick-track {
  display: flex !important;
  justify-content: center !important;
  gap: 0px;
}

.testimo-line-break {
  border-bottom: 1px solid #2020200f;
  width: 100%;
  margin-top: 25px;
}

.testimonials-card-1-text {
  color: #202020;
  line-height: 25px;
  font-size: 14px;
  font-weight: 400;
  font-style: inter;
  margin-top: 0;
  padding-right: 0px;
}

.testimonials-card-1 {
  width: 100% !important;
  background-color: #fff;
  height: 250px;
  padding: 22px;
  border-radius: 12px;
}

.slick-initialized .slick-slide {
  display: block;
  padding: 0 14px;
}

  .line-1-img {
    position: absolute;
    margin-left: 13px;
    margin-top: 125px;
  }

  .line-2-img-work {
    width: 0px;
  }

  .line-1-img-work {
    width: 334px;
  }

  .text-business-1 {
    margin-left: 10%;
    margin-right: 10%;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    font-family: inter;
    color: #003FAA;
    margin-top: 15px;
  }

  .text-business-2 {
    margin-left: 10%;
    margin-right: 10%;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    font-family: inter;
    color: #003FAA;
    margin-top: 15px;
  }

  .text-business-3 {
    margin-left: 10%;
    margin-right: 10%;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    font-family: inter;
    color: #003FAA;
    margin-top: 15px;
  }

  .text-engineer-1 {
    margin-left: 10%;
    margin-right: 10%;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    font-family: inter;
    color: #003FAA;
    margin-top: 15px;
  }

  .text-engineer-2 {
    margin-left: 10%;
    margin-right: 10%;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    font-family: inter;
    color: #003FAA;
    margin-top: 15px;
  }

  .text-engineer-3 {
    margin-left: 10%;
    margin-right: 10%;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    font-family: inter;
    color: #003FAA;
    margin-top: 15px;
  }

  .paragraph-business-1 {
    margin-left: 10%;
    margin-right: 10%;
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
    font-family: inter;
    color: #003FAA;
    margin-top: 20px;
    margin-bottom: 3px;
    display: flex;
    justify-content: center;
  }

  .paragraph-business-2 {
    margin-left: 10%;
    margin-right: 10%;
    font-size: 23px;
    font-weight: 500;
    line-height: 29px;
    font-family: inter;
    color: #003FAA;
    margin-top: 20px;
    margin-bottom: 3px;
    display: flex;
    justify-content: center;
  }

  .paragraph-business-3 {
    margin-left: 10%;
    margin-right: 10%;
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
    font-family: inter;
    color: #003FAA;
    margin-top: 20px;
    margin-bottom: 3px;
    display: flex;
    justify-content: center;
  }

  .paragraph-engineer-1 {
    margin-left: 10%;
    margin-right: 10%;
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
    font-family: inter;
    color: #003FAA;
    margin-top: 20px;
    margin-bottom: 3px;
    display: flex;
    justify-content: center;
  }

  .paragraph-engineer-2 {
    margin-left: 10%;
    margin-right: 10%;
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
    font-family: inter;
    color: #003FAA;
    margin-top: 20px;
    margin-bottom: 3px;
    display: flex;
    justify-content: center;
  }

  .paragraph-engineer-3 {
    margin-left: 10%;
    margin-right: 10%;
    font-size: 24px;
    font-weight: 500;
    line-height: 29px;
    font-family: inter;
    color: #003FAA;
    margin-top: 20px;
    margin-bottom: 3px;
    display: flex;
    justify-content: center;
  }

  .text-engineer-1 {
    margin-left: 10%;
    margin-right: 10%;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    font-family: inter;
    color: #003FAA;
    margin-top: 15px;
    display: flex;
    text-align: center;
  }

  .text-engineer-2 {
    margin-left: 10%;
    margin-right: 10%;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    font-family: inter;
    color: #003FAA;
    margin-top: 15px;
    display: flex;
    text-align: center;
  }

  .text-engineer-3 {
    margin-left: 10%;
    margin-right: 10%;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    font-family: inter;
    color: #003FAA;
    margin-top: 15px;
    display: flex;
    text-align: center;
  }

  .text-business-1 {
    margin-left: 10%;
    margin-right: 10%;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    font-family: inter;
    color: #003FAA;
    margin-top: 15px;
    display: flex;
    text-align: center;
  }

  .text-business-2 {
    margin-left: 10%;
    margin-right: 10%;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    font-family: inter;
    color: #003FAA;
    margin-top: 15px;
    display: flex;
    text-align: center;
  }

  .text-business-3 {
    margin-left: 10%;
    margin-right: 10%;
    font-size: 16px;
    font-weight: 400;
    line-height: 19px;
    font-family: inter;
    color: #003FAA;
    margin-top: 15px;
    display: flex;
    text-align: center;
  }

  .img-number-1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 7%;
    display: grid;
    flex-direction: column;
    justify-content: center;
    padding-top: 30px;
  }

  .business-01 {
    color: #F1F6FB;
    font-weight: 700;
    font-family: inter;
    font-size: 0px;
    line-height: 0px;
  }

  .img-number-2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 7%;
    display: grid;
    flex-direction: column;
    justify-content: center;
    padding-top: 30px;
  }

  .business-02 {
    color: #F1F6FB;
    font-weight: 700;
    font-family: inter;
    font-size: 0px;
    line-height: 0px;
  }

  .img-number-3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 7%;
    display: grid;
    flex-direction: column;
    justify-content: center;
    padding-top: 30px;
  }

  .business-03 {
    color: #F1F6FB;
    font-weight: 700;
    font-family: inter;
    font-size: 0px;
    line-height: 0px;
  }

  .engineer-01 {
    color: #F1F6FB;
    font-weight: 700;
    font-family: inter;
    font-size: 0px;
    line-height: 0px;
  }

  .engineer-02 {
    color: #F1F6FB;
    font-weight: 700;
    font-family: inter;
    font-size: 0px;
    line-height: 0px;
  }

  .engineer-03 {
    color: #F1F6FB;
    font-weight: 700;
    font-family: inter;
    font-size: 0px;
    line-height: 0px;
  }

  .engineer-and-business-img {
    width: 60px;
  }

  .gig-peoples-container {
    margin-left: 50px;
    margin-right: 20px;
    margin-top: 0px;
    width: 320px;
    margin: 0 auto;
  }

  .testimonials-card-container {
    margin-left: 0px;
    margin-right: 0px;
  }

  .main-page-image-1 {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    margin-left: -10px;
    margin-right: -15px;
  }

}

@media only screen and (min-width: 750px) and (max-width: 900px) {
  .toolbar-or-logo {
    display: flex;
    justify-content: space-between;
    margin-left: -146px;
    flex-direction: row-reverse;
    gap: 145px;
  }
}

@media only screen and (min-width: 750px) and (max-width: 1020px) {
  .testimonials-container {
    background-color: #FFDBCC;
    height: 450px;
    margin-top: 64px;
    margin-right: -59px;
  }

  .sign-up-and-connected-container {
    background-color: #F1F6FB;
    height: 1075px;
    padding-top: 20px;
    margin-right: -59px;

  }

}

@media only screen and (min-width: 750px) and (max-width: 850px) {

  .Sign-up-as-a-business-heading {
    color: #E67844;
    padding-left: 50px;
    padding-top: 40px;
    font-weight: 700;
    line-height: 48px;
    font-size: 28px;
    font-style: inter;
    margin-bottom: 0px;
  }

  .para-btn-project-page {
    display: flex;
    padding-left: 51px;
    padding-top: 0px;
    padding-right: 28px;
    grid-gap: 105px;
    gap: 105px;
    align-items: baseline;
}
}

@media only screen and (min-width: 850px) and (max-width: 1020px) {

  .Sign-up-as-a-business-heading {
    color: #E67844;
    padding-left: 50px;
    padding-top: 60px;
    font-weight: 700;
    line-height: 48px;
    font-size: 28px;
    font-style: inter;
    margin-bottom: 0px;
  }

  .para-btn-project-page {
    display: flex;
    padding-left: 75px;
    padding-top: 0px;
    padding-right: -26px;
    grid-gap: 149px;
    grid-gap: 149px;
    gap: 149px;
    align-items: baseline;
}

}

@media only screen and (min-width: 900px) and (max-width: 1040px) {
  .toolbar-or-logo {
    display: flex;
    justify-content: space-between;
    margin-left: 0px;
    flex-direction: row-reverse;
    gap: 145px;
  }
}

@media only screen and (min-width: 750px) and (max-width: 1030px) {

  .main-home-page-container {
    background-color: white;
    width: 100%;
    height: 100%;
  }

  .div1-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 10vh;
    margin-bottom: 50px;
    margin-left: 20px;
  }

  .d1-component {
    width: 200px;
    max-width: 200px;
    margin-top: 100px;
  }

  .d1-component h1 {
    line-height: 41px;
    font-size: 30px;
    color: #003FAA;
    font-weight: 400;
    font-family: inter;
    width: 280px;
    max-width: 320px;
  }

  .d1-component p {
    color: #E67844;
    font-size: 15px;
    font-weight: 400;
    line-height: 20.37px;
    font-family: inter;
    width: 300px;
    max-width: 300px;
  }

  .member-benefit {
    justify-content: center;
    display: flex;
    margin-top: 30px;
    color: #003FAA;
    font-size: 24px;
    font-weight: 400;
    line-height: 23px;
    font-style: inter;
    font-family: inter;
    margin-bottom: 30px;
  }

  .business-card-container {
    display: flex;
    align-items: center;
    grid-gap: 55px;
    gap: 30px;
    padding-left: 25px;
    padding-right: 0px;
    margin-top: 25px;
  }

  .business-card-1 {
    background-color: #F1F6FB;
    width: 420px;
    height: 230px;
    border-radius: 10px;
  }

  .business-card-2 {
    background-color: #F1F6FB;
    width: 420px;
    height: 230px;
    border-radius: 10px;
  }

  .business-card-3 {
    background-color: #F1F6FB;
    width: 420px;
    height: 230px;
    border-radius: 10px;
  }

  .engineer-card-1 {
    background-color: #F1F6FB;
    width: 420px;
    height: 230px;
    border-radius: 10px;
  }

  .engineer-card-2 {
    background-color: #F1F6FB;
    width: 420px;
    height: 230px;
    border-radius: 10px;
  }

  .engineer-card-3 {
    background-color: #F1F6FB;
    width: 420px;
    height: 230px;
    border-radius: 10px;
  }

  .engineer-card-container {
    display: flex;
    align-items: center;
    grid-gap: 55px;
    gap: 30px;
    padding-left: 25px;
    padding-right: 0px;
    margin-top: 25px;
  }

  .how-we-work-container {
    margin-top: 120px;
    margin-left: 30px;
    margin-right: 0px;
    display: flex;
    justify-content: space-between;
    grid-gap: 135px;
    gap: 100px;
  }

  .how-we-work-text-container {
    width: 500px;
  }

  .how-we-work-text-heading {
    color: #003FAA;
    font-weight: 400;
    font-style: inter;
    font-size: 26px;
    line-height: 30px;
    margin-top: -32px;
  }

  .how-we-work-text-paragraph {
    color: #E67844;
    font-weight: 400;
    font-style: inter;
    font-size: 14px;
    line-height: 18px;
  }

  .how-we-work-container-2 {
    margin-top: 88px;
    margin-left: 26px;
    display: flex;
    justify-content: space-between;
    grid-gap: 0px;
    gap: 0px;
    align-items: center;
  }

  .innovation-heading {
    width: 500px;
    margin-right: 30px;
  }

  .how-we-work-switch-btn {
    justify-content: center;
    display: flex;
    margin-top: 1px;
    color: #003FAA;
    font-size: 25px;
    font-weight: 400;
    line-height: 26px;
    font-style: inter;
    font-family: inter;
    margin-bottom: 21px;
  }

  .how-we-work-engineer-container {
    position: relative;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 0px;
    display: flex;
    justify-content: space-evenly;
    grid-gap: 50px;
    gap: 12px;
  }

  .why-us-container {
    background-color: #F1F6FB;
    height: 740px;
    border-radius: 50px;
    margin-top: 50px;
    margin-left: 14px;
    margin-right: 0px;
    margin-bottom: 30px;
    border: 2px solid #E67844;
    width: 100%;
    max-width: 100%;
  }

  .why-us-heading {
    display: flex;
    justify-content: center;
    margin-top: 35px;
    margin-bottom: 1.5%;
    font-family: inter;
    font-weight: 400;
    font-size: 29px;
    line-height: 37px;
    color: #E67844;
  }

  .why-us-card-container {
    display: flex;
    justify-content: center;
    grid-gap: 30px;
    gap: 30px;
    padding-left: 27px;
    padding-right: 22px;
    margin-top: 50px;
  }

  .why-us-card-1-heading {
    margin-left: 0px;
    color: #E67844;
    font-size: 25px;
    line-height: 40px;
    font-weight: 700;
    font-style: inter;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .why-us-card-2-heading {
    margin-left: 0px;
    color: #E67844;
    font-size: 25px;
    line-height: 40px;
    font-weight: 700;
    font-style: inter;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .why-us-card-3-heading {
    margin-left: 0px;
    color: #E67844;
    font-size: 25px;
    line-height: 40px;
    font-weight: 700;
    font-style: inter;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .why-us-card-4-heading {
    margin-left: 0px;
    color: #E67844;
    font-size: 25px;
    line-height: 40px;
    font-weight: 700;
    font-style: inter;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .meet-our-register-btn {
    margin-left: 20px;
    margin-right: 14px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    text-align: center;
  }

  .meet-our-heading {
    color: #003FAA;
    font-family: inter;
    font-weight: 400;
    font-size: 24px;
    line-height: 48px;
  }

  .gig-peoples-container {
    margin-left: 30px;
    margin-right: 20px;
    margin-top: 2%;
  }

  .testimonials-heading {
    display: flex;
    justify-content: center;
    color: #003FAA;
    font-size: 31px;
    font-weight: 400;
    line-height: 73px;
    font-style: inter;
    padding-top: 20px;
  }

  .main-container-testimo {
    display: flex;
    margin-top: 22px;
  }

  .main-container-testimo-1 {
    display: flex;
    margin-top: 22px;
  }

  .testimonials-card-1 {
    width: 100% !important;
    background-color: #fff;
    height: 270px;
    padding-top: 22px;
    padding-left: 15px;
    border-radius: 12px;
  }

  .class-image-signup {
    position: absolute;
    padding-left: 32px;
    padding-right: 0px;
  }

  .start-a-project-homepage {
    position: relative;
    margin-left: 10px;
    margin-right: 10px;
  }

  .Sign-up-as-a-business-para {
    color: #EDF1F4;
    font-weight: 400;
    line-height: 20px;
    font-size: 10px;
    font-style: inter;
    width: 425px;
  }

  .MuiButton-root.start-project-btn-homepage {
    background-color: #FFDBCC;
    width: 145px;
    height: 44px;
    border-radius: 14px;
    cursor: pointer;
    margin-top: 5px;
    line-height: 28px;
    font-weight: 700;
    font-size: 10px;
    font-style: inter;
    color: #2354AE;
  }

  .get-connected-text {
    color: #003FAA;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 30px;
    font-size: 30px;
    font-weight: 400;
    line-height: 72px;
    font-family: inter;
    font-style: inter;
    padding-top: 35px;
  }

  .get-connected-container {
    margin-left: 20px;
    margin-right: 20px;
    display: flex;
    grid-gap: 65px;
    gap: 65px;
  }

  .faq-homepage-container {
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 5px;
    display: flex;
  }

  .faq-heading-homepage {
    color: #E67844;
    line-height: 58px;
    font-size: 25px;
    font-weight: 700;
    font-family: inter;
    font-style: inter;
    margin-bottom: 0px;
  }

  .newletter-container {
    display: flex;
    margin-left: 25px;
    margin-right: 0px;
    margin-top: 30px;
    margin-bottom: 40px;
    background-color: #E6EFFA;
    height: 330px;
    border-radius: 30px;
    grid-gap: 20px;
    gap: 20px;
  }

  .how-we-work-business-container {
    position: relative;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 0px;
    display: flex;
    justify-content: space-evenly;
    grid-gap: 12px;
    gap: 12px;
  }

  .business-01 {
    color: #E67844;
    font-weight: 700;
    font-size: 39px;
    font-family: inter;
    line-height: 85px;
  }

  .business-02 {
    color: #E67844;
    font-weight: 700;
    font-size: 39px;
    font-family: inter;
    line-height: 85px;
  }

  .business-03 {
    color: #E67844;
    font-weight: 700;
    font-size: 39px;
    font-family: inter;
    line-height: 85px;
  }

  .engineer-01 {
    color: #E67844;
    font-weight: 700;
    font-size: 39px;
    font-family: inter;
    line-height: 85px;
  }

  .engineer-02 {
    color: #E67844;
    font-weight: 700;
    font-size: 39px;
    font-family: inter;
    line-height: 85px;
  }

  .engineer-03 {
    color: #E67844;
    font-weight: 700;
    font-size: 39px;
    font-family: inter;
    line-height: 85px;
  }

  .paragraph-engineer-1 {
    margin-left: 10%;
    margin-right: 5px;
    font-size: 19px;
    font-weight: 700;
    line-height: 30px;
    font-family: inter;
    color: #003FAA;
    margin-top: 0px;
    margin-bottom: 3px;
  }

  .paragraph-engineer-2 {
    margin-left: 10%;
    margin-right: 5px;
    font-size: 19px;
    font-weight: 700;
    line-height: 30px;
    font-family: inter;
    color: #003FAA;
    margin-top: 0px;
    margin-bottom: 3px;
  }

  .paragraph-engineer-3 {
    margin-left: 10%;
    margin-right: 5px;
    font-size: 19px;
    font-weight: 700;
    line-height: 30px;
    font-family: inter;
    color: #003FAA;
    margin-top: 0px;
    margin-bottom: 3px;
  }

  .paragraph-business-1 {
    margin-left: 10%;
    margin-right: 5px;
    font-size: 19px;
    font-weight: 700;
    line-height: 30px;
    font-family: inter;
    color: #003FAA;
    margin-top: 0px;
    margin-bottom: 3px;
  }

  .paragraph-business-2 {
    margin-left: 10%;
    margin-right: 5px;
    font-size: 19px;
    font-weight: 700;
    line-height: 30px;
    font-family: inter;
    color: #003FAA;
    margin-top: 0px;
    margin-bottom: 3px;
  }

  .paragraph-business-3 {
    margin-left: 10%;
    margin-right: 5px;
    font-size: 19px;
    font-weight: 700;
    line-height: 30px;
    font-family: inter;
    color: #003FAA;
    margin-top: 0px;
    margin-bottom: 3px;
  }

  .text-engineer-1 {
    margin-left: 10%;
    margin-right: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    font-family: inter;
    color: #003FAA;
    margin-top: 0px;
  }

  .text-engineer-2 {
    margin-left: 10%;
    margin-right: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    font-family: inter;
    color: #003FAA;
    margin-top: 0px;
  }

  .text-engineer-3 {
    margin-left: 10%;
    margin-right: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    font-family: inter;
    color: #003FAA;
    margin-top: 0px;
  }

  .text-business-1 {
    margin-left: 10%;
    margin-right: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    font-family: inter;
    color: #003FAA;
    margin-top: 0px;
  }

  .text-business-2 {
    margin-left: 10%;
    margin-right: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    font-family: inter;
    color: #003FAA;
    margin-top: 0px;
  }

  .text-business-3 {
    margin-left: 10%;
    margin-right: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    font-family: inter;
    color: #003FAA;
    margin-top: 0px;
  }

  .how-we-work-business-card-1 {
    width: 170px;
    height: 280px;
    min-height: 280px;
    max-width: 170px;
  }

  .how-we-work-business-card-2 {
    width: 170px;
    height: 280px;
    min-height: 280px;
    max-width: 170px;
  }

  .how-we-work-business-card-3 {
    width: 170px;
    height: 280px;
    min-height: 280px;
    max-width: 170px;
  }

  .how-we-work-business-card-4 {
    width: 170px;
    height: 280px;
    min-height: 280px;
    max-width: 170px;
  }

  .how-we-work-engineer-card-1 {
    width: 165px;
    height: 280px;
    min-height: 280px;
    max-width: 165px;
  }

  .how-we-work-engineer-card-2 {
    width: 165px;
    height: 280px;
    min-height: 280px;
    max-width: 165px;
  }

  .how-we-work-engineer-card-3 {
    width: 165px;
    height: 280px;
    min-height: 280px;
    max-width: 165px;
  }

  .how-we-work-engineer-card-4 {
    width: 165px;
    height: 280px;
    min-height: 280px;
    max-width: 165px;
  }

}

.how-we-work-img-1-b {
  max-width: 260px;
  width: 260px;
  height: 150px;
  max-height: 150px;
}

.how-we-work-img-2-b {
  max-width: 260px;
  width: 260px;
  height: 150px;
  max-height: 150px;
}

.how-we-work-img-3-b {
  max-width: 246px;
  width: 246px;
  height: 150px;
  max-height: 150px;
}

.how-we-work-img-4-b {
  max-width: 260px;
  width: 260px;
  height: 150px;
  max-height: 150px;
}

.how-we-work-img-1-e {
  max-width: 260px;
  width: 260px;
  height: 150px;
  max-height: 150px;
}

.how-we-work-img-2-e {
  max-width: 260px;
  width: 260px;
  height: 150px;
  max-height: 150px;
}

.how-we-work-img-3-e {
  max-width: 260px;
  width: 260px;
  height: 150px;
  max-height: 150px;
}

.how-we-work-img-4-e {
  max-width: 260px;
  width: 260px;
  height: 150px;
  max-height: 150px;
}

@media only screen and (min-width: 1000px) and (max-width: 1100px) {
  .sign-up-and-connected-container {
    background-color: #F1F6FB;
    height: 1058px;
    padding-top: 4%;
    width: 1054px;
    max-width: 1054px;
  }

  .testimonials-container {
    background-color: #FFDBCC;
    height: 450px;
    margin-top: 64px;
    width: 1056px;
    max-width: 1056px;
  }

  .get-connected-text {
    color: #003FAA;
    margin-left: 20px;
    margin-right: 20px;
    margin-top: 115px;
    font-size: 30px;
    font-weight: 400;
    line-height: 72px;
    font-family: inter;
    font-style: inter;
  }

  .sign-up-and-connected-container {
    background-color: #F1F6FB;
    height: 1195px;
    padding-top: 69px;
    width: 1056px;
    max-width: 1056px;
  }
}

@media only screen and (min-width: 1600px) and (max-width: 2500px) {
  .d1-component {
    width: 700px;
    max-width: 700px;
    margin-top: 107px;
  }

  .d1-component h1 {
    line-height: 50px;
    font-size: 40px;
    color: #003FAA;
    font-weight: 400;
    font-family: inter;
    width: 700px;
    max-width: 700px;
  }

  .d1-component p {
    color: #E67844;
    font-size: 16px;
    font-weight: 400;
    line-height: 21.37px;
    font-family: inter;
    width: 700px;
    max-width: 700px;
  }

  .homePage1-img {
    margin-top: -338px;
    margin-left: 35px;
  }

}

@media only screen and (min-width: 1915px) and (max-width: 2400px) {
  .toolbar-or-logo {
    margin-left: -50px;
  }

  .Sign-up-as-a-business-heading {
    color: #E67844;
    padding-left: 110px;
    padding-top: 120px;
    font-weight: 700;
    line-height: 60px;
    font-size: 44px;
    font-style: inter;
    margin-bottom: 0px;
  }

  .Sign-up-as-a-business-para {
    color: #EDF1F4;
    font-weight: 400;
    line-height: 30px;
    font-size: 24px;
    font-style: inter;
    width: 65%;
  }

  .MuiButton-root.start-project-btn-homepage {
    background-color: #FFDBCC;
    width: 187px;
    height: 66px;
    border-radius: 14px;
    cursor: pointer;
    margin-top: 5px;
    line-height: 28px;
    font-weight: 700;
    font-size: 20px;
    font-style: inter;
    color: #2354AE;
  }

  .sign-up-and-connected-container {
    background-color: #F1F6FB;
    height: 1400px;
    padding-top: 80px;
  }

  .faq-homepage-container {
    margin-left: 160px;
    margin-right: 100px;
    margin-top: 10px;
    display: flex;
    padding-top: 80px;
  }

  .newletter-container {
    display: flex;
    margin-left: 160px;
    margin-right: 160px;
    margin-top: 50px;
    margin-bottom: 66px;
    background-color: #E6EFFA;
    height: 406px;
    border-radius: 60px;
    grid-gap: 40px;
    gap: 40px;
  }

  .newletter-text {
    font-family: inter;
    font-size: 30px;
    font-weight: 700;
    line-height: 50px;
    color: #E67844;
    margin-top: 50px;
    margin-bottom: 5px;
  }

  .newletter-paragraph {
    color: #838383;
    font-family: inter;
    font-size: 20px;
    font-weight: 400;
    line-height: 33px;
    margin-top: 22px;
  }
}

@media only screen and (min-width: 2500px) and (max-width: 2800px) {
  .toolbar-or-logo {
    margin-left: -500px;
  }

  .get-connected-text {
    color: #003FAA;
    margin-left: 160px;
    margin-right: 150px;
    margin-top: 300px;
    font-size: 36px;
    font-weight: 400;
    line-height: 72px;
    font-family: inter;
    font-style: inter;
  }

  .sign-up-and-connected-container {
    background-color: #F1F6FB;
    height: 1500px;
    padding-top: 100px;
  }

  .faq-homepage-container {
    margin-left: 160px;
    margin-right: 100px;
    margin-top: 86px;
    display: flex;
  }

  .newletter-img {
    width: 600px;
    display: flex;
    justify-content: center;
    margin-left: 356px;
  }

  .why-us-container {
    background-color: #F1F6FB;
    height: 907px;
    border-radius: 100px;
    margin-top: 10%;
    margin-left: 160px;
    margin-right: 150px;
    margin-bottom: 4%;
    border: 2px solid #E67844;
  }

  .Sign-up-as-a-business-para {
    color: #EDF1F4;
    font-weight: 400;
    line-height: 22px;
    font-size: 14px;
    font-style: inter;
    width: 1100px;
  }
}

@media only screen and (min-width: 1040px) and (max-width: 2600px) {
  .why-us-container {
    background-color: #F1F6FB;
    height: 790px;
    border-radius: 100px;
    margin-top: 5px;
    margin-left: 160px;
    margin-right: 150px;
    margin-bottom: 20px;
    border: 2px solid #E67844;
  }

  .why-us-heading {
    display: flex;
    justify-content: center;
    margin-top: 5%;
    margin-bottom: 1.5%;
    font-family: inter;
    font-weight: 400;
    font-size: 35px;
    line-height: 40px;
    color: #E67844;
  }

  .why-us-text {
    display: flex;
    justify-content: center;
    font-family: inter;
    font-weight: 400;
    font-size: 17px;
    line-height: 22px;
    color: #003FAA;
    padding-left: 27%;
    padding-right: 27%;
    text-align: center;
  }

  .why-us-card-container {
    display: flex;
    justify-content: center;
    gap: 70px;
    padding-left: 7%;
    padding-right: 7%;
    margin-bottom: -15px;
  }

  .why-us-card-1 {
    width: 43%;
    height: 260px;
    margin-top: 5px;
    border-radius: 30px;
  }

  .why-us-card-1:hover {
    width: 43%;
    height: 260px;
    margin-top: 4%;
    border-radius: 30px;
    background-color: #fff;
    transform: scale(1.05);
    transition: all 0.5s linear;
  }

  .why-us-card-1-img {
    margin-top: 90px;
    margin-left: 8%;
  }

  .why-us-card-1:hover .why-us-card-1-img {
    width: 750px;
    margin-top: 6%;
  }

  .why-us-card-1-heading {
    margin-left: 8%;
    color: #E67844;
    font-size: 30px;
    line-height: 40px;
    font-weight: 700;
    font-style: inter;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .why-us-card-1-para {
    margin-left: 8.7%;
    margin-right: 4%;
    color: #003FAA;
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
    font-family: inter;
    margin-top: 2px;
  }

  .why-us-card-1:hover .why-us-card-1-para {
    margin-left: 8.7%;
    margin-right: 4%;
    color: #003FAA;
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
    font-family: inter;
    margin-top: 2px;
  }

  .why-us-card-1:hover .why-us-card-1-heading {
    color: #003FAA;
  }

  .why-us-card-2 {
    width: 43%;
    height: 260px;
    margin-top: 5px;
    border-radius: 10px;
  }

  .why-us-card-2:hover {
    width: 43%;
    height: 260px;
    margin-top: 4%;
    border-radius: 20px;
    background-color: #fff;
    transform: scale(1.05);
    transition: all 0.5s linear;
  }

  .why-us-card-2-img {
    margin-top: 90px;
    margin-left: 8%;
  }

  .why-us-card-2:hover .why-us-card-2-img {
    width: 750px;
    margin-top: 6%;
  }

  .why-us-card-2-heading {
    margin-left: 8%;
    color: #E67844;
    font-size: 30px;
    line-height: 40px;
    font-weight: 700;
    font-style: inter;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .why-us-card-2-para {
    margin-left: 8.7%;
    margin-right: 4%;
    color: #003FAA;
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
    font-family: inter;
    margin-top: 2px;
  }

  .why-us-card-2:hover .why-us-card-2-para {
    margin-left: 8.7%;
    margin-right: 4%;
    color: #003FAA;
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
    font-family: inter;
    margin-top: 2px;
  }

  .why-us-card-2:hover .why-us-card-2-heading {
    color: #003FAA;
  }

  .why-us-card-3 {
    width: 43%;
    height: 260px;
    border-radius: 10px;
  }

  .why-us-card-3:hover {
    width: 43%;
    height: 260px;
    border-radius: 20px;
    background-color: #fff;
    transform: scale(1.05);
    transition: all 0.5s linear;
  }

  .why-us-card-3-img {
    margin-top: 15%;
    margin-left: 8%;
  }

  .why-us-card-3:hover .why-us-card-3-img {
    width: 750px;
    margin-top: 6%;
  }

  .why-us-card-3-heading {
    margin-left: 8%;
    color: #E67844;
    font-size: 30px;
    line-height: 40px;
    font-weight: 700;
    font-style: inter;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .why-us-card-3-para {
    margin-left: 8.7%;
    margin-right: 4%;
    color: #003FAA;
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
    font-family: inter;
    margin-top: 2px;
  }

  .why-us-card-3:hover .why-us-card-3-para {
    margin-left: 8.7%;
    margin-right: 4%;
    color: #003FAA;
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
    font-family: inter;
    margin-top: 2px;
  }

  .why-us-card-3:hover .why-us-card-3-heading {
    color: #003FAA;
  }

  .why-us-card-4 {
    width: 43%;
    height: 260px;
    border-radius: 20px;
  }

  .why-us-card-4:hover {
    width: 43%;
    height: 260px;
    border-radius: 20px;
    background-color: #fff;
    transform: scale(1.05);
    transition: all 0.5s linear;
  }

  .why-us-card-4-img {
    margin-top: 15%;
    margin-left: 8%;
  }

  .why-us-card-4:hover .why-us-card-4-img {
    width: 750px;
    margin-top: 6%;
  }

  .why-us-card-4-heading {
    margin-left: 8%;
    color: #E67844;
    font-size: 30px;
    line-height: 40px;
    font-weight: 700;
    font-style: inter;
    margin-top: 0px;
    margin-bottom: 0px;
  }

  .why-us-card-4-para {
    margin-left: 8.7%;
    margin-right: 4%;
    color: #003FAA;
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
    font-family: inter;
    margin-top: 2px;
  }

  .why-us-card-4:hover .why-us-card-4-para {
    margin-left: 8.7%;
    margin-right: 4%;
    color: #003FAA;
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
    font-family: inter;
    margin-top: 2px;
  }

  .why-us-card-4:hover .why-us-card-4-heading {
    color: #003FAA;
  }
}

@media only screen and (min-width: 330px) and (max-width: 370px) {
  .toolbar-or-logo {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: 10px;
    flex-direction: row-reverse;
    gap: 97px;
  }
}

@media only screen and (min-width: 370px) and (max-width: 400px) {
  .toolbar-or-logo {
    display: flex;
    justify-content: space-between;
    margin-left: -8px;
    flex-direction: row-reverse;
    gap: 108px;
  }
}

@media only screen and (min-width: 330px) and (max-width: 400px) {
  .signup-img-homepage-1 {
    width: 100%;
    height: 100%;
    margin-left: 20px;
  }

  .d1-component p {
    color: #E67844;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.37px;
    font-family: inter;
    padding-right: 0px;
    max-width: 360px;
    width: 360px;
  }

  .get-connected-text {
    color: #003FAA;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 35px;
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    font-family: inter;
    font-style: inter;
    display: flex;
    justify-content: center;
  }

  .sign-up-and-connected-container {
    background-color: #F1F6FB;
    height: 1740px;
    padding-top: 45px;
    margin-left: -10px;
    margin-right: -16px;
  }

  .newletter-text {
    font-family: inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    color: #E67844;
    margin-top: 7%;
    margin-bottom: 5px;
    width: 180px;
  }

  .input-or-sub-btn {
    display: block;
    justify-content: center;
    align-items: center;
    width: 285px;
    max-width: 285px;
    padding-left: 0px;
    padding-right: 0px;
    gap: 5px;
    margin-top: 20px;
  }

  .para-btn-project-page {
    display: block;
    padding-left: 3px;
    padding-top: 0px;
    padding-right: 2px;
    grid-gap: 190px;
    gap: 190px;
    align-items: baseline;
  }

  .Sign-up-as-a-business-para {
    color: #EDF1F4;
    font-weight: 400;
    line-height: 25px;
    font-size: 16px;
    font-style: inter;
    width: 100%;
    padding-right: 90px;
  }
}

@media only screen and (min-width: 400px) and (max-width: 750px) {
  .signup-img-homepage-1 {
    width: 100%;
    height: 100%;
    margin-left: 36px;
  }

  .d1-component p {
    color: #E67844;
    font-size: 16px;
    font-weight: 400;
    line-height: 19.37px;
    font-family: inter;
    padding-right: 0px;
    max-width: 410px;
    width: 410px;
  }

  .get-connected-text {
    color: #003FAA;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 35px;
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    font-family: inter;
    font-style: inter;
    display: flex;
    justify-content: center;
  }

  .sign-up-and-connected-container {
    background-color: #F1F6FB;
    height: 1780px;
    padding-top: 45px;
    margin-left: -10px;
    margin-right: -15px;
  }

  .newletter-text {
    font-family: inter;
    font-size: 24px;
    font-weight: 700;
    line-height: 30px;
    color: #E67844;
    margin-top: 7%;
    margin-bottom: 5px;
    width: 310px;
  }

  .input-or-sub-btn {
    display: block;
    justify-content: center;
    align-items: center;
    width: 335px;
    max-width: 335px;
    padding-left: 0px;
    padding-right: 0px;
    gap: 5px;
    margin-top: 20px;
  }

  .para-btn-project-page {
    display: block;
    padding-left: 8px;
    padding-top: 0px;
    padding-right: 2px;
    grid-gap: 190px;
    gap: 190px;
    align-items: baseline;
  }

  .Sign-up-as-a-business-para {
    color: #EDF1F4;
    font-weight: 400;
    line-height: 30px;
    font-size: 16px;
    font-style: inter;
    width: 100%;
    padding-right: 90px;
    padding-bottom: 15px;
  }

  .toolbar-or-logo {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-left: 0px;
    flex-direction: row-reverse;
    gap: 115px;
  }
}

@media only screen and (min-width: 750px) and (max-width: 1030px) {
  .how-we-work-img-1-b {
    max-width: 200px;
    width: 200px;
    height: 80px;
    max-height: 80px;
  }

  .how-we-work-img-2-b {
    max-width: 200px;
    width: 200px;
    height: 80px;
    max-height: 80px;
  }

  .how-we-work-img-3-b {
    max-width: 200px;
    width: 200px;
    height: 80px;
    max-height: 80px;
  }

  .how-we-work-img-4-b {
    max-width: 200px;
    width: 200px;
    height: 80px;
    max-height: 80px;
  }

  .how-we-work-img-1-e {
    max-width: 200px;
    width: 200px;
    height: 80px;
    max-height: 80px;
  }

  .how-we-work-img-2-e {
    max-width: 200px;
    width: 200px;
    height: 80px;
    max-height: 80px;
  }

  .how-we-work-img-3-e {
    max-width: 200px;
    width: 200px;
    height: 80px;
    max-height: 80px;
  }

  .how-we-work-img-4-e {
    max-width: 200px;
    width: 200px;
    height: 80px;
    max-height: 80px;
  }

  .why-us-text {
    padding-left: 35px;
  }

  .why-us-card-1-para {
    margin-top: 10px;
  }

  .why-us-card-2-para {
    margin-top: 10px;
  }

  .why-us-card-3-para {
    margin-top: 10px;
  }

  .why-us-card-4-para {
    margin-top: 10px;
  }

  .why-us-card-3 {
    width: 50%;
  }

  .why-us-card-4 {
    width: 50%;
  }
}

.business-card-container {
  transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;
  opacity: 1;
  visibility: visible;
}

.engineer-card-container {
  transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;
  opacity: 1;
  visibility: visible;
}

.business-card-container.active {
  opacity: 0;
  visibility: hidden;
}

.engineer-card-container.active {
  opacity: 0;
  visibility: hidden;
}

.toggle-2-card-container {
  transition: opacity 0.25s ease-in-out, visibility 0.25s ease-in-out;
  opacity: 1;
  visibility: visible;
}

.toggle-2-card-container.active {
  opacity: 0;
  visibility: hidden;
}

@media only screen and (min-width: 1500px) and (max-width: 2500px) {
  .how-we-work-container {
    margin-top: 0px;
    grid-gap: 135px;
    gap: 135px;
    padding-top: 120px;
  }
  }

@media only screen and (min-width: 1030px) and (max-width: 1410px) {
  .how-we-work-business-container {
    position: relative;
    margin-top: 20px;
    margin-left: 30px;
    margin-right: 0px;
    display: flex;
    justify-content: space-evenly;
    grid-gap: 12px;
    gap: 12px;
  }

  .business-01 {
    color: #E67844;
    font-weight: 700;
    font-size: 39px;
    font-family: inter;
    line-height: 85px;
  }

  .business-02 {
    color: #E67844;
    font-weight: 700;
    font-size: 39px;
    font-family: inter;
    line-height: 85px;
  }

  .business-03 {
    color: #E67844;
    font-weight: 700;
    font-size: 39px;
    font-family: inter;
    line-height: 85px;
  }

  .engineer-01 {
    color: #E67844;
    font-weight: 700;
    font-size: 39px;
    font-family: inter;
    line-height: 85px;
  }

  .engineer-02 {
    color: #E67844;
    font-weight: 700;
    font-size: 39px;
    font-family: inter;
    line-height: 85px;
  }

  .engineer-03 {
    color: #E67844;
    font-weight: 700;
    font-size: 39px;
    font-family: inter;
    line-height: 85px;
  }

  .paragraph-engineer-1 {
    margin-left: 10%;
    margin-right: 5px;
    font-size: 19px;
    font-weight: 700;
    line-height: 30px;
    font-family: inter;
    color: #003FAA;
    margin-top: 0px;
    margin-bottom: 3px;
  }

  .paragraph-engineer-2 {
    margin-left: 10%;
    margin-right: 5px;
    font-size: 19px;
    font-weight: 700;
    line-height: 30px;
    font-family: inter;
    color: #003FAA;
    margin-top: 0px;
    margin-bottom: 3px;
  }

  .paragraph-engineer-3 {
    margin-left: 10%;
    margin-right: 5px;
    font-size: 19px;
    font-weight: 700;
    line-height: 30px;
    font-family: inter;
    color: #003FAA;
    margin-top: 0px;
    margin-bottom: 3px;
  }

  .paragraph-business-1 {
    margin-left: 10%;
    margin-right: 5px;
    font-size: 19px;
    font-weight: 700;
    line-height: 30px;
    font-family: inter;
    color: #003FAA;
    margin-top: 0px;
    margin-bottom: 3px;
  }

  .paragraph-business-2 {
    margin-left: 10%;
    margin-right: 5px;
    font-size: 19px;
    font-weight: 700;
    line-height: 30px;
    font-family: inter;
    color: #003FAA;
    margin-top: 0px;
    margin-bottom: 3px;
  }

  .paragraph-business-3 {
    margin-left: 10%;
    margin-right: 5px;
    font-size: 19px;
    font-weight: 700;
    line-height: 30px;
    font-family: inter;
    color: #003FAA;
    margin-top: 0px;
    margin-bottom: 3px;
  }

  .text-engineer-1 {
    margin-left: 10%;
    margin-right: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    font-family: inter;
    color: #003FAA;
    margin-top: 0px;
  }

  .text-engineer-2 {
    margin-left: 10%;
    margin-right: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    font-family: inter;
    color: #003FAA;
    margin-top: 0px;
  }

  .text-engineer-3 {
    margin-left: 10%;
    margin-right: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    font-family: inter;
    color: #003FAA;
    margin-top: 0px;
  }

  .text-business-1 {
    margin-left: 10%;
    margin-right: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    font-family: inter;
    color: #003FAA;
    margin-top: 0px;
  }

  .text-business-2 {
    margin-left: 10%;
    margin-right: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    font-family: inter;
    color: #003FAA;
    margin-top: 0px;
  }

  .text-business-3 {
    margin-left: 10%;
    margin-right: 10px;
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    font-family: inter;
    color: #003FAA;
    margin-top: 0px;
  }

  .how-we-work-business-card-1 {
    width: 170px;
    height: 280px;
    min-height: 280px;
    max-width: 170px;
  }

  .how-we-work-business-card-2 {
    width: 170px;
    height: 280px;
    min-height: 280px;
    max-width: 170px;
  }

  .how-we-work-business-card-3 {
    width: 170px;
    height: 280px;
    min-height: 280px;
    max-width: 170px;
  }

  .how-we-work-business-card-4 {
    width: 170px;
    height: 280px;
    min-height: 280px;
    max-width: 170px;
  }

  .how-we-work-engineer-card-1 {
    width: 165px;
    height: 280px;
    min-height: 280px;
    max-width: 165px;
  }

  .how-we-work-engineer-card-2 {
    width: 165px;
    height: 280px;
    min-height: 280px;
    max-width: 165px;
  }

  .how-we-work-engineer-card-3 {
    width: 165px;
    height: 280px;
    min-height: 280px;
    max-width: 165px;
  }

  .how-we-work-engineer-card-4 {
    width: 165px;
    height: 280px;
    min-height: 280px;
    max-width: 165px;
  }

  .why-us-container {
    background-color: #F1F6FB;
    height: 845px;
    border-radius: 100px;
    margin-top: 100px;
    margin-left: 100px;
    margin-right: 70px;
    margin-bottom: 20px;
    border: 2px solid #E67844;
  }

  .testimonials-container {
    background-color: #FFDBCC;
    height: 450px;
    margin-top: 64px;
    margin-right: -59px;
  }

  .sign-up-and-connected-container {
    background-color: #F1F6FB;
    height: 1075px;
    padding-top: 20px;
    margin-right: -59px;

  }

  .get-connected-text {
    color: #003FAA;
    margin-left: 160px;
    margin-right: 150px;
    margin-top: 70px;
    font-size: 36px;
    font-weight: 400;
    line-height: 72px;
    font-family: inter;
    font-style: inter;
    padding-top: 0px;
}

.para-btn-project-page {
  display: flex;
  padding-left: 75px;
  padding-top: 0px;
  padding-right: -26px;
  gap: 80px;
  align-items: baseline;
  margin-top: -20px;
}
}

@media only screen and (min-width: 1040px) and (max-width: 1300px) {
.Sign-up-as-a-business-para {
  color: #EDF1F4;
  font-weight: 400;
  line-height: 22px;
  font-size: 10px;
  font-style: inter;
  width: 65%;
  margin-left: -21px;
}
}

@media only screen and (min-width: 1040px) and (max-width: 1480px) {
.main-container-testimo {
  display: flex;
  margin-top: 32px;
  gap: 8px;
}
}

@media only screen and (min-width: 700px) and (max-width: 1100px) {
.line-1-img {
  position: absolute;
  margin-left: 15px;
  margin-right: 200px;
  margin-top: 15px;
  width: 100%;
}
}

@media only screen and (min-width: 410px) and (max-width: 750px) {
.line-1-img {
  position: absolute;
  margin-left: 53px;
  margin-top: 130px;
}
}

@media only screen and (min-width: 300px) and (max-width: 330px) {
  .main-home-page-container {
    background-color: white;
    width: 96%;
    height: 100%;
    padding-left: 10px;
    padding-right: 0px;
    padding-top: 50px;
    padding-bottom: 10px;
    margin-left: 0px;
  }

    .div1-container {
      width: 100%;
      display: block;
      justify-content: space-between;
      margin-top: 80px;
      margin-bottom: 8%;
      margin-right: 0px;
      margin-left: 0px;
    }
  
    .d1-component {
      width: 100%;
      max-width: 100%;
    }
  
    .d1-component h1 {
      line-height: 29px;
      font-size: 24px;
      color: #003FAA;
      font-weight: 500;
      font-family: inter;
    }
  
    .homePage1-img {
      margin-top: 45px;
      margin-left: -123px;
      width: 610px;
    }
  
    .member-benefit {
      justify-content: center;
      display: flex;
      margin-top: 50px;
      color: #003FAA;
      font-size: 24px;
      font-weight: 600;
      line-height: 29px;
      font-style: inter;
      font-family: inter;
      margin-bottom: 30px;
    }
  
    .business-card-container {
      display: block;
      align-items: center;
      grid-gap: 55px;
      gap: 55px;
      padding-left: 4px;
      padding-right: 0px;
    }
  
    .business-card-1 {
      background-color: #F1F6FB;
      width: 330px;
      height: 275px;
      border-radius: 10px;
    }
  
    .business-card-2 {
      background-color: #F1F6FB;
      width: 330px;
      height: 240px;
      border-radius: 10px;
      margin-top: 20px;
    }
  
    .business-card-3 {
      background-color: #F1F6FB;
      width: 330px;
      height: 240px;
      border-radius: 10px;
      margin-top: 20px;
    }
  
    .engineer-card-container {
      display: block;
      align-items: center;
      grid-gap: 55px;
      gap: 55px;
      padding-left: 4px;
      padding-right: 0px;
    }
  
    .engineer-card-1 {
      background-color: #F1F6FB;
      width: 330px;
      height: 275px;
      border-radius: 10px;
    }
  
    .engineer-card-2 {
      background-color: #F1F6FB;
      width: 330px;
      height: 240px;
      border-radius: 10px;
      margin-top: 20px;
    }
  
    .engineer-card-3 {
      background-color: #F1F6FB;
      width: 330px;
      height: 275px;
      border-radius: 10px;
      margin-top: 20px;
    }
  
    .how-we-work-container {
      margin-top: 20px;
      margin-left: 5px;
      margin-right: 5px;
      display: inline-flex;
      justify-content: space-between;
      gap: 21px;
      flex-direction: column-reverse;
    }
  
    .how-we-work-text-container {
      width: 100%;
    }
  
    .how-we-work-text-heading {
      color: #003FAA;
      font-weight: 500;
      font-style: inter;
      font-size: 22px;
      line-height: 29px;
    }
  
    .our-process-heading {
      display: flex;
      justify-content: center;
      margin-top: 50px;
      color: #E67844;
      font-weight: 400;
      line-height: 19px;
      font-size: 16px;
      font-family: inter;
      font-style: inter;
    }
  
    .how-we-work-switch-btn {
      justify-content: center;
      display: flex;
      margin-top: 6px;
      color: #003FAA;
      font-size: 24px;
      font-weight: 600;
      line-height: 29px;
      font-style: inter;
      font-family: inter;
      margin-bottom: 21px;
    }
  
    .how-we-work-business-container {
      margin-top: 40px;
      margin-left: 0px;
      margin-right: 0px;
      display: grid;
      justify-content: space-evenly;
      grid-gap: 0px;
      gap: 0px;
    }
  
    .how-we-work-business-card-1 {
      width: 100%;
    }
  
    .how-we-work-business-1 {
      color: #003FAA;
      font-size: 24px;
      font-weight: 500;
      line-height: 29px;
      font-family: inter;
      margin-top: 30px;
      display: flex;
      justify-content: center;
    }
  
    .how-we-work-img-1-b {
      display: flex;
      justify-content: center;
    }
  
    .how-we-work-business-para-1 {
      color: #003FAA;
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      font-family: inter;
      margin-top: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding-right: 2px;
      padding-left: 20px;
    }
  
    .how-we-work-business-card-2 {
      width: 100%;
      margin-top: 0px
    }
  
    .how-we-work-business-2 {
      color: #003FAA;
      font-size: 24px;
      font-weight: 500;
      line-height: 29px;
      font-family: inter;
      margin-top: 30px;
      display: flex;
      justify-content: center;
    }
  
    .how-we-work-img-2-b {
      display: flex;
      justify-content: center;
    }
  
    .how-we-work-business-para-2 {
      color: #003FAA;
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      font-family: inter;
      margin-top: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding-right: 2px;
      padding-left: 25px;
    }
  
    .how-we-work-business-card-3 {
      width: 100%;
      margin-top: 12px
    }
  
    .how-we-work-business-3 {
      color: #003FAA;
      font-size: 24px;
      font-weight: 500;
      line-height: 29px;
      font-family: inter;
      margin-top: 30px;
      display: flex;
      justify-content: center;
    }
  
    .how-we-work-img-3-b {
      display: flex;
      justify-content: center;
    }
  
    .how-we-work-business-para-3 {
      color: #003FAA;
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      font-family: inter;
      margin-top: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding-right: 5px;
      padding-left: 20px;
    }
  
    .how-we-work-business-card-4 {
      width: 100%;
      margin-top: 40px;
    }
  
    .how-we-work-business-4 {
      color: #003FAA;
      font-size: 24px;
      font-weight: 500;
      line-height: 29px;
      font-family: inter;
      margin-top: 30px;
      display: flex;
      justify-content: center;
    }
  
    .how-we-work-img-4-b {
      display: flex;
      justify-content: center;
    }
  
    .how-we-work-business-para-4 {
      color: #003FAA;
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      font-family: inter;
      margin-top: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding-right: 0px;
      padding-left: 25px;
    }
  
    .how-we-work-engineer-container {
      margin-top: 40px;
      margin-left: 0px;
      margin-right: 0px;
      display: grid;
      justify-content: space-evenly;
      grid-gap: 0px;
      gap: 0px;
    }
  
    .how-we-work-engineer-card-1 {
      width: 100%;
    }
  
    .how-we-work-engineer-1 {
      color: #003FAA;
      font-size: 24px;
      font-weight: 500;
      line-height: 29px;
      font-family: inter;
      margin-top: 30px;
      display: flex;
      justify-content: center;
    }
  
    .how-we-work-img-1-e {
      display: flex;
      justify-content: center;
    }
  
    .how-we-work-engineer-para-1 {
      color: #003FAA;
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      font-family: inter;
      margin-top: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding-right: 25px;
      padding-left: 25px;
    }
  
    .how-we-work-engineer-card-2 {
      width: 100%;
      margin-top: 50px;
    }
  
    .how-we-work-engineer-2 {
      color: #003FAA;
      font-size: 24px;
      font-weight: 500;
      line-height: 29px;
      font-family: inter;
      margin-top: 30px;
      display: flex;
      justify-content: center;
    }
  
    .how-we-work-img-2-e {
      display: flex;
      justify-content: center;
    }
  
    .how-we-work-engineer-para-2 {
      color: #003FAA;
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      font-family: inter;
      margin-top: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding-right: 40px;
      padding-left: 40px;
    }
  
    .how-we-work-engineer-card-3 {
      width: 100%;
      margin-top: 50px;
    }
  
    .how-we-work-engineer-3 {
      color: #003FAA;
      font-size: 24px;
      font-weight: 500;
      line-height: 29px;
      font-family: inter;
      margin-top: 30px;
      display: flex;
      justify-content: center;
    }
  
    .how-we-work-img-3-e {
      display: flex;
      justify-content: center;
    }
  
    .how-we-work-engineer-para-3 {
      color: #003FAA;
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      font-family: inter;
      margin-top: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding-right: 30px;
      padding-left: 30px;
    }
  
    .how-we-work-engineer-card-4 {
      width: 100%;
      margin-top: -20px;
    }
  
    .how-we-work-engineer-4 {
      color: #003FAA;
      font-size: 24px;
      font-weight: 500;
      line-height: 29px;
      font-family: inter;
      margin-top: 30px;
      display: flex;
      justify-content: center;
    }
  
    .how-we-work-img-4-e {
      display: flex;
      justify-content: center;
    }
  
    .how-we-work-engineer-para-4 {
      color: #003FAA;
      font-size: 16px;
      font-weight: 400;
      line-height: 19px;
      font-family: inter;
      margin-top: 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      padding-right: 25px;
      padding-left: 25px;
    }
  
    .why-us-container {
      background-color: #F1F6FB;
      height: 955px;
      border-radius: 10px;
      margin-top: 10px;
      margin-left: 2px;
      margin-right: 0px;
      margin-bottom: 4%;
      border: 2px solid #E67844;
      width: 335px;
    }
  
    .why-us-heading {
      display: flex;
      justify-content: center;
      margin-top: 7%;
      margin-bottom: 1.5%;
      font-family: inter;
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      color: #E67844;
    }
  
    .why-us-text {
      display: flex;
      justify-content: center;
      font-family: inter;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #003FAA;
      padding-left: 3%;
      padding-right: 3%;
      text-align: center;
      margin-top: 13px;
    }
  
    .why-us-card-container {
      display: block;
      justify-content: center;
      grid-gap: 25px;
      gap: 25px;
      padding-left: 3%;
      padding-right: 3%;
      margin-top: 40px;
    }
  
    .why-us-card-1 {
      width: 100%;
      height: 150px;
      margin-top: 4%;
      border-radius: 15px;
    }
  
    .why-us-card-1-heading {
      margin-left: 35px;
      color: #E67844;
      font-size: 20px;
      line-height: 24px;
      font-weight: 500;
      font-style: inter;
      margin-top: -33px;
      margin-bottom: 0px;
    }
  
    .why-us-card-1-para {
      margin-left: 35px;
      margin-right: 10px;
      color: #003FAA;
      font-size: 14px;
      line-height: 16px;
      font-weight: 400;
      font-family: inter;
      margin-top: 10px;
    }
  
    .why-us-card-2 {
      width: 100%;
      height: 150px;
      margin-top: 4%;
      border-radius: 15px;
    }
  
    .why-us-card-2-heading {
      margin-left: 40px;
      color: #E67844;
      font-size: 20px;
      line-height: 24px;
      font-weight: 500;
      font-style: inter;
      margin-top: -33px;
      margin-bottom: 0px;
    }
  
    .why-us-card-2-para {
      margin-left: 40px;
      margin-right: 10px;
      color: #003FAA;
      font-size: 14px;
      line-height: 16px;
      font-weight: 400;
      font-family: inter;
      margin-top: 10px;
    }
  
    .why-us-card-3 {
      width: 100%;
      height: 150px;
      margin-top: 4%;
      border-radius: 15px;
    }
  
    .why-us-card-3-heading {
      margin-left: 35px;
      color: #E67844;
      font-size: 20px;
      line-height: 24px;
      font-weight: 500;
      font-style: inter;
      margin-top: -33px;
      margin-bottom: 0px;
    }
  
    .why-us-card-3-para {
      margin-left: 35px;
      margin-right: 10px;
      color: #003FAA;
      font-size: 14px;
      line-height: 16px;
      font-weight: 400;
      font-family: inter;
      margin-top: 10px;
    }
  
    .why-us-card-4 {
      width: 100%;
      height: 150px;
      margin-top: 50px;
      border-radius: 15px;
    }
  
    .why-us-card-4-heading {
      margin-left: 35px;
      color: #E67844;
      font-size: 20px;
      line-height: 24px;
      font-weight: 500;
      font-style: inter;
      margin-top: -33px;
      margin-bottom: 0px;
    }
  
    .why-us-card-4-para {
      margin-left: 35px;
      margin-right: 10px;
      color: #003FAA;
      font-size: 14px;
      line-height: 16px;
      font-weight: 400;
      font-family: inter;
      margin-top: 10px;
    }
  
    .meet-our-register-btn {
      margin-left: 0px;
      margin-right: 0px;
      display: block;
      justify-content: space-evenly;
      align-items: center;
      text-align: center;
    }
  
    .meet-our-heading {
      color: #003FAA;
      font-family: inter;
      font-weight: 600;
      font-size: 24px;
      line-height: 29px;
      margin-top: 35px;
    }
  
    .register-btn-link {
      color: #fff;
      font-size: 15px;
      font-weight: 400;
      line-height: 21px;
      font-family: inter;
    }
  
    /* .slick-initialized .slick-slide {
      display: flex !important;
      justify-content: center !important;
      margin-left: 50px !important;
    } */
  
    .testimonials-container {
      background-color: #FFDBCC;
      height: 400px;
      margin-top: 60px;
      margin-left: -10px;
      margin-right: -53px;
    }
  
    .testimonials-heading {
      display: flex;
      justify-content: center;
      color: #003FAA;
      font-size: 24px;
      font-weight: 600;
      line-height: 29px;
      font-style: inter;
      padding-top: 7%;
      padding-bottom: 1%;
    }
  
    .how-we-work-text-paragraph {
      color: #E67844;
      font-weight: 400;
      font-style: inter;
      font-size: 16px;
      line-height: 19px;
      padding-right: 0px;
      width: 330px;
      max-width: 330px;
    }
  
    .homepage-img-2 {
      margin-top: 25px;
      display: flex;
      width: 100%;
      justify-content: center;
      margin-left: 3px;
    }
  
    .how-we-work-container-2 {
      margin-top: 7%;
      margin-left: 2%;
      margin-right: 2%;
      display: block;
      justify-content: space-between;
      grid-gap: 0px;
      gap: 0px;
      align-items: center;
    }
  
    .home-page-img-3 {
      width: 100%;
      margin-left: 3px;
      display: flex;
      justify-content: center;
    }
  
    .get-connected-container {
      margin-left: 5%;
      margin-right: 5%;
      display: block;
      grid-gap: 65px;
      gap: 65px;
    }
  
    .get-connected-input {
      width: 100%;
      background-color: #fff;
      height: 830px;
      border-radius: 8px;
      padding-top: 10%;
      padding-left: 4%;
      padding-right: 4%;
      margin-top: 40px;
    }
  
    .contact-us-text-bottom {
      color: #5A7184;
      font-weight: 400;
      line-height: 19px;
      font-size: 16px;
      font-style: inter;
      margin-top: 30px;
      margin-bottom: 40px;
    }
  
    .MuiButton-root.get-in-touch-btn {
      background-color: #003FAA;
      width: 100%;
      height: 48px;
      border-radius: 8px;
      cursor: pointer;
      margin-top: 5px;
      line-height: 19px;
      font-weight: 600;
      font-size: 16px;
      font-style: inter;
    }
  
    .mail-text-container {
      width: 100%;
      margin-top: 15px;
    }
  
    .class-image-signup {
      position: absolute;
      padding-left: 0px;
      padding-right: 0px;
    }
  
    .signup-img-homepage-6 {
      width: 0px;
      height: 0px;
    }
  
    .start-a-project-homepage {
      position: relative;
      margin-left: 0px;
      margin-top: 90px;
      width: 100%;
      padding-left: 50px;
    }
  
    .MuiButton-root.start-project-btn-homepage {
      background-color: #FFDBCC;
      width: 154px;
      height: 44px;
      border-radius: 8px;
      cursor: pointer;
      margin-top: 5px;
      line-height: 19px;
      font-weight: 500;
      font-size: 16px;
      font-style: inter;
      color: #2354AE;
    }
  
    .Sign-up-as-a-business-heading {
      color: #E67844;
      font-weight: 600;
      line-height: 29px;
      font-size: 26px;
      font-style: inter;
      margin-bottom: 0px;
      margin-left: -21px;
      margin-right: 0px;
    }
  
    .innovation-heading {
      width: 100%;
      margin-right: 0px;
    }
  
    .faq-homepage-container {
      display: block;
      width: 100%;
    }
  
    .faq-text-1 {
      width: 200px;
    }
  
    .faq-text-2 {
      width: 335px;
    }
  
    .accordion-item-header {
      padding: 0.5rem 3rem 0.5rem 1rem;
      min-height: 3rem;
      line-height: 26px;
      font-weight: 700;
      font-size: 16px;
      display: flex;
      align-items: center;
      position: relative;
      cursor: pointer;
      color: #003FAA;
      font-family: inter;
      font-style: inter;
    }
  
    .accordion-item-body-content {
      padding-left: 1rem;
      padding-right: 3rem;
      padding-bottom: 1rem;
      line-height: 20px;
      border-image: linear-gradient(to right, transparent, #34495e, transparent) 1;
      margin-top: -2px;
      font-size: 13px;
      font-family: inter;
      font-style: inter;
      font-weight: 400;
      color: #838383;
    }
  
    .faq-homepage-container {
      margin-left: 2px;
      margin-right: 0px;
      margin-top: 0px;
      padding-top: 30px;
    }

    .menu-item-container{
      margin-top: 50px !important;
      margin-left: 200px !important;
    }

      .Clients-Endorsers {
        font-size: 23px;
        font-weight: 500;
        line-height: 29px;
        font-family: inter;
        color: #003FAA;
        margin-top: 40px;
        width: 100%;
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
        margin-left: 18px;
      }
    
      .newletter-container {
        display: inline-flex;
        margin-left: 2px;
        margin-top: 0px;
        margin-bottom: 0px;
        background-color: #E6EFFA;
        height: 675px;
        border-radius: 20px;
        width: 334px;
        padding-top: 0px;
        padding-right: 0px;
        margin-right: 0px;
        padding-bottom: 60px;
        gap: 0px;
        flex-direction: column-reverse;
      }
    
      .newletter-text-para {
        width: 100%;
        padding-left: 10px;
      }
    
      .newletter-paragraph {
        color: #838383;
        font-family: inter;
        font-size: 15px;
        font-weight: 400;
        line-height: 20px;
        margin-top: 10px;
        width: 265px;
        max-width: 265px;
      }
    
      .newletter-img {
        width: 100%;
        display: flex;
        justify-content: center;
        margin-top: 40px;
      }
    
      .MuiButton-root.subscribe-btn-homepage {
        background-color: #003FAA;
        width: 100%;
        height: 54px;
        padding-right: 0px;
        border-radius: 12px;
        cursor: pointer;
        line-height: 21px;
        font-weight: 400;
        font-size: 14px;
        font-style: inter;
        color: #FCFCFC;
        text-transform: capitalize;
        margin-top: 18px;
      }

        .slick-prev {
          left: -17px !important;
          top: 140px !important;
        }
      
        .slick-next {
          right: -25px !important;
          top: 140px !important;
        }
      
        .accordion {
          width: 100%;
          max-width: 1000px;
          margin: 2rem auto;
        }
      
      .slick-slider .slick-list {
        justify-content: center !important;
        align-items: center;
        overflow: hidden;
      }
      
      .slick-slider .slick-track {
        display: flex !important;
        align-items: center;
      }
      
      .slick-slide > div {
        justify-content: center !important;
        flex-direction: column;
      }
      
      .slick-track {
        display: flex !important;
        justify-content: center !important;
        gap: 0px;
      }
      
      .testimo-line-break {
        border-bottom: 1px solid #2020200f;
        width: 100%;
        margin-top: 25px;
      }
      
      .testimonials-card-1-text {
        color: #202020;
        line-height: 25px;
        font-size: 14px;
        font-weight: 400;
        font-style: inter;
        margin-top: 0;
        padding-right: 0px;
      }
      
      .testimonials-card-1 {
        width: 100% !important;
        background-color: #fff;
        height: 250px;
        padding: 22px;
        border-radius: 12px;
      }
      
      .slick-initialized .slick-slide {
        display: block;
        padding: 0 14px;
      }
      
        .line-1-img {
          position: absolute;
          margin-left: 13px;
          margin-top: 95px;
        }
      
        .line-2-img-work {
          width: 0px;
        }
      
        .line-1-img-work {
          width: 320px;
          margin-top: 25px;
        }
      
        .text-business-1 {
          margin-left: 10%;
          margin-right: 10%;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          font-family: inter;
          color: #003FAA;
          margin-top: 15px;
        }
      
        .text-business-2 {
          margin-left: 10%;
          margin-right: 10%;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          font-family: inter;
          color: #003FAA;
          margin-top: 15px;
        }
      
        .text-business-3 {
          margin-left: 10%;
          margin-right: 10%;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          font-family: inter;
          color: #003FAA;
          margin-top: 15px;
        }
      
        .text-engineer-1 {
          margin-left: 10%;
          margin-right: 10%;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          font-family: inter;
          color: #003FAA;
          margin-top: 15px;
        }
      
        .text-engineer-2 {
          margin-left: 10%;
          margin-right: 10%;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          font-family: inter;
          color: #003FAA;
          margin-top: 15px;
        }
      
        .text-engineer-3 {
          margin-left: 10%;
          margin-right: 10%;
          font-size: 16px;
          font-weight: 400;
          line-height: 20px;
          font-family: inter;
          color: #003FAA;
          margin-top: 15px;
        }
      
        .paragraph-business-1 {
          margin-left: 10%;
          margin-right: 10%;
          font-size: 24px;
          font-weight: 500;
          line-height: 29px;
          font-family: inter;
          color: #003FAA;
          margin-top: 20px;
          margin-bottom: 3px;
          display: flex;
          justify-content: center;
        }
      
        .paragraph-business-2 {
          margin-left: 10%;
          margin-right: 10%;
          font-size: 23px;
          font-weight: 500;
          line-height: 29px;
          font-family: inter;
          color: #003FAA;
          margin-top: 20px;
          margin-bottom: 3px;
          display: flex;
          justify-content: center;
        }
      
        .paragraph-business-3 {
          margin-left: 10%;
          margin-right: 10%;
          font-size: 24px;
          font-weight: 500;
          line-height: 29px;
          font-family: inter;
          color: #003FAA;
          margin-top: 20px;
          margin-bottom: 3px;
          display: flex;
          justify-content: center;
        }
      
        .paragraph-engineer-1 {
          margin-left: 10%;
          margin-right: 10%;
          font-size: 24px;
          font-weight: 500;
          line-height: 29px;
          font-family: inter;
          color: #003FAA;
          margin-top: 20px;
          margin-bottom: 3px;
          display: flex;
          justify-content: center;
        }
      
        .paragraph-engineer-2 {
          margin-left: 10%;
          margin-right: 10%;
          font-size: 24px;
          font-weight: 500;
          line-height: 29px;
          font-family: inter;
          color: #003FAA;
          margin-top: 20px;
          margin-bottom: 3px;
          display: flex;
          justify-content: center;
        }
      
        .paragraph-engineer-3 {
          margin-left: 10%;
          margin-right: 10%;
          font-size: 24px;
          font-weight: 500;
          line-height: 29px;
          font-family: inter;
          color: #003FAA;
          margin-top: 20px;
          margin-bottom: 3px;
          display: flex;
          justify-content: center;
        }
      
        .text-engineer-1 {
          margin-left: 10%;
          margin-right: 10%;
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
          font-family: inter;
          color: #003FAA;
          margin-top: 15px;
          display: flex;
          text-align: center;
        }
      
        .text-engineer-2 {
          margin-left: 10%;
          margin-right: 10%;
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
          font-family: inter;
          color: #003FAA;
          margin-top: 15px;
          display: flex;
          text-align: center;
        }
      
        .text-engineer-3 {
          margin-left: 10%;
          margin-right: 10%;
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
          font-family: inter;
          color: #003FAA;
          margin-top: 15px;
          display: flex;
          text-align: center;
        }
      
        .text-business-1 {
          margin-left: 10%;
          margin-right: 10%;
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
          font-family: inter;
          color: #003FAA;
          margin-top: 15px;
          display: flex;
          text-align: center;
        }
      
        .text-business-2 {
          margin-left: 10%;
          margin-right: 10%;
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
          font-family: inter;
          color: #003FAA;
          margin-top: 15px;
          display: flex;
          text-align: center;
        }
      
        .text-business-3 {
          margin-left: 10%;
          margin-right: 10%;
          font-size: 16px;
          font-weight: 400;
          line-height: 19px;
          font-family: inter;
          color: #003FAA;
          margin-top: 15px;
          display: flex;
          text-align: center;
        }
      
        .img-number-1 {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 7%;
          display: grid;
          flex-direction: column;
          justify-content: center;
          padding-top: 30px;
        }
      
        .business-01 {
          color: #F1F6FB;
          font-weight: 700;
          font-family: inter;
          font-size: 0px;
          line-height: 0px;
        }
      
        .img-number-2 {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 7%;
          display: grid;
          flex-direction: column;
          justify-content: center;
          padding-top: 30px;
        }
      
        .business-02 {
          color: #F1F6FB;
          font-weight: 700;
          font-family: inter;
          font-size: 0px;
          line-height: 0px;
        }
      
        .img-number-3 {
          display: flex;
          justify-content: space-between;
          align-items: center;
          margin-top: 7%;
          display: grid;
          flex-direction: column;
          justify-content: center;
          padding-top: 30px;
        }
      
        .business-03 {
          color: #F1F6FB;
          font-weight: 700;
          font-family: inter;
          font-size: 0px;
          line-height: 0px;
        }
      
        .engineer-01 {
          color: #F1F6FB;
          font-weight: 700;
          font-family: inter;
          font-size: 0px;
          line-height: 0px;
        }
      
        .engineer-02 {
          color: #F1F6FB;
          font-weight: 700;
          font-family: inter;
          font-size: 0px;
          line-height: 0px;
        }
      
        .engineer-03 {
          color: #F1F6FB;
          font-weight: 700;
          font-family: inter;
          font-size: 0px;
          line-height: 0px;
        }
      
        .engineer-and-business-img {
          width: 60px;
        }
      
        .gig-peoples-container {
          margin-left: 50px;
          margin-right: 20px;
          margin-top: 0px;
          width: 300px;
          margin: 0 auto;
          margin-left: 18px;
        }
      
        .testimonials-card-container {
          margin-left: 0px;
          margin-right: 0px;
        }
      
        .main-page-image-1 {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          margin-left: -10px;
          margin-right: -53px;
        }
      
          .signup-img-homepage-1 {
            width: 100%;
            height: 100%;
            margin-left: 20px;
          }
        
          .d1-component p {
            color: #E67844;
            font-size: 16px;
            font-weight: 400;
            line-height: 19.37px;
            font-family: inter;
            padding-right: 0px;
            max-width: 340px;
            width: 340px;
        }
        
          .get-connected-text {
            color: #003FAA;
            margin-left: 0px;
            margin-right: 0px;
            margin-top: 35px;
            font-size: 24px;
            font-weight: 600;
            line-height: 29px;
            font-family: inter;
            font-style: inter;
            display: flex;
            justify-content: center;
          }
        
          .sign-up-and-connected-container {
            background-color: #F1F6FB;
            height: 1715px;
            padding-top: 45px;
            margin-left: -10px;
            margin-right: -53px;
          }
        
          .newletter-text {
            font-family: inter;
            font-size: 22px;
            font-weight: 700;
            line-height: 30px;
            color: #E67844;
            margin-top: 7%;
            margin-bottom: 5px;
            width: 180px;
          }
        
          .input-or-sub-btn {
            display: block;
            justify-content: center;
            align-items: center;
            width: 285px;
            max-width: 285px;
            padding-left: 0px;
            padding-right: 0px;
            gap: 5px;
            margin-top: 20px;
          }
        
          .para-btn-project-page {
            display: block;
            padding-left: 3px;
            padding-top: 0px;
            padding-right: 2px;
            grid-gap: 190px;
            gap: 190px;
            align-items: baseline;
          }
        
          .Sign-up-as-a-business-para {
            color: #EDF1F4;
            font-weight: 400;
            line-height: 25px;
            font-size: 16px;
            font-style: inter;
            width: 100%;
            padding-right: 45px;
          }

          .toolbar-or-logo {
            display: flex;
            justify-content: space-between;
            width: 100%;
            margin-left: 10px;
            flex-direction: row-reverse;
            gap: 85px;
          }
}

.email-link-1{
  text-decoration: none !important;
}

.testimonials-card-1-text::-webkit-scrollbar {
  width: 6px;
}

.testimonials-card-1-text::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
 
.testimonials-card-1-text::-webkit-scrollbar-thumb {
  background: #888; 
}

.testimonials-card-1-text::-webkit-scrollbar-thumb:hover {
  background: #555; 
}

@media only screen and (min-width: 2400px) and (max-width: 2500px) {
.get-connected-text {
  color: #003FAA;
  margin-left: 160px;
  margin-right: 150px;
  margin-top: 155px;
  font-size: 36px;
  font-weight: 400;
  line-height: 72px;
  font-family: inter;
  font-style: inter;
  padding-top: 75px;
}
}

@media only screen and (min-width: 1650px) and (max-width: 1915px) {
  .get-connected-text {
    color: #003FAA;
    margin-left: 160px;
    margin-right: 150px;
    margin-top: 155px;
    font-size: 36px;
    font-weight: 400;
    line-height: 72px;
    font-family: inter;
    font-style: inter;
    padding-top: 75px;
  }
  }

@media only screen and (min-width: 2000px) and (max-width: 2800px) {
  .testimonials-card-1 {
    width: 100% !important;
    background-color: #fff;
    height: 290px;
    padding-top: 22px;
    padding-left: 15px;
    border-radius: 12px;
  }
}

@media only screen and (min-width: 450px) and (max-width: 760px) {
  .get-connected-text {
    color: #003FAA;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 150px;
    font-size: 24px;
    font-weight: 600;
    line-height: 29px;
    font-family: inter;
    font-style: inter;
    display: flex;
    justify-content: center;
  }
}