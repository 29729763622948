.button-gig {
  display: flex;
  margin-top: 10px;
}

.text-sign-1 .text-signin-text-1 {
  color: #757575;
  font-size: 11px;
  font-weight: 500;
  font-family: Poppins;
}

.MuiButton-root.gig-btn {
  background-color: #0160bb;
  height: 34px;
  width: 134px;
  color: #ffffff;
  text-transform: none;
  font-size: 14px;
}

.MuiButton-root.gig-btn:hover {
  background-color: #0160bb;
}

.dialog-text .text-sign-1 {
  display: flex;
  font-weight: 500;
  justify-self: flex-end;
  margin-right: 6px;
}

.dialog-text {
  justify-content: center;
  text-align: center;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 30px;
  padding-bottom: 40px;
  border-radius: 20px;
  overflow: auto;
}

.MuiButton-root.google-btn {
  background-color: #ffffff;
  width: 271px;
  height: 32px;
  text-transform: none;
  border: 1.5px solid #d3d3d3;
  color: #5e5e5e;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin-right: 0%;
}

.forget-btn-signin-btn {
  display: flex;
  font-size: 11px;
  font-weight: 700;
  place-content: space-between;
}
.MuiButton-root.google-btn .contin-with-google {
  margin-top: 8px;
  margin-left: -10px;
}
.MuiButton-root.google-btn .contin-with-google-link {
  margin-top: 7px;
  margin-left: -5px;
}
.MuiButton-root.google-btn .continu-with-google-text {
  margin-left: 0px;
  font-size: 5.13;
  width: 100%;
}
.MuiButton-root.google-btn .continu-with-google-text-link {
  margin-left: 0px;
  font-size: 5.13;
  width: 100%;
}
.MuiButton-root.google-btn .logo-google-work {
  margin-left: -10px;
  margin-top: 8px;
}
.MuiButton-root.google-btn .logo-link-din-conti {
  margin-left: -5px;
  margin-top: 8px;
}
.MuiButton-root.google-btn:hover {
  background-color: #e8e8e8;
}

.MuiButton-root.google-btn-engineer {
  background-color: #ffffff;
  width: 100%;
  height: 35px;
  font-size: 14px;
  font-weight: 400px;
  text-transform: none;
  border: 1.5px solid #d3d3d3;
  color: #5e5e5e;
  margin-top: 20px;
  display: flex;
  align-items: center;
  .business-logo-con {
    margin-right: 0%;
  }
}

.MuiButton-root.google-btn-engineer .business-logo-con {
  margin-left: -20px;
  margin-top: 7.5px;
}
.MuiButton-root.google-btn-engineer .signuplogo-bu {
  margin-left: -9px;
  margin-top: 7.5px;
}
.MuiButton-root.google-btn-engineer .business-text-logo-work {
  margin-left: 0px;
  width: 100%;
}

.MuiButton-root.google-btn-engineer:hover {
  background-color: #e8e8e8;
}

.text-or {
  text-align: center;
  font-size: 14px;
  font-weight: 400px;
  margin-bottom: 0px;
  color: #656565;
}

.textfild-width {
  width: 559px;
}

.MuiButton-root.signin-gig {
  background-color: #4285f4;
  width: 558px;
  height: 35px;
  text-transform: none;
  color: #ffffff;
  font-size: 13px;
  font-weight: 500px;
  text-transform: uppercase;
  margin-bottom: 20px;
  margin-top: 15px;
}

.MuiButton-root.signin-gig:hover {
  background-color: #4285f4;
}

.simpl-text {
  text-align: center;
  font-size: 10px;
  font-weight: 400px;
  color: #808080;
}
.text-p {
  display: flex;
  text-align: center;
}

.css-1kymsix-MuiTypography-root-MuiLink-root.terms-text {
  color: #1976d2;
  font-size: 11px;
}

.css-1kymsix-MuiTypography-root-MuiLink-root.privacy-text {
  color: #1976d2;
  font-size: 11px;
}

.copyrigth-text {
  font-size: 12px;
  font-weight: 400px;
  text-align: center;
  color: #808080;
}

.text-1 {
  font-size: 11px;
  margin-top: 8px;
  font-weight: 700px;
  color: #403f3d;
  font-family: Poppins;
  font-style: normal;
  line-height: 12px;
  letter-spacing: 0.0786155px;
}

.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-size: 12px;
}

.signup-dialog-text {
  justify-content: center;
  text-align: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 18px;
  padding-bottom: 10px;
  border-radius: 20px;
  overflow: auto;
}

.MuiButton-root.signup-1 {
  background-color: #1976d2;
  font-size: 13px;
  font-weight: 500px;
  height: 33px;
}

.MuiButton-root.signup-1:hover {
  background-color: #1976d2;
}

.copyrigth-text-1 {
  font-size: 12px;
  font-weight: 400px;
  text-align: center;
  color: #757575;
}

.logo-logo {
  border-radius: 5px;
  background-color: #ffffff;
}

.logo-logo-linkedin {
  border-radius: 5px;
  background-color: #ffffff;
  height: 24px;
  width: 31px;
}

.logo-link {
  border-radius: 5px;
  background-color: #ffffff;
}

.all-button-popup {
  display: flex;
  margin-top: 6px;
  margin-left: 11px;
}

.signup-dialog-text .bnt-signup-engineer-text {
  display: flex;
  margin-top: 6px;
}
.signup-dialog-text .bnt-signup-engineer-text .MuiButton-root.google-btn {
  width: 272px;
}

.dialog-text .forget-pas-text {
  font-size: 11px;
  color: #1976d2;
  font-weight: 700;
  margin-left: 8px;
  cursor: pointer;
  justify-self: flex-start;
}

@media only screen and (min-width: 320px) and (max-width: 850px) {
  .BtnSignup {
    text-align: center;
    margin: 0;
    text-decoration: none;
    color: #7e84a3;
  }
  .BtnSignin {
    text-align: center;
    margin: 0;
    text-decoration: none;
    color: #7e84a3;
  }
  .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
    font-size: 10px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 750px) {
  .forget-btn-signin-btn {
    display: inline-table;
  }
  .dialog-text .text-sign-1 {
    margin-top: 5px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 750px) {
  .dialog-text {
    padding: 10px 10px 10px 10px;
  }
  .BtnSignup {
    text-align: center;
    margin: 0;
    text-decoration: none;
    color: #7e84a3;
  }
  .BtnSignin {
    text-align: center;
    margin: 0;
    text-decoration: none;
    color: #7e84a3;
  }
  .signup-dialog-text {
    padding: 10px 10px 3px 10px;
  }
  .signup-dialog-text .MuiButton-root.google-btn-engineer {
    width: 100%;
  }
  .signup-dialog-text .MuiButton-root.google-btn-engineer .signuplogo-bu {
    margin-left: -9px;
  }
  .signup-dialog-text .MuiButton-root.google-btn-engineer .business-text-logo-work {
    font-size: 10px;
    margin-left: 0px;
    width: 100%;
  }
  .dialog-text .all-button-popup {
    display: block;
  }
  .signup-dialog-text .bnt-signup-engineer-text {
    display: block;
  }
  .signup-dialog-text .bnt-signup-engineer-text .MuiButton-root.google-btn {
    width: 100%;
  }
  .signup-dialog-text .bnt-signup-engineer-text .MuiButton-root.google-btn .logo-google-work {
    margin-left: -10px;
  }
  .signup-dialog-text
    .bnt-signup-engineer-text
    .MuiButton-root.google-btn
    .continu-with-google-text {
    font-size: 11px;
    width: 100%;
  }
  .logo-logo-linkedin {
    border-radius: 5px;
    background-color: #ffffff;
    height: 24.5px;
    width: 31px;
  }
  .signup-dialog-text .bnt-signup-engineer-text .MuiButton-root.google-btn .logo-link-din-conti {
    margin-left: -4px;
  }
  .signup-dialog-text
    .bnt-signup-engineer-text
    .MuiButton-root.google-btn
    .continu-with-google-text-link {
    font-size: 11px;
    width: 100%;
  }
  .signup-dialog-text .bnt-signup-engineer-text .MuiButton-root.google-btn {
    margin-left: 0px;
    width: 100%;
  }
  .dialog-text .all-button-popup .MuiButton-root.google-btn {
    width: 100%;
  }
  .dialog-text .all-button-popup .MuiButton-root.google-btn .contin-with-google {
    margin-left: -10px;
  }
  .dialog-text .all-button-popup .MuiButton-root.google-btn .continu-with-google-text {
    font-size: 11px;
    margin-left: -10px;
    width: 100%;
  }
  .dialog-text .all-button-popup .MuiButton-root.google-btn {
    margin-left: 0px;
  }
  .dialog-text .all-button-popup .MuiButton-root.google-btn .contin-with-google-link {
    margin-left: -4px;
    margin-top: 9px;
  }
  .dialog-text .all-button-popup .MuiButton-root.google-btn .continu-with-google-text-link {
    font-size: 11px;
    width: 100%;
    margin-left: 0px;
  }
  .dialog-text .MuiButton-root.google-btn-engineer {
    width: 100%;
  }
  .dialog-text .MuiButton-root.google-btn-engineer .business-logo-con {
    margin-left: -9px;
  }
  .dialog-text .MuiButton-root.google-btn-engineer .business-text-logo-work {
    font-size: 10px;
    margin-left: 0px;
    width: 100%;
  }
  .dialog-text .textfild-width {
    width: 100%;
  }
  .dialog-text .MuiButton-root.signin-gig {
    width: 100%;
  }
}
@media only screen and (min-width: 375px) and (max-width: 750px) {
  .signup-dialog-text .bnt-signup-engineer-text .MuiButton-root.google-btn {
    width: 100%;
  }
  .signup-dialog-text .bnt-signup-engineer-text .MuiButton-root.google-btn .logo-google-work {
    margin-left: -10px;
  }
  .signup-dialog-text
    .bnt-signup-engineer-text
    .MuiButton-root.google-btn
    .continu-with-google-text {
    font-size: 11px;
    margin-left: -10px;
    width: 100%;
  }
  .signup-dialog-text .bnt-signup-engineer-text .MuiButton-root.google-btn .logo-link-din-conti {
    margin-left: -4px;
  }
  .signup-dialog-text
    .bnt-signup-engineer-text
    .MuiButton-root.google-btn
    .continu-with-google-text-link {
    font-size: 11px;
    width: 100%;
  }
  .signup-dialog-text .bnt-signup-engineer-text .MuiButton-root.google-btn {
    margin-left: 0px;
    width: 100%;
  }
  .signup-dialog-text
    .bnt-signup-engineer-text
    .MuiButton-root.google-btn
    .continu-with-google-text-link {
    font-size: 11px;
    margin-left: 0px;
    width: 100%;
  }
  .signup-dialog-text .MuiButton-root.google-btn-engineer {
    width: 100%;
  }
  .signup-dialog-text .MuiButton-root.google-btn-engineer .signuplogo-bu {
    margin-left: -9px;
  }
  .signup-dialog-text .MuiButton-root.google-btn-engineer .business-text-logo-work {
    font-size: 10px;
    margin-left: 0px;
    width: 100%;
  }
}

.recapcha-engineer-login{
  display: flex;
  justify-content: center;
  align-items: center;
}