.rehire-table {
  margin-top: -30px;
}

.padd-data {
  padding: 40px;
}

.MuiButton-root.btn-renhire-select {
  background-color: #0543ab;
  color: white;
}

.MuiButton-root.btn-renhire-select:hover {
  background-color: #0543ab;
}

.MuiButton-root.btn-renhire-select-muted {
  background-color: #A7A7A7;
  color: white;
}

.MuiButton-root.btn-renhire-select-muted:hover {
  background-color: #A7A7A7;
}
