.bet-ween-line-container {
  margin-left: 40px;
  border-right: 1px solid #dad7d7;
}

@media only screen and (min-width: 350px) and (max-width: 400px) {
  .name-alignment-issue-engineer {
    width: 300px;
    margin-left: -4px;
  }
}

@media only screen and (min-width: 400px) and (max-width: 600px) {
  .name-alignment-issue-engineer {
    width: 300px;
    margin-left: -4px;
  }
}

@media only screen and (min-width: 740px) and (max-width: 800px) {
  .profile-bottom-item-fix {
    margin-left: 23px;
    margin-top: 23px;
  }
}

@media only screen and (min-width: 740px) and (max-width: 800px) {
  .name-alignment-issue-engineer {
    width: 300px;
    margin-left: -4px;
  }
  .bet-ween-line-container {
    margin-left: 40px;
    border-right: 1px solid #ffffff;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1100px) {
  .profile-bottom-item-fix {
    margin-left: 23px;
  }
  .name-alignment-issue-engineer {
    width: 300px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1480px) {
  .skills-availability-res {
    margin-left: -9px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 500px) {
  .skills-availability-res {
    display: flex;
    flex-direction: column-reverse;
    margin-top: -85px;
  }
  .item-signup-cont {
    margin-top: 50px;
  }
}

.profile-bottom-item-fix {
  margin-top: 18px;
  margin-left: 20px;
}

.skills-availability-res .MuiButton-root.google-text-btn .not-user-text {
  width: 100%;
  margin-left: 0px;
  font-family: 'Poppins', 'sansSerif';
  font-weight: 500;
}

.skills-availability-res .MuiButton-root.google-text-btn .not-user-logo {
  margin-left: -5px;
  margin-top: 7px;
}

.profile-display-content-res {
  margin-top: 150px;
}
.profile-display-content-responsive {
  margin-top: 150px;
  margin-left: -50px;
}

@media only screen and (min-width: 750px) and (max-width: 1010px) {
  .skills-availability-res {
    margin-left: -55px;
    width: 48vw;
    padding-left: 33px;
    padding-bottom: 30px;
  }
}

@media only screen and (min-width: 1010px) and (max-width: 1053px) {
  .skills-availability-res {
    width: 30vw;
    padding-bottom: 30px;
  }
}

@media only screen and (min-width: 1300px) and (max-width: 1400px) {
  .name-alignment-issue-engineer {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 1390px) and (max-width: 1500px) {
  .name-alignment-issue-engineer {
    margin-left: 30px;
  }
}

@media only screen and (min-width: 1097px) and (max-width: 1200px) {
  .name-alignment-issue-engineer {
    margin-left: 70px;
  }
}

@media only screen and (min-width: 1197px) and (max-width: 1300px) {
  .name-alignment-issue-engineer {
    margin-left: 50px;
  }
}

@media only screen and (min-width: 1300px) and (max-width: 1400px) {
  .profile-display-content-res {
    .name-alignment-issue-engineer {
      margin-left: 40px;
    }
  }
}

@media only screen and (min-width: 1390px) and (max-width: 1500px) {
  .profile-display-content-res {
    .name-alignment-issue-engineer {
      margin-left: 30px;
    }
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1100px) {
  .profile-display-content-res {
    .name-alignment-issue-engineer {
      margin-left: -5px;
      width: 300px;
    }
  }
  .profile-display-content-res .MuiGrid-item,
  .profile-display-content-responsive .MuiGrid-item {
    padding-bottom: 30px;
    max-width: 350px;
  }
}

@media only screen and (min-width: 1097px) and (max-width: 1200px) {
  .profile-display-content-res {
    .name-alignment-issue-engineer {
      margin-left: 70px;
    }
  }
}

@media only screen and (min-width: 1197px) and (max-width: 1300px) {
  .profile-display-content-res {
    .name-alignment-issue-engineer {
      margin-left: 50px;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 750px) {
  .profile-display-content-responsive {
    margin-top: 150px;
    margin-left: -250px;
    margin-right: 10px;
  }
  .profile-display-content-res {
    margin-top: 150px;
    margin-left: -250px;
    margin-right: 10px;
  }
  .profile-display-content-res .MuiGrid-item,
  .profile-display-content-responsive .MuiGrid-item {
    margin-left: 0px;
    padding-bottom: 30px;
  }
}

@media only screen and (min-width: 750px) and (max-width: 850px) {
  .profile-display-content-responsive {
    margin-top: 150px;
    margin-left: -250px;
  }
  .skills-availability-res {
    margin-right: 10px;
  }
  .profile-display-content-res {
    margin-top: 150px;
    margin-left: -250px;
  }
  .profile-display-content-res .MuiGrid-item,
  .profile-display-content-responsive .MuiGrid-item {
    margin-left: 0px;
    padding-bottom: 30px;
  }
  .bet-ween-line-container {
    margin-left: 40px;
    border-right: 1px solid #ffffff;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1100px) {
  .bet-ween-line-container {
    margin-left: 40px;
    border-right: 1px solid #dad7d7;
  }
}

@media only screen and (min-width: 750px) and (max-width: 800px) {
  .profile-display-content-res {
    margin-top: 150px;
    margin-left: -150px;
    margin-right: 10px;
  }
  .profile-display-content-res .MuiGrid-item,
  .profile-display-content-responsive .MuiGrid-item {
    padding-bottom: 30px;
    max-width: 350px;
  }
}

.forget-btn-signin-btn {
  display: flex;
  font-size: 11px;
  font-weight: 700;
  place-content: space-between;
}

.dialog-text .forget-pas-text {
  font-size: 11px;
  color: #1976d2;
  font-weight: 700;
  margin-left: 8px;
  cursor: pointer;
  justify-self: flex-start;
}

.dialog-text .text-sign-1 {
  display: flex;
  font-weight: 500;
  justify-self: flex-end;
  margin-right: 6px;
}

@media only screen and (min-width: 320px) and (max-width: 750px) {
  .forget-btn-signin-btn {
    display: inline-table;
  }
  .dialog-text .text-sign-1 {
    margin-top: 5px;
  }
}

.MuiButton-root.google-text-btn {
  background-color: #ffffff;
  border: 1.5px solid #d3d3d3;
  color: #5e5e5e;
  font-size: 12.5px;
  text-transform: none;
  margin-top: 30px;
  width: 100%;
  height: 38px;
  border-radius: 5px;
}

.MuiButton-root.google-text-btn:hover {
  background-color: #e8e8e8;
}

.text-city-overflow {
  margin-left: 10px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.MuiButton-root.signup-engineer-text-btn {
  background-color: #f99600;
  color: #ffffff;
  font-size: 12.5px;
  text-transform: inherit;
  margin-top: 20px;
  width: 100%;
  height: 38px;
  border-radius: 10px;
}

.MuiButton-root.signup-engineer-text-btn:hover {
  background-color: #f99600;
}

.MuiButton-root.signup-business-text-btn {
  background-color: #f99600;
  color: #ffffff;
  font-size: 12.5px;
  text-transform: inherit;
  margin-top: 15px;
  width: 100%;
  height: 38px;
  border-radius: 10px;
}

.MuiButton-root.signup-business-text-btn:hover {
  background-color: #f99600;
}

@media only screen and (min-width: 395px) and (max-width: 600px) {
  .profile-bottom-item-fix {
    margin-right: 137px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 394px) {
  .profile-bottom-item-fix {
    margin-right: 85px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 620px) {
  .MuiButton-root.google-btn-engineer {
    background-color: #ffffff;
    width: 100%;
    height: 44px;
    font-size: 14px;
    font-weight: 400px;
    text-transform: capitalize;
    border: 1.5px solid #d3d3d3;
    color: #5e5e5e;
    margin-top: 17px;
  }

  .MuiButton-root.signup-business-text-btn {
    background-color: #f99600;
    color: #ffffff;
    font-size: 12.5px;
    text-transform: inherit;
    margin-top: 15px;
    width: 100%;
    height: 38px;
    border-radius: 10px;
  }

  .MuiButton-root.signup-engineer-text-btn {
    background-color: #f99600;
    color: #ffffff;
    font-size: 12.5px;
    text-transform: inherit;
    margin-top: 20px;
    width: 100%;
    height: 38px;
    border-radius: 10px;
  }

  .skills-availability-res .MuiButton-root.google-text-btn .not-user-text {
    width: 100%;
    margin-left: 0px;
  }

  .skills-availability-res .MuiButton-root.google-text-btn .not-user-logo {
    margin-left: -5px;
    margin-top: 7px;
  }

  .MuiButton-root.google-text-btn {
    background-color: #ffffff;
    border: 1.5px solid #d3d3d3;
    color: #5e5e5e;
    font-size: 12.5px;
    text-transform: none;
    margin-top: 30px;
    width: 100%;
    height: 38px;
    border-radius: 5px;
  }
}

.button-gig {
  display: flex;
  margin-top: 10px;
}

.MuiButton-root.gig-btn {
  background-color: #0160bb;
  height: 34px;
  width: 134px;
  color: #ffffff;
  text-transform: capitalize;
  font-size: 14px;
}

.MuiButton-root.gig-btn:hover {
  background-color: #0160bb;
}

.dialog-text {
  justify-content: center;
  text-align: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 30px;
  padding-bottom: 40px;
  border-radius: 20px;
  background-color: #fafafa;
}

.MuiButton-root.google-btn {
  background-color: #ffffff;
  width: 281px;
  height: 32px;
  text-transform: capitalize;
  border: 1.5px solid #d3d3d3;
  color: #5e5e5e;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  margin-right: 0%;
}
.MuiButton-root.google-btn .contin-with-google {
  margin-top: 8px;
  margin-left: -10px;
}
.MuiButton-root.google-btn .contin-with-google-link {
  margin-top: 7px;
  margin-left: -5px;
}
.MuiButton-root.google-btn .continu-with-google-text {
  margin-left: 0px;
  font-size: 5.13;
  width: 100%;
}
.MuiButton-root.google-btn .continu-with-google-text-link {
  margin-left: 0px;
  font-size: 5.13;
  width: 100%;
}
.MuiButton-root.google-btn .logo-google-work {
  margin-left: -10px;
  margin-top: 8px;
}
.MuiButton-root.google-btn .logo-link-din-conti {
  margin-left: -5px;
  margin-top: 8px;
}
.MuiButton-root.google-btn:hover {
  background-color: #e8e8e8;
}

.MuiButton-root.google-btn-engineer {
  background-color: #ffffff;
  width: 100%;
  height: 35px;
  font-size: 14px;
  font-weight: 400px;
  text-transform: capitalize;
  border: 1.5px solid #d3d3d3;
  color: #5e5e5e;
  margin-top: 20px;
  display: flex;
  align-items: center;
  .business-logo-con {
    margin-right: 0%;
  }
}

.MuiButton-root.google-btn-engineer .business-logo-con {
  margin-left: -10px;
  margin-top: 7.5px;
}
.MuiButton-root.google-btn-engineer .signuplogo-bu {
  margin-left: -9px;
  margin-top: 7.5px;
}
.MuiButton-root.google-btn-engineer .business-text-logo-work {
  margin-left: 0px;
  width: 100%;
}

.MuiButton-root.google-btn-engineer:hover {
  background-color: #e8e8e8;
}

.text-or {
  text-align: center;
  font-size: 14px;
  font-weight: 400px;
  margin-bottom: 0px;
  color: #656565;
}

.textfild-width {
  width: 559px;
}

.MuiButton-root.signin-gig {
  background-color: #4285f4;
  width: 558px;
  height: 35px;
  text-transform: capitalize;
  color: #ffffff;
  font-size: 13px;
  font-weight: 500px;
  text-transform: uppercase;
  margin-bottom: 20px;
  margin-top: 15px;
}

.MuiButton-root.signin-gig:hover {
  background-color: #4285f4;
}

.simpl-text {
  text-align: center;
  font-size: 10px;
  font-weight: 400px;
  color: #808080;
}
.text-p {
  display: flex;
  text-align: center;
}

.css-1kymsix-MuiTypography-root-MuiLink-root.terms-text {
  color: #1976d2;
  font-size: 11px;
}

.css-1kymsix-MuiTypography-root-MuiLink-root.privacy-text {
  color: #1976d2;
  font-size: 11px;
}

.copyrigth-text {
  font-size: 12px;
  font-weight: 400px;
  text-align: center;
  color: #808080;
}

.text-1 {
  font-size: 11px;
  margin-top: 8px;
  font-weight: 700px;
  color: #403f3d;
  font-family: Poppins;
  font-style: normal;
  line-height: 12px;
  letter-spacing: 0.0786155px;
}

.css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
  font-size: 12px;
}

.signup-dialog-text {
  justify-content: center;
  text-align: center;
  align-items: center;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 18px;
  padding-bottom: 10px;
  border-radius: 20px;
}

.MuiButton-root.signup-1 {
  background-color: #1976d2;
  font-size: 13px;
  font-weight: 500px;
  height: 33px;
}

.MuiButton-root.signup-1:hover {
  background-color: #1976d2;
}

.copyrigth-text-1 {
  font-size: 12px;
  font-weight: 400px;
  text-align: center;
  color: #757575;
}

.logo-logo {
  border-radius: 5px;
  background-color: #ffffff;
}

.logo-logo-linkedin {
  border-radius: 5px;
  background-color: #ffffff;
  height: 24px;
  width: 31px;
}

.logo-link {
  border-radius: 5px;
  background-color: #ffffff;
}

.all-button-popup {
  display: flex;
  margin-top: 6px;
}

.signup-dialog-text .bnt-signup-engineer-text {
  display: flex;
  margin-top: 6px;
}
.signup-dialog-text .bnt-signup-engineer-text .MuiButton-root.google-btn {
  width: 272px;
}

@media only screen and (min-width: 320px) and (max-width: 850px) {
  .BtnSignup {
    text-align: center;
    margin: 0;
    text-decoration: none;
    color: #7e84a3;
  }
  .BtnSignin {
    text-align: center;
    margin: 0;
    text-decoration: none;
    color: #7e84a3;
  }
  .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
    font-size: 10px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 750px) {
  .dialog-text {
    padding: 10px 10px 10px 10px;
  }
  .BtnSignup {
    text-align: center;
    margin: 0;
    text-decoration: none;
    color: #7e84a3;
  }
  .BtnSignin {
    text-align: center;
    margin: 0;
    text-decoration: none;
    color: #7e84a3;
  }
  .signup-dialog-text {
    padding: 10px 10px 3px 10px;
  }
  .signup-dialog-text .MuiButton-root.google-btn-engineer {
    width: 100%;
  }
  .signup-dialog-text .MuiButton-root.google-btn-engineer .signuplogo-bu {
    margin-left: -9px;
  }
  .signup-dialog-text .MuiButton-root.google-btn-engineer .business-text-logo-work {
    font-size: 10px;
    margin-left: 0px;
    width: 100%;
  }
  .dialog-text .all-button-popup {
    display: block;
  }
  .signup-dialog-text .bnt-signup-engineer-text {
    display: block;
  }
  .signup-dialog-text .bnt-signup-engineer-text .MuiButton-root.google-btn {
    width: 100%;
  }
  .signup-dialog-text .bnt-signup-engineer-text .MuiButton-root.google-btn .logo-google-work {
    margin-left: -10px;
  }
  .signup-dialog-text
    .bnt-signup-engineer-text
    .MuiButton-root.google-btn
    .continu-with-google-text {
    font-size: 11px;
    width: 100%;
  }
  .logo-logo-linkedin {
    border-radius: 5px;
    background-color: #ffffff;
    height: 24.5px;
    width: 31px;
  }
  .signup-dialog-text .bnt-signup-engineer-text .MuiButton-root.google-btn .logo-link-din-conti {
    margin-left: -4px;
  }
  .signup-dialog-text
    .bnt-signup-engineer-text
    .MuiButton-root.google-btn
    .continu-with-google-text-link {
    font-size: 11px;
    width: 100%;
  }
  .signup-dialog-text .bnt-signup-engineer-text .MuiButton-root.google-btn {
    margin-left: 0px;
    width: 100%;
  }
  .dialog-text .all-button-popup .MuiButton-root.google-btn {
    width: 100%;
  }
  .dialog-text .all-button-popup .MuiButton-root.google-btn .contin-with-google {
    margin-left: -10px;
  }
  .dialog-text .all-button-popup .MuiButton-root.google-btn .continu-with-google-text {
    font-size: 11px;
    margin-left: -10px;
    width: 100%;
  }
  .dialog-text .all-button-popup .MuiButton-root.google-btn {
    margin-left: 0px;
  }
  .dialog-text .all-button-popup .MuiButton-root.google-btn .contin-with-google-link {
    margin-left: -4px;
    margin-top: 9px;
  }
  .dialog-text .all-button-popup .MuiButton-root.google-btn .continu-with-google-text-link {
    font-size: 11px;
    width: 100%;
    margin-left: 0px;
  }
  .dialog-text .MuiButton-root.google-btn-engineer {
    width: 100%;
  }
  .dialog-text .MuiButton-root.google-btn-engineer .business-logo-con {
    margin-left: -9px;
  }
  .dialog-text .MuiButton-root.google-btn-engineer .business-text-logo-work {
    font-size: 10px;
    margin-left: 0px;
    width: 100%;
  }
  .dialog-text .textfild-width {
    width: 100%;
  }
  .dialog-text .MuiButton-root.signin-gig {
    width: 100%;
  }
}

@media only screen and (min-width: 375px) and (max-width: 750px) {
  .signup-dialog-text .bnt-signup-engineer-text .MuiButton-root.google-btn {
    width: 100%;
  }
  .signup-dialog-text .bnt-signup-engineer-text .MuiButton-root.google-btn .logo-google-work {
    margin-left: -10px;
  }
  .signup-dialog-text
    .bnt-signup-engineer-text
    .MuiButton-root.google-btn
    .continu-with-google-text {
    font-size: 11px;
    margin-left: -10px;
    width: 100%;
  }
  .signup-dialog-text .bnt-signup-engineer-text .MuiButton-root.google-btn .logo-link-din-conti {
    margin-left: -4px;
  }
  .signup-dialog-text
    .bnt-signup-engineer-text
    .MuiButton-root.google-btn
    .continu-with-google-text-link {
    font-size: 11px;
    width: 100%;
  }
  .signup-dialog-text .bnt-signup-engineer-text .MuiButton-root.google-btn {
    margin-left: 0px;
    width: 100%;
  }
  .signup-dialog-text
    .bnt-signup-engineer-text
    .MuiButton-root.google-btn
    .continu-with-google-text-link {
    font-size: 11px;
    margin-left: 0px;
    width: 100%;
  }
  .signup-dialog-text .MuiButton-root.google-btn-engineer {
    width: 100%;
  }
  .signup-dialog-text .MuiButton-root.google-btn-engineer .signuplogo-bu {
    margin-left: -9px;
  }
  .signup-dialog-text .MuiButton-root.google-btn-engineer .business-text-logo-work {
    font-size: 10px;
    margin-left: 0px;
    width: 100%;
  }
}

.recaptcha-msg{
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-overview-error-msg{
   cursor: pointer;
   border: white;
   background-color: white;
   font-family: Poppins, sans-serif;
   color: #1976D2;
   font-size: 13.5px;
   font-weight: 500;
   margin-left: 8px;
}
