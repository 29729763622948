.main-heading {
    display: flex;
    justify-content: flex-start;
    font-family: Poppins;
    font-size: 22px;
    align-items: center;
    font-weight: 500;
    margin-left: 5px;
    color: #0140aa;
  }
  
  @media only screen and (min-width: 320px) and (max-width: 700px) {
    .main-heading {
      display: flex;
      justify-content: flex-start;
      font-family: Poppins;
      font-size: 20px;
      align-items: center;
      font-weight: 500;
      margin-left: -36px;
      color: #0140aa;
    }
  }
  
  @media only screen and (min-width: 700px) and (max-width: 1050px) {
    .main-heading {
      display: flex;
      justify-content: flex-start;
      font-family: Poppins;
      font-size: 20px;
      align-items: center;
      font-weight: 500;
      margin-left: -15px;
      color: #0140aa;
    }
  }
  
  .heading-body {
    font-family: Poppins;
    font-size: 22px;
    font-weight: 500;
    margin-left: 15px;
    color: #0140aa;
    margin-right: 20px;
  }
  
  @media only screen and (min-width: 300px) and (max-width: 500px) {
    .heading-body {
      font-family: Poppins;
      font-size: 18px;
      font-weight: 500;
      margin-left: 28px;
      color: #0140aa;
      margin-right: 0px;
      padding: 20px;
    }
  }
  
  .step1-body {
    width: 100%;
    height: auto;
    padding: 20px;
    display: flex;
  }
  
  @media only screen and (min-width: 300px) and (max-width: 1045px) {
    .step1-body {
      width: 100%;
      height: 100%;
      padding: 0px;
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }
  
  .img-container {
    width: 50%;
    height: 600px;
  }
  
  @media only screen and (min-width: 300px) and (max-width: 1045px) {
    .img-container {
      width: 100%;
      height: 100%;
      align-items: center;
      padding: 0px;
    }
  }
  
  .basic-text {
    font-family: Poppins;
    font-size: 25px;
    font-weight: 500;
    color: #0140aa;
  }
  
  @media only screen and (min-width: 300px) and (max-width: 500px) {
    .basic-text {
      font-family: Poppins;
      font-size: 18px;
      font-weight: 500;
      color: #0140aa;
      margin-left: 12px;
    }
  }
  
  .text-contaner {
    font-family: Poppins;
    font-size: 14px;
    font-weight: 500;
    color: #7e84a3;
    text-overflow: warpe;
  }
  
  @media only screen and (min-width: 300px) and (max-width: 500px) {
    .text-contaner {
      font-family: Poppins;
      font-size: 14px;
      font-weight: 500;
      color: #7e84a3;
      text-overflow: warpe;
      margin-left: 11px;
    }
  }
  
  @media only screen and (min-width: 320px) and (max-width: 380px) {
    .vector-img-step1 {
      align-items: center;
      width: 100%;
      height: auto;
      margin-left: -23px;
    }
    .step-1-img {
      width: 396px;
      height: 396px;
    }
  }
  
  @media only screen and (min-width: 380px) and (max-width: 500px) {
    .vector-img-step1 {
      align-items: center;
      width: 100%;
      height: auto;
      margin-left: -10px;
    }
    .step-1-img {
      width: 396px;
      height: 396px;
    }
  }
  
  @media only screen and (min-width: 500px) and (max-width: 1045px) {
    .vector-img-step1 {
      align-items: center;
      width: 100%;
      height: auto;
      margin-left: -30px;
    }
    .step-1-img {
      width: 396px;
      height: 396px;
    }
  }
  
  @media only screen and (min-width: 1045px) and (max-width: 2200px) {
    .vector-img-step1 {
      align-items: center;
      width: 100%;
      height: auto;
      margin-left: -30px;
    }
    .step-1-img {
      width: 396px;
      height: 396px;
    }
  }
  
  .Formrik-Container {
    width: 50%;
    height: auto;
  }
  
  @media only screen and (min-width: 300px) and (max-width: 1045px) {
    .Formrik-Container {
      width: 100%;
      height: 150%;
      padding: 0px;
      align-items: center;
    }
  }
  
  .project-title-data {
    width: 100%;
    padding: 20px;
  }
  
  .field-engineer-data {
    width: 100%;
    padding: 20px;
  }
  
  .skill-step1-data {
    width: 100%;
    padding: 20px;
  }
  
  .tools-technology-data {
    width: 100%;
    padding: 20px;
  }
  
  .step1-btn-container {
    display: flex;
    width: 98.4%;
    justify-content: flex-end;
    gap: 15;
    align-items: center;
  }
  
  @media only screen and (min-width: 300px) and (max-width: 321px) {
    .step1-btn-container {
      display: flex;
      width: 92.5%;
      justify-content: flex-end;
      gap: 15;
      align-items: center;
    }
  }
  
  @media only screen and (min-width: 321px) and (max-width: 377px) {
    .step1-btn-container {
      display: flex;
      width: 94%;
      justify-content: flex-end;
      gap: 15;
      align-items: center;
    }
  }
  
  @media only screen and (min-width: 377px) and (max-width: 426px) {
    .step1-btn-container {
      display: flex;
      width: 97%;
      justify-content: flex-end;
      gap: 15;
      align-items: center;
    }
  }
  
  @media only screen and (min-width: 426px) and (max-width: 1045px) {
    .step1-btn-container {
      width: 98%;
      align-items: center;
    }
  }
  
  .draftgig-list {
    display: flex;
    justify-content: space-between;
  }
  
  .error-msg-step1-texteditor {
    color: #d32f2f;
    font-family: Poppins;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.66px;
    margin-top: 13px;
    margin-left: 15px;
  }
  /* .step1-draft-btn{
  width: 140px;
  border: 0.1px solid #FF8008;
  color: #FF8008;
  align-items: center;
  font-size: 12px;
  height: 45px;
  padding: 12px 20px;
  text-transform: uppercase;
  border-radius: 4px;
  font-weight: 500;
  line-height: 1.75;
  font-family: sans-serif;
  
  } */
  