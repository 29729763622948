.chat {
  display: grid;
  grid-template-columns: 1fr !important;
  gap: 20px !important;
  min-height: 85vh;
  padding: 0 0 !important;
}

.chat__msger {
  border-radius: 18px;
}

.chat__msger {
  display: flex;
  align-items: center;
  justify-content: center;
  background: hsl(0, 0%, 100%);
}

.chat__msger img {
  display: inline-block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

@media (min-width: 550px) {
  .chat__msger img {
    width: 480px;
    height: 480px;
  }
}

@media (min-width: 780px) {
  .chat__msger img {
    width: 500px;
    height: 500px;
  }
}

@media (max-width: 550px) {
  .chat-Box {
    display: block !important;
  }
}

.chat-Selected {
  background-color: #0140aa !important;
  color: white !important;
}

.chat-Box {
  height: 90vh !important;
}

.icon-tick-chat{
  margin-left: 5px;
  margin-top: 5px;
}

.chat-name-left{
  display: flex;
}

.chat-body-right{
  margin-top: 5px;
}

.verified-name-ico{
  width: 18px;
}
