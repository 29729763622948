.tooltip-applicant-text {
  cursor: pointer;
}

.pointer-tooltip-tabledata-business {
  cursor: pointer;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  margin: 0 auto;
  margin-top: 2px;
  align-items: center;
  justify-content: center;
}

.pointer-tooltip-tabledata-business-1 {
  cursor: pointer;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  margin: 0 auto;
  margin-top: 2px;
  align-items: center;
  justify-content: center;
}

.pointer-tooltip-tabledata-admin-side {
  cursor: pointer;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  margin: 0 auto;
  margin-top: 2px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.pointer-tooltip-tabledata-dashboard {
  cursor: pointer;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  margin: 0 auto;
  margin-top: 2px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.not-pointer-location {
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  margin: 0 auto;
  margin-top: 2px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.pointer-tooltip-tabledata {
  cursor: pointer;
}

.pointer-tooltip-tabledata-dashboard {
  cursor: pointer;
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  margin: 0 auto;
  margin-top: 2px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.not-pointer-location {
  width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: block;
  margin: 0 auto;
  margin-top: 2px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

.grid-table-past-current{
  display: flex;
  align-items: center;
  justify-content: center;
}

.verified-icon-applicate{
  width: 18px;
  margin-left: 8px;
}
