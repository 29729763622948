.project-title-activeeng-gig {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.43;
  text-align: center;
  margin-top: 8px;
  font-family: 'Poppins', sansserif;
  margin: 0;
  color: #392722;
  padding-left: 10px;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  }

  .all-contents-text{
    display: flex;
    margin-left: 20px;
    text-align: center;
  }
  
  .longer-text{
    width: 230px;
    margin-top: 2px;
  }
  
  @media only screen and (min-width: 320px) and (max-width: 380px) {
    .all-contents-text{
      display: flex;
      margin-left: 7px;
      text-align: center;
    }
    .longer-text{
      width: 230px;
      margin-top: 4px;
    }
  }
  
  @media only screen and (min-width: 380px) and (max-width: 500px) {
    .all-contents-text{
      display: flex;
      margin-left: 20px;
      text-align: center;
    }
    .longer-text{
      width: 230px;
      margin-top: 3px;
    }
  }
  
  @media only screen and (min-width: 500px) and (max-width: 1050px) {
    .all-contents-text{
      display: flex;
      margin-left: 5px;
      text-align: center;
    }
    .longer-text{
      width: 230px;
      margin-top: 3px;
    }
  }
  
  .longer-text{
    width: 230px;
    margin-top: 2px;
  }
  
  @media only screen and (min-width: 320px) and (max-width: 380px) {
    .all-contents-text{
      display: flex;
      margin-left: 7px;
      text-align: center;
    }
    .longer-text{
      width: 230px;
      margin-top: 4px;
    }
  }
  
  @media only screen and (min-width: 380px) and (max-width: 500px) {
    .all-contents-text{
      display: flex;
      margin-left: 20px;
      text-align: center;
    }
    .longer-text{
      width: 230px;
      margin-top: 3px;
    }
  }
  
  @media only screen and (min-width: 500px) and (max-width: 1050px) {
    .all-contents-text{
      display: flex;
      margin-left: 5px;
      text-align: center;
    }
    .longer-text{
      width: 230px;
      margin-top: 3px;
    }
  }
  
  .all-contents-text{
    display: flex;
    margin-left: 25px;
    text-align: center;
  }
  
  .longer-text{
    width: 230px;
    margin-top: 2px;
  }
  
  @media only screen and (min-width: 320px) and (max-width: 380px) {
    .all-contents-text{
      display: flex;
      margin-left: 7px;
      text-align: center;
    }
    .longer-text{
      width: 230px;
      margin-top: 4px;
    }
  }
  
  @media only screen and (min-width: 380px) and (max-width: 500px) {
    .all-contents-text{
      display: flex;
      margin-left: 20px;
      text-align: center;
    }
    .longer-text{
      width: 230px;
      margin-top: 3px;
    }
  }
  
  @media only screen and (min-width: 500px) and (max-width: 1050px) {
    .all-contents-text{
      display: flex;
      margin-left: 5px;
      text-align: center;
    }
    .longer-text{
      width: 230px;
      margin-top: 3px;
    }
  }

  .active-gig-text{
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .icon-text-appled-gig{
    display: flex;
    justify-content: center;
  }

  .report-icon-or-text{
    font-size: 14px;
    color: red;
    border: 2px solid #E9E4F0;
    border-radius: 10px;
    padding: 10px;
    width: 318px;
    margin-top: 15px;
  }

  .no-record-text-table{
    font-size: 12.5px;
  }