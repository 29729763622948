.MuiButton-root.btn-text-save {
  margin-top: 30px;
  background-image: linear-gradient(#ff8008, #ff8008, #ffc837);
  color: #ffffff;
  border-radius: 5px;
}

@media only screen and (min-width: 320px) and (max-width: 505px) {
  .reset-password-page-responsive {
    width: 270px;
    padding: 20px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 505px) {
  .css-1l6jlkn-MuiPaper-root-MuiCard-root {
    margin-left: 17px;
    width: 270px;
  }
}
