.contact-img-container {
    position: relative;
    text-align: center;
  }
  .cnt-pic {
    width: 100%;
  }
  .cnt-pic img {
    width: 100%;
  }
  .cntct-cont {
    position: absolute;
    top: 0;
    width: 100%;
    text-align: center;
  }
  .cntct-cont h1 {
    color: white;
    font-weight: 600;
    font-size: 38px;
    top: 0;
    margin-top: 100px;
  }
  .contact-img-container hr {
    width: 200px;
    background-color: #ff9400;
    border: 2px solid #ff9400;
    margin-top: -20px;
  }
  .contact-img-container h4 {
    color: white;
    font-size: large;
  }
  @media only screen and (min-width: 320px) and (max-width: 375px) {
    .contact-img-container{
        height: 110vh;
      }
      .cnt-pic img {
        width: 100%;
        height: 110vh;
        object-fit: cover;
      }
  }

  @media only screen and (min-width: 375px) and (max-width: 425px) {
    .contact-img-container{
        height: 110vh;
      }
      .cnt-pic img {
        width: 100%;
        height: 110vh;
        object-fit: cover;
      }
  }

  @media only screen and (min-width: 425px) and (max-width: 768px) {
    .contact-img-container{
        height: 110vh;
      }
      .cnt-pic img {
        width: 100%;
        height: 110vh;
        object-fit: cover;
      }
  }

  @media only screen and (min-width: 768px) and (max-width: 1024px) {
    .contact-img-container{
        height: 110vh;
      }
      .cnt-pic img {
        width: 100%;
        height: 110vh;
        object-fit: cover;
      }
  }
  /* @media only screen and (min-width: 1024px) and (max-width: 1440px) {

  }
  @media only screen and (min-width: 1440px) and (max-width: 2560px) {

  } */
@media only screen and (min-width: 2560px){
        .contactUS-container{
            max-width: 1500px;
            margin: auto;
        }
}

.Contact-email-logo{
  margin-left: 45%;
}