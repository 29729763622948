.textoverflow-small {
  width: 80px;
  height: 20px;
  border: none;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-left: 20px;
}

.drag-drop-icon{
  margin-top: 25px;
  cursor: pointer;
}

.certificate-icon-drag{
  margin-top: 22px;
  margin-left: 39px;
}

@media only screen and (min-width: 320px) and (max-width: 1000px) {
.not-uploaded-cert{
  width: fit-content;
  margin: -40px;
  margin-top: -7px;
}
.textoverflow-small{
  margin-left: 45px;
  margin-bottom: 40px;
  margin-top: 5px;
}
}
