.avtar_profile{
position: relative;
display: flex;
align-items: center;
justify-content: center;
}
.avtar_profile:hover .avtar_group_profile{
  visibility: visible;
  opacity: 1;
}

.avtar_group_profile{
position: absolute;
display: flex;
align-items: center;
justify-content: center;
gap: 10px;
width: 150px;
height: 150px;
background-color: rgba(0, 0, 0, 0.3);
border-radius: 50%;
visibility: hidden;
opacity: 0;
}
.avtar_group_profile button:hover{
background-color: #fff;
border: none;
}