.heading {
    text-align: center;
    margin-top: 10%;
}

.para {
    margin-left: 15%;
    margin-top: 4%;
}

.paras {
    margin-left: 15%;
    /* margin-top: 4%; */
}

.text {
    margin-left: 15%;
    margin-right: 14%;
}

.paras1{
    margin-left: 15%;
    margin-right: 14%;
}

@media only screen and (min-width: 320px) and (max-width: 380px) {
    .heading {
        text-align: center;
        margin-top: 20%;
    }
}

@media only screen and (min-width: 380px) and (max-width: 500px){
    .heading {
        text-align: center;
        margin-top: 20%;
    }
}

@media only screen and (min-width: 500px) and (max-width: 800px){
    .heading {
        text-align: center;
        margin-top: 12%;
    }
}