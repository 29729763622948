@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
.verify-modal-wrapper{
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.23);
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    .modal-card{
        // height: calc(100vh - 60px);
        max-width: 993px;
        width: 30%;
        padding: 10px 0px;
        background: #FFFFFF;
        box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
        border-radius: 4px;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 4px;
            border-radius: 5px; 
        }
        
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 5px; 
        }
        
        &::-webkit-scrollbar-thumb {
            background: #888; 
            border-radius: 5px; 
        }
        
        &::-webkit-scrollbar-thumb:hover {
            background: #555; 
            border-radius: 5px; 
        }

        .modal-top{
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 0 14px;
            h2{
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 24px;
                line-height: 38px;
                margin: 0;
            }
            span{
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 35px;
                color: #000000;
                cursor: pointer;
                height: 38px;
                display: flex;
                align-items: center;
            }
        }

        .modal-text{
            font-family: 'Arial';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
            padding: 20px 14px;
            border-top: 1px solid rgba(0, 0, 0, 0.12);
            border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        }

        .cta-wrapper{
            display: flex;
            justify-content: flex-end;
            padding-top: 10px;
            padding-right: 10px;
            .confirm-cta{
                background: #3F52B6;
                border-radius: 5px;
                color: #FFFFFF;
                padding: 5px 17px;
                width: fit-content;
                cursor: pointer;
                border: none;
                font-family: 'Helvetica';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 18px;
                &.disabled{
                    cursor: not-allowed;
                    background: #7E84A3;
                }

                &.cancel{
                    border: 1px solid #F99600;
                    color: #F99600;
                    background: #FFF;
                    margin-right: 11px;
                    transition: 0.3s all ease-in-out;
                    &:hover{
                        color: #FFF;
                        background: #F99600;
                    }
                }
            }
        }




    }
}

.css-j5h6pi-MuiPopper-root{
    z-index: 20000 !important;
}

.css-3dah0e-MuiModal-root-MuiDialog-root{
    z-index: 20000 !important;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
    .verify-modal-wrapper{
        
        .modal-card{
            min-width: calc(100vw - 40px);

        }
    }
}