$color_1: #FFFFFF;
$color_2: #000000;
$font-family_1: 'Poppins';

.new-invoice-cta {
	font-style: normal;
	font-weight: 400;
	font-size: 16px;
	line-height: 38px;
	display: flex;
	align-items: center;
	color: $color_1;
	cursor: pointer;
	background: #0543AB;
	border-radius: 5px;
	padding: 4px 22px;
	border: none;
	margin-top: 5px;
}
.summary-wrapper {
	margin-top: 38px;
	border: 1px solid #BFBFBF;
	box-shadow: 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
	border-radius: 10px;
	padding: 36px;
	margin-bottom: 28px;
	.summary-box {
		border: 1.37209px solid #BFBFBF;
		border-radius: 6.86047px;
		margin: 30px 0;
		&:nth-child(1) {
			margin-top: 0px;
		}
		&:nth-last-child(1) {
			margin-bottom: 0px;
		}
		p {
			font-style: normal;
			font-weight: 400;
			font-size: 20px;
			line-height: 53px;
			color: $color_2;
			margin: 0;
			margin-top: 17px;
			margin-left: 5%;
		}
		.summary-currency-wrapper {
			display: flex;
			justify-content: space-around;
			align-items: center;
			flex-direction: row;
			margin-bottom: 26px;
            div{
                display: flex;
                flex-direction: column;
                align-items: center;
                p{
                    margin: 0;
                    line-height: 32px;
                }
                span {
                    border: 1.37209px solid #BFBFBF;
                    border-radius: 6.86047px;
                    font-family: $font-family_1;
                    font-style: normal;
                    font-weight: 700;
                    font-size: 18px;
                    color: $color_2;
                    padding: 2px 14px;
                }
            }
			span {
				border: 1.37209px solid #BFBFBF;
				border-radius: 6.86047px;
				font-family: $font-family_1;
				font-style: normal;
				font-weight: 700;
				font-size: 18px;
				color: $color_2;
				padding: 2px 14px;
			}
		}
	}
}

@media only screen and (min-width: 310px) and (max-width: 480px) {
	.summary-wrapper .summary-box  .summary-currency-wrapper{
		display: block;
		justify-content: space-around;
		align-items: center;
		flex-direction: row;
		margin-bottom: 26px;
	}
}

@media only screen and (min-width: 310px) and (max-width: 480px) {
	.summary-wrapper .summary-box  .summary-currency-wrapper .us-doller-gap{
	margin-top: 20px;
	}
}