.export-cta{
    background: #0543AB;
    border-radius: 5px;
    padding: 6px 18px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    border: none;
    cursor: pointer;
    color: #FFFFFF;
}

.format-info{
    /* cursor: help; */
    transition: all 0.2s linear;
}

.format-info:hover{
    /* animation: bounce 0.4s linear 0s infinite alternate; */
}

@keyframes bounce {
    from{
        transform: translateY(0px);
    }
    to{
        transform: translateY(-5px);
    }
}