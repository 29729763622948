.text-step3 {
  margin-top: 50%;
  display: block;
  margin-left: 10px;
}

.attachment-issue {
  margin-top: 20px;
  margin-left: 20px;
}

.main-container-step3 {
  width: 100%;
  height: 1100px;
}

@media (max-width: 1200px) {
  .main-container-step3 {
    height: fit-content;
  }
}

.all-item-step2 {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
}

@media (max-width: 700px) {
  .all-item-step2 {
    margin-top: -20px;
  }
}

@media only screen and (min-width: 700px) and (max-width: 1200px) {
  .all-item-step2 {
    margin-top: -35px;
  }
}

.text-feild-step-1 {
  width: 100%;
  padding: 20px;
}

.MuiButton-root.go-to-profile-btn {
  background-color: #0140aa;
  font-size: 16px;
  text-transform: inherit;
  width: 130px;
  border-radius: 5px;
  height: 35px;
  margin-top: 10px;
  margin-left: 74%;
  color: white;
}

.MuiButton-root.go-to-profile-btn:hover {
  background-color: #0140aa;
}

@media (max-width: 550px) {
  .fRjEnz.fRjEnz {
    padding: 0px;
    min-height: 80vh;
  }

  .css-iimk3f {
    -webkit-box-flex: 1;
    -webkit-flex-grow: 1;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding: 0px;
    -webkit-transition: margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    transition: margin 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms;
    margin-left: -240px;
    height: 100vh;
  }

  .css-46bh2p-MuiCardContent-root:last-child {
    padding-bottom: 0px;
  }

  .css-46bh2p-MuiCardContent-root {
    padding: 0px;
  }
}

.textoverflow-step-3{
  width: 130px;
  height: 22px;
  border: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: default;
}

.new-post-editor-step-1 {
  min-height: 150px;
}

.deliverables-step3{
  width: 100%;
  padding: 0px;
}

.deliverableTitle-step3{
  width: 100%;
}

.deliverable-description-step3{
width: 100%;
padding: 20px;
display: flex;
justify-content: center;
align-items: center;
}

.specialrequirements-step3{
  width: 99%;
}

.data-upload-step3{
  width: 100%;
  margin-left: 13px;
}

.Deliverable-Container{
width: 100%;
display: flex;
align-items: center;
}

@media only screen and (min-width: 300px) and (max-width: 800px){
  .Deliverable-Container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    }
}

/* .Button-Container-step3{
 display: flex;
 width: 99%;
 justify-content: flex-end;
 gap: 15;
 align-items: center;
 margin-top: 20px;
}

@media only screen and (min-width: 400px) and (max-width: 1050px){
  .Button-Container-step3{
    width: 99%;
    height: 100%;
    }
} */

.drag-drop-upload-data{
  margin-top: 4px;
  margin-left: 11px;
}

.remote-work-step3{
  display: flex;
  align-items: center;
}
