.or-seperator-wrapper{
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.60);
    font-family: Poppins;
    font-size: 11.175px;
    font-style: normal;
    font-weight: 400;
    line-height: 16.064px; /* 143.75% */
    letter-spacing: 0.105px;
    margin-top: 11px;
    hr{
        width: 100%;
        height: 1px;
        margin: 0 10px;
        border: none;
        border-bottom: 1px solid #B1ACAC;
        // border-color: #B1ACAC;
    }
}