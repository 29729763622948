.footer {
  background-color: #FAFCFE;
  color: aliceblue;
  border: 1px solid #FAFCFE;
}

.f-logo {
  height: 28%;
  margin-left: 8px;
}

.f-logo img {
  width: 20%;
  height: 20%;
  margin-left: 7%;
  margin-top: 20px;
}

.desc {
  display: flex;
  margin-bottom: 2%;
}

.desc-div1 {
  width: 35%;
  margin-left: 8%;
}

.desc-div1 p {
  font-size: 10px;
  line-height: 18px;
  letter-spacing: 0px;
  color: #0B213E;
  font-family: inter;
}

.desc-div2 {
  margin-left: 7%;
  width: 12%;
  margin-top: -55px;
}

.desc-div2 h2 {
  font-size: 15px;
  line-height: 36px;
  font-family: inter;
  font-weight: 700;
  cursor: pointer;
}

.desc-div2 p {
  font-size: 11px;
  line-height: 19px;
  font-family: inter;
  font-weight: 400;
  color: #0B213E;
  cursor: pointer;
}

.desc-div2 div {
  padding: 1%;
  letter-spacing: 0px;
  font-size: 11px;
}

.desc-div2 div:hover {
  cursor: pointer;
}

.logo-facebook-link {
  width: 20%;
  margin-top: -70px;
}

.logo-facebook-link h2 {
  font-size: 22px;
  line-height: 36px;
  font-family: inter;
  font-weight: 700;
}

.desc-div3 {
  margin-left: 0%;
  width: 12%;
  margin-top: -55px;
}

.desc-div3 h2 {
  font-size: 15px;
  line-height: 36px;
  font-family: inter;
  font-weight: 700;
  cursor: pointer;
}

.desc-div3 p {
  font-size: 11px;
  line-height: 19px;
  font-family: inter;
  font-weight: 400;
  color: #0B213E;
  cursor: pointer;
}

.desc-div3 div:hover {
  cursor: pointer;
}

.desc-div4 {
  margin-left: 0%;
  width: 12%;
  margin-top: -55px;
}

.desc-div4 h2 {
  font-size: 15px;
  line-height: 36px;
  font-family: inter;
  font-weight: 700;
  cursor: pointer;
}

.desc-div4 p {
  font-size: 11px;
  line-height: 19px;
  font-family: inter;
  font-weight: 400;
  color: #0B213E;
  cursor: pointer;
}

.desc-div4 div:hover {
  cursor: pointer;
}

.contact-and-faqs {
  text-decoration: none;
}

.desc-div5 {
  margin-left: 0%;
  width: 12%;
  margin-top: -55px;
}

.desc-div5 h2 {
  font-size: 15px;
  line-height: 36px;
  font-family: inter;
  font-weight: 700;
  cursor: pointer;
}

.desc-div5 div:hover {
  cursor: pointer;
}

.insta-twiter-container {
  margin-left: 2px;
}

.linkedin-facebook-container {
  margin-left: 2px;
  margin-top: 6px;
}

.break-line-footer {
  border-bottom: 1px solid #D0D0D2;
  width: 81%;
  margin-left: 8%;
  margin-top: -25px;
}

.copy-right-text-footer {
  color: #838383;
  font-size: 10px;
  display: flex;
  justify-content: center;
  line-height: 16px;
  font-weight: 400;
  font-style: inter;
  margin-top: 18px;
  margin-bottom: 20px;
}

.copy {
  display: flex;
  /* margin-top: -30px; */
  margin-bottom: 1%;
  margin-left: 45%;
}

.copy div {
  font-size: 11px;
}

.copy> :first-child {
  width: 70%;
}

.copy> :last-child {
  margin-left: 3%;
  cursor: pointer;
}

.copy> :nth-child(2) {
  cursor: pointer;
}

@media screen and (min-width: 300px) and (max-width: 330px) {
  .footer {
    background-color: #FAFCFE;
    color: aliceblue;
    border: 1px solid #FAFCFE;
    margin-right: -53px;
    margin-top: 65px;
    margin-left: -10px;
  }
  
    .f-logo img {
      width: 156px;
      margin-left: 0%;
      margin-top: 5%;
      height: 30px;
    }
  
    .social-media-btn-footer {
      display: flex;
    }
  
    .insta-twiter-container {
      margin-left: 2px;
      width: 158px;
    }
  
    .linkedin-facebook-container {
      margin-left: -88px;
      margin-top: 0px;
      width: 158px;
    }
  
    .break-line-footer {
      border-bottom: 1px solid #D0D0D2;
      width: 95%;
      margin-left: 3%;
      margin-top: 25px;
    }
  
    .copy-right-text-footer {
      color: #838383;
      font-size: 16px;
      display: flex;
      justify-content: center;
      line-height: 20px;
      font-weight: 400;
      font-style: inter;
      margin-top: 18px;
      margin-bottom: 20px;
      margin-left: 10px;
      text-align: center;
      padding-right: 20px;
    }
  
    .desc {
      display: flex;
      flex-direction: column;
      text-align: justify;
    }
  
    .desc-div1 {
      width: 95%;
      margin-left: 2.5%;
      font-size: small;
    }
  
    .desc-div1 p {
      font-size: 13px;
      letter-spacing: 0px;
      line-height: 16px;
      font-family: inter;
      font-weight: 500;
      color: #0B213E;
    }
  
    .desc-div2 p {
      font-size: 16px;
      letter-spacing: 0px;
      line-height: 19px;
      font-family: inter;
      font-weight: 400;
      color: #0B213E;
      cursor: pointer;
    }
  
    .desc-div3 p {
      font-size: 16px;
      letter-spacing: 0px;
      line-height: 19px;
      font-family: inter;
      font-weight: 400;
      color: #0B213E;
      cursor: pointer;
    }
  
    .desc-div4 p {
      font-size: 16px;
      letter-spacing: 0px;
      line-height: 19px;
      font-family: inter;
      font-weight: 400;
      color: #0B213E;
      cursor: pointer;
    }
  
    .desc-div2 {
      width: 95%;
      margin-left: 2.5%;
      font-size: 16px;
      margin-top: -15px;
    }
  
    .desc-div3 {
      width: 95%;
      margin-left: 2.5%;
      font-size: 16px;
      margin-top: -15px;
    }
  
    .desc-div4 {
      width: 95%;
      margin-left: 2.5%;
      font-size: 16px;
      margin-top: -15px;
    }
  
    .desc-div5 {
      width: 95%;
      margin-left: 2.5%;
      font-size: 16px;
      margin-top: -15px;
    }
  
    .desc-div2 h2 {
      font-size: 24px;
      line-height: 29px;
      font-weight: 600;
      cursor: pointer;
      font-family: inter;
    }
  
    .desc-div3 h2 {
      font-size: 24px;
      line-height: 29px;
      font-weight: 600;
      cursor: pointer;
      font-family: inter;
    }
  
    .desc-div4 h2 {
      font-size: 24px;
      line-height: 29px;
      font-weight: 600;
      cursor: pointer;
      font-family: inter;
    }
  
    .desc-div5 h2 {
      font-size: 24px;
      line-height: 29px;
      font-weight: 600;
      cursor: pointer;
      font-family: inter;
    }
  
    .desc-div3 {
      width: 95%;
      margin-left: 2.5%;
      margin-top: -15px;
    }
  
    .logo-facebook-link {
      width: 95%;
      margin-left: 2.5%;
      margin-top: 20px;
    }
  
    .logo-facebook-link div img {
      width: 15px;
      height: 15px;
    }
  
    .d3-imgd>img {
      display: none;
    }
  
    .icons img {
      margin-left: 20%;
      width: 40%;
      height: 75%;
    }
  
    .copy {
      text-align: center;
      margin-left: 60px;
      margin-top: 10px;
    }
  
    .copy div {
      font-size: 11px;
    }
}

@media screen and (min-width: 330px) and (max-width: 480px) {
  .footer {
    background-color: #FAFCFE;
    color: aliceblue;
    border: 1px solid #FAFCFE;
    margin-right: -15px;
    margin-top: 65px;
    margin-left: -10px;
  }

  .f-logo img {
    width: 156px;
    margin-left: 0%;
    margin-top: 5%;
    height: 30px;
  }

  .social-media-btn-footer {
    display: flex;
  }

  .insta-twiter-container {
    margin-left: 2px;
    width: 158px;
  }

  .linkedin-facebook-container {
    margin-left: -88px;
    margin-top: 0px;
    width: 158px;
  }

  .break-line-footer {
    border-bottom: 1px solid #D0D0D2;
    width: 95%;
    margin-left: 3%;
    margin-top: 25px;
  }

  .copy-right-text-footer {
    color: #838383;
    font-size: 16px;
    display: flex;
    justify-content: center;
    line-height: 20px;
    font-weight: 400;
    font-style: inter;
    margin-top: 18px;
    margin-bottom: 20px;
    margin-left: 10px;
    text-align: center;
    padding-right: 20px;
  }

  .desc {
    display: flex;
    flex-direction: column;
    text-align: justify;
  }

  .desc-div1 {
    width: 95%;
    margin-left: 2.5%;
    font-size: small;
  }

  .desc-div1 p {
    font-size: 13px;
    letter-spacing: 0px;
    line-height: 16px;
    font-family: inter;
    font-weight: 500;
    color: #0B213E;
  }

  .desc-div2 p {
    font-size: 16px;
    letter-spacing: 0px;
    line-height: 19px;
    font-family: inter;
    font-weight: 400;
    color: #0B213E;
    cursor: pointer;
  }

  .desc-div3 p {
    font-size: 16px;
    letter-spacing: 0px;
    line-height: 19px;
    font-family: inter;
    font-weight: 400;
    color: #0B213E;
    cursor: pointer;
  }

  .desc-div4 p {
    font-size: 16px;
    letter-spacing: 0px;
    line-height: 19px;
    font-family: inter;
    font-weight: 400;
    color: #0B213E;
    cursor: pointer;
  }

  .desc-div2 {
    width: 95%;
    margin-left: 2.5%;
    font-size: 16px;
    margin-top: -15px;
  }

  .desc-div3 {
    width: 95%;
    margin-left: 2.5%;
    font-size: 16px;
    margin-top: -15px;
  }

  .desc-div4 {
    width: 95%;
    margin-left: 2.5%;
    font-size: 16px;
    margin-top: -15px;
  }

  .desc-div5 {
    width: 95%;
    margin-left: 2.5%;
    font-size: 16px;
    margin-top: -15px;
  }

  .desc-div2 h2 {
    font-size: 24px;
    line-height: 29px;
    font-weight: 600;
    cursor: pointer;
    font-family: inter;
  }

  .desc-div3 h2 {
    font-size: 24px;
    line-height: 29px;
    font-weight: 600;
    cursor: pointer;
    font-family: inter;
  }

  .desc-div4 h2 {
    font-size: 24px;
    line-height: 29px;
    font-weight: 600;
    cursor: pointer;
    font-family: inter;
  }

  .desc-div5 h2 {
    font-size: 24px;
    line-height: 29px;
    font-weight: 600;
    cursor: pointer;
    font-family: inter;
  }

  .desc-div3 {
    width: 95%;
    margin-left: 2.5%;
    margin-top: -15px;
  }

  .logo-facebook-link {
    width: 95%;
    margin-left: 2.5%;
    margin-top: 20px;
  }

  .logo-facebook-link div img {
    width: 15px;
    height: 15px;
  }

  .d3-imgd>img {
    display: none;
  }

  .icons img {
    margin-left: 20%;
    width: 40%;
    height: 75%;
  }

  .copy {
    text-align: center;
    margin-left: 60px;
    margin-top: 10px;
  }

  .copy div {
    font-size: 11px;
  }
}

@media only screen and (min-width: 2560px) {
  .footer-large {
    max-width: 1440px;
    margin: auto;
  }
}

.instagram-logo-footer {
  width: 16px;
  color: white;
}

.facebook-logo-footer {
  width: 18px;
  color: white;
}

@media only screen and (min-width: 700px) and (max-width: 900px) {
.footer {
  background-color: #FAFCFE;
  color: aliceblue;
  border: 1px solid #FAFCFE;
  margin-right: -59px;
}
}

@media only screen and (min-width: 900px) and (max-width: 1030px) {
  .footer {
    background-color: #FAFCFE;
    color: aliceblue;
    border: 1px solid #FAFCFE;
    margin-right: -32px;
  }
  }