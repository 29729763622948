.MuiButton-root.btn-bank-save {
    margin-top: 30px;
    background-image: linear-gradient(#ff8008, #ff8008, #ffc837);
    color: #ffffff;
    border-radius: 5px;
  }

  .MuiButton-root.edit-btn-bank{
    margin-top: 30px;
    background-image: linear-gradient(#0140AA, #0140AA, #0140AA);
    color: #ffffff;
    border-radius: 5px;
  }

  .MuiButton-root.edit-btn-bank:hover {
    background-color: #0140AA;
  }

  .MuiButton-root.all-record-delete-btn{
   margin-top: 19px;
  }

  @media only screen and (min-width: 320px) and (max-width: 505px) {
    .MuiButton-root.all-record-delete-btn{
      margin-top: 25px;
     }
  }

  @media only screen and (min-width: 320px) and (max-width: 505px) {
    .MuiButton-root.edit-btn-bank {
      margin-top: 30px;
      background-image: linear-gradient(#0140AA, #0140AA, #0140AA);
      color: #ffffff;
      border-radius: 5px;
      width: 85%;
      margin-left: 24px;
    }
  }

  @media only screen and (min-width: 505px) and (max-width: 800px) {
    .MuiButton-root.edit-btn-bank {
      margin-top: 30px;
      background-image: linear-gradient(#0140AA, #0140AA, #0140AA);
      color: #ffffff;
      border-radius: 5px;
      width: 85%;
      margin-left: 24px;
    }
  }

  @media only screen and (min-width: 320px) and (max-width: 505px) {
    .MuiButton-root.btn-bank-save {
      margin-top: 30px;
      background-image: linear-gradient(#ff8008, #ff8008, #ffc837);
      color: #ffffff;
      border-radius: 5px;
      width: 85%;
      margin-left: 24px;
    }
  }

  @media only screen and (min-width: 505px) and (max-width: 800px) {
    .MuiButton-root.btn-bank-save {
      margin-top: 30px;
      background-image: linear-gradient(#ff8008, #ff8008, #ffc837);
      color: #ffffff;
      border-radius: 5px;
      width: 85%;
      margin-left: 24px;
    }
  }
  
  @media only screen and (min-width: 320px) and (max-width: 505px) {
    .bank-page-responsive {
      width: 270px;
      padding: 20px;
      display: contents;
    }
  }
  
  @media only screen and (min-width: 320px) and (max-width: 505px) {
    .css-1l6jlkn-MuiPaper-root-MuiCard-root {
      margin-left: 17px;
      width: 270px;
    }
  }
  
  .bank-cross-icon{
    cursor: pointer;
  }

  .MuiButton-root.cancel-btn-bank {
    border-radius: 5px;
    background-color: #ffffff;
    color: #F99600;
    margin-left: 30px;
  }

  .MuiButton-root.cancel-btn-bank:hover {
    background-color: #ffffff;
  }

  .img-or-text-bank{
    text-align: center;
    margin-top: 60px;
  }

  .no-record-bank{
    font-family: Poppins;
    line-height: 30px;
    font-size: 16px;
    font-weight: 400;
    margin-top: 30px;
  }

  .card-container{
    height: 300px;
  }

  @media only screen and (min-width: 320px) and (max-width: 505px) {
    .card-container{
      height: 530px;
      width: 85%;
      margin-left: 24px;
    }
  }

  @media only screen and (min-width: 505px) and (max-width: 805px) {
    .card-container{
      height: 510px;
      width: 85%;
      margin-left: 24px;
    }
  }

  .btn-container-delete{
    display: flex;
    margin-left: 20px;
    margin-top: 20px;
    padding-bottom: 20px;
    margin-left: 220px;
  }

  @media only screen and (min-width: 320px) and (max-width: 505px) {
    .btn-container-delete{
      display: flex;
      margin-left: 20px;
      margin-top: 20px;
      padding-bottom: 20px;
      margin-left: 35px;
    }
  }

  .delete-text-wrapper{
   border-top: 1px solid #E1E1E1;
   border-bottom: 1px solid #E1E1E1;
   width: 500px;
   font-size: 14px;
   line-height: 55px;
   font-weight: 400;
   padding-left: 10px;
  }

  @media only screen and (min-width: 320px) and (max-width: 505px) {
    .delete-text-wrapper{
      border-top: 1px solid #E1E1E1;
      border-bottom: 1px solid #E1E1E1;
      width: 310px;
      font-size: 12px;
      line-height: 55px;
      font-weight: 400;
      padding-left: 10px;
     }
  }

  .wrapper-main-text{
   font-size: 18px;
   line-height: 55px;
   font-weight: 600;
   display: flex;
   padding-left: 10px;
   padding-right: 15px;
   justify-content: space-between;
  }

  @media only screen and (min-width: 320px) and (max-width: 505px) {
    .wrapper-main-text{
      font-size: 15px;
      line-height: 55px;
      font-weight: 600;
      display: flex;
      padding-left: 10px;
      padding-right: 15px;
      justify-content: space-between;
     }
  }

  .wrapper-text-no-data{
    height: 250px;
    margin-top: 25px;
  }

  
  @media only screen and (min-width: 300px) and (max-width: 800px) {
    .wrapper-text-no-data{
     height: 450px;
     }
  }

  .error-msg-bank-account-type{
    color: #d32f2f;
    font-weight: 400;
    font-family: 'Poppins', sansserif;
    line-height: 1.66;
    text-align: left;
    margin-top: 3px;
    margin-left: 14px;
    font-size: 12px;
  }


          