.textoverflow-request-3 {
  width: 160px;
  height: 22px;
  border: none;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: default;
}

.raise-query-upload-icon{
  margin-top: 6px;
  padding-bottom: 2px;
}

.attachment-text-query{
  color: #0140AA;
  margin-top: 0px;
}
