.main-container-notusername {
  margin-top: 90px;
  margin-left: 170px;
  border: 1px solid #f2f2f2;
  width: 1202px;
  height: 550px;
  align-items: center;
  justify-content: center;
  position: absolute;
  background-color: #f2f2f2;
  .all-items-user {
    padding-left: 465px;
    padding-top: 100px;
    .user-1-text {
      font-family: Poppins;
      font-style: normal;
      font-weight: 700;
      font-size: 15px;
      line-height: 30px;
    }
    .user-2-text {
      font-family: Poppins;
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 27px;
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 750px) {
  .main-container-notusername {
    margin-left: 0px;
  }
}
