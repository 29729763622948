@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
.reject-modal-wrapper{
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.23);
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    .modal-card{
        height: fit-content;

        max-width: 385px;
        width: fit-content;
        padding: 20px 25px;
        background: #FFFFFF;
        border: 1px solid #000000;
        border-radius: 10px;
        overflow-y: auto;
        width: calc(100vw - 40px);
        &::-webkit-scrollbar {
            width: 4px;
            border-radius: 5px; 
        }
        
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 5px; 
        }
        
        &::-webkit-scrollbar-thumb {
            background: #888; 
            border-radius: 5px; 
        }
        
        &::-webkit-scrollbar-thumb:hover {
            background: #555; 
            border-radius: 5px; 
        }

        .modal-top{
            display: flex;
            justify-content: flex-end;
            span{
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 35px;
                color: #000000;
                cursor: pointer;
            }
        }

        textarea{
            width: 100%;
            height: 130px;
            border: 1px solid #BFBFBF;
            border-radius: 5px;
            padding: 16.5px 14px;
            resize: none;
            outline: none;
            margin-top: 10px;
        }

        .modal-header{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 25px;
            line-height: 42px;
            color: #000000;
            display: flex;
            justify-content: space-between;
            align-items: center;
            border-bottom: 1px solid #E1E1E1;
            img{
                height: 100%;
                width: 40%;
            }
            .cross{
                font-size: 28px;
                cursor: pointer;
            }
        }


        .confirm-cta{
            background: #3F52B6;
            border-radius: 5px;
            font-style: normal;
            color: #FFFFFF;
            padding: 5px 17px;
            width: fit-content;
            margin: 0 auto;
            margin-top: 30px;
            cursor: pointer;
            border: none;
            display: block;
            font-family: 'Poppins',sansSerif;
            font-weight: 500;
            font-size: 0.875rem;
            line-height: 1.75;
            text-transform: uppercase;
        }

        .modal-infotext{
            font-family: 'Helvetica';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 21px;
            color: #000000;
            margin: 24px 0;
        }

        .reject-dd-list{
            border: 1px solid #BFBFBF;
            border-radius: 5px;
            padding: 19px 25px;
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 21px;
            outline: none;
            width: 100%;
        }

    }
}

.css-j5h6pi-MuiPopper-root{
    z-index: 20000 !important;
}

.css-3dah0e-MuiModal-root-MuiDialog-root{
    z-index: 20000 !important;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
    .modal-wrapper{
        
        .modal-card{
            min-width: calc(100vw - 40px);

        }
    }
}