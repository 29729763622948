.MuiButton-root.admin-all-btn {
  background-color: #0543ab;
}

.MuiButton-root.admin-all-btn:hover {
  background-color: #0543ab;
}

.des-text-detail-overflow {
  overflow: auto;
  width: 100%;
  height: 55px;
  font-size: 12px;
  font-family: Poppins;
  font-weight: 400;
  line-height: 1.43;
  color: #7e84a3;
}

.css-185gdzj-MuiCardHeader-root.logo-detail-red {
  width: 50%;
  margin-top: -44px;
}

.status-gig-admin-details {
  display: flex;
  border: 1.5px solid #63a46c;
  border-radius: 5px;
  color: #63a46c;
  margin-left: auto;
  margin-top: 10px;
  font-size: 10px;
  font-family: Arial;
  font-weight: 700;
  font-style: normal;
  line-height: 11px;
  text-transform: capitalize;
  text-align: center;
  justify-content: center;
  margin-right: 4px;
  align-items: center;
}

.status-gig-admin {
  display: flex;
  border-radius: 5px;
  font-size: 10px;
  font-family: Arial;
  font-weight: 700;
  font-style: normal;
  line-height: 11px;
  text-transform: capitalize;
  text-align: center;
  justify-content: center;
  align-items: center;
}

.sta-green {
  /* padding: 7.5px 1.5px 7.5px 2.5px; */
  margin-left: 1.5px;
  display: flex;
  align-items: center;
  margin-top: 1.5px;
  font-size: 13px;
}

.status-active {
  border: 1.5px solid #63a46c;
  color: #63a46c;
  width: 71px;
  height: 25px;
}

.status-inactive {
  border: 1.5px solid #ff4140;
  color: #ff4140;
  width: 80px;
  height: 25px;
}

.status-draft {
  border: 1.5px solid #f9d100;
  color: #f9d100;
  width: 71px;
  height: 25px;
}

.status-closed {
  border: 1.5px solid #595757;
  color: #595757;
  width: 74px;
  height: 25px;
}

@media only screen and (min-width: 599px) and (max-width: 1042px) {
  .status-show-admin-side {
    margin-top: 30px;
  }
  .status-gig-admin {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 1042px) and (max-width: 2560px) {
  .status-show-admin-side {
    margin-left: auto;
  }
  .status-gig-admin {
    margin-left: auto;
  }
}

@media only screen and (min-width: 320px) and (max-width: 598px) {
  .status-show-admin-side {
    margin-left: auto;
  }
  .status-gig-admin {
    margin-left: auto;
  }
}

.text-description-and-title{
  margin-top: 10px;
}