p {
  text-align: left;
}
* {
  letter-spacing: 0;
  font-family: sans-serif;
}

.div1 {
  width: 80%;
  display: flex;
  justify-content: space-between;
  margin-top: 10vh;
  margin: 8%;
}
.div1 h2 {
  color: black;
}
.d1-comps {
  width: 32%;
}
.d1-comps h1 {
  line-height: 120%;
  font-size: 42px;
  color: black;
}
.d1-comps p {
  color: black;
}
.d1-comps h1 .line {
  width: 35%;
  border: 2px solid #ff9400;
  background-color: #ff9400;
}
.d1-comps hr {
  width: 35%;
  border: 2px solid #ff9400;
  background-color: #ff9400;
  margin-left: 0;
  margin-top: -5%;
}
.d-link {
  color: #1396e3;
  font-size: 20px;
  display: flex;
}
.arrow-div{
  width: 50px;
  height: auto;
  margin-left: 10%;
}
.right-arrow{
  width: 38px;
}
.d-link h5 {
  margin-top: -2%;
}
.d-link:hover {
  cursor: pointer;
}
#d1-comp1 {
  display: flex;
  flex-direction: column;
  margin-left: 10%;
  width: 20%;
}
#d1-comp1 > :last-child {
  margin-top: 18%;
}
#d1-comp2 {
  display: flex;
  flex-direction: column;
  margin-left: 10%;
  width: 20%;
}
#d1-comp2 > :last-child {
  margin-top: 24%;
}

.div2 {
  background-color: #b2a499;
  display: flex;
  margin-top: 7%;
}
.d2-comps h1 {
  font-size: 42px;
  margin-top: 8%;
}
.d2-comps .line {
  width: 23%;
  border: 2px solid #ff9400;
  background-color: #ff9400;
  margin-left: 0%;
  margin-top: 0.1%;
}
.div2 img {
  height: 100%;
}
.d2-comps {
  color: white;
  text-align: justify;
  font-size: larger;
}
.d2-comps p {
  margin-top: -2%;
  margin-right: 38%;
  font-size: 23px;
  width: calc(100% - 100px);
}
#d2-comp2 {
  margin-left: 5%;
}
.d2-btns {
  display: flex;
  gap: 2%;
}
.d2-btns Button:hover {
  background-color: whitesmoke;
}

.div3 {
  margin-top: 50px;
}
.hdiv {
  margin-top: 2%;
  font-size: xx-large;
  text-align: center;
}
.div3 h2 {
  color: black;
}
.d3-hr {
  margin-left: 44%;
  width: 13%;
  margin-top: -40px;
  background-color: #ff9400;
  border: 2px solid #ff9400;
}
.d-comps {
  display: flex;
  margin-left: 8%;
  margin-right: 8%;
}
.num-head{
  width: 100%;
  display: flex;
}
.circle-img{
  width: 15%;
}
.circle-img img {
  width: 100%;
}
.div3 h3 {
  color: #015bb9;
  font-size: 200%;
  font-weight: 700;
  margin: 0%;
  margin-left: 15px;
}
.div3 p {
  color: #726b67;
  margin-top: 0%;
  margin-left: 5%;
}
.grey-hr {
  border: 0.25px solid #b9b9b9;
  background-color: #b9b9b9;
  width: 84%;
  margin-left: 8%;
  margin-right: 8%;
  margin-top: 0.1%;
}


.dark-hr {
  border: 1.25px solid #7d7a7a;
  background-color: #7d7a7a;
  width: 95%;
  margin-top: 50px;
}

.div4 {
  margin-top: 50px;
  margin-bottom: 50px;
}
.div4 h2 {
  color: black;
}
.div4 h3 {
  color: #015bb9;
  font-size: 200%;
  font-weight: 700;
  margin: 0%;
  margin-left: 15px;
  
}
.div4 p {
  color: #726b67;
  margin-top: 0%;
  margin-left: 5%;
}


/*responsive section*/

@media only screen and (min-width: 320px) and (max-width: 375px) {
  .div1 {
    display: flex;
    flex-direction: column;
  }
  .d1-comps h1 {
    font-size: x-large;
    color: black;
    width: 100%;
  }
  .d1-comps {
    width: 100%;
  }
  .d1-comps p {
    color: black;
    font-size: small;
  }
  .d1-comps .line {
    margin-top: 5px;
    width: 125px;
    border: 2px solid #ff9400;
    background-color: #ff9400;
  }
  .d1-comps hr {
    width: 85px;
    border: 2px solid #ff9400;
    background-color: #ff9400;
  }
  .d-link {
    color: #1396e3;
    font-size: 20px;
    display: flex;
  }
  #d1-comp1 {
    display: flex;
    flex-direction: column;
    margin-left: 0%;
    width: 100%;
  }
  #d1-comp1 > :last-child {
    margin-top: -2%;
  }
  #d1-comp2 {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-left: 0%;
  }
  #d1-comp2 > :last-child {
    margin-top: -2%;
  }

  .d2-comps {
    color: white;
    text-align: center;
    font-size: large;
  }
  .div2 img {
    object-fit: cover;
    width: 100%;
  }
  .d2-comps h1 {
    font-size: x-large;
    text-align: left;
  }
  .d2-comps h1 .line {
    border: 2px solid #ff9400;
    background-color: #ff9400;
    width: 125px;
  }
  .d2-comps p {
    width: 95%;
    font-size: medium;
    text-align: left;
    width: calc(100% - 10px);
  }
  .d2-btns {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 150px;
  }

  .hdiv {
    font-size: x-large;
    text-align: center;
  }
  .d3-hr {
    margin-top: -30px;
    margin-left: 25%;
    width: 50%;
  }
  .div3 h3 {
    color: #015bb9;
    font-size: x-large;
    font-weight: 700;
  }
  .i2 {
    display: none;
  }
  .d-comps {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  .d-comps-flex{
    width: 100%;
  }
  .num-head{
    width: 100%;
  }
  .circle-img img {
    width: 85%;
    height: auto;
  }
  .d-comps-flex > img {
    width: 200px;
    height: auto;
    margin-left: 15%;
  }

  .i2 {
    display: none;
  }
  .d-comps {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  .d-comps-flex > img {
    width: 35%;
    margin-bottom: -15%;
    margin-left: 70%;
  }

  .div4 h3 {
    color: #015bb9;
    font-size: x-large;
    font-weight: 700;
  }
}

@media only screen and (min-width: 375px) and (max-width: 424px) {
  .div1 {
    display: flex;
    flex-direction: column;
  }
  .d1-comps h1 {
    font-size: x-large;
    color: black;
    width: 100%;
  }
  .d1-comps {
    width: 100%;
  }
  .d1-comps p {
    color: black;
    font-size: small;
  }
  .d1-comps .line {
    margin-top: 5px;
    width: 125px;
    border: 2px solid #ff9400;
    background-color: #ff9400;
  }
  .d1-comps hr {
    width: 85px;
    margin-top: -20px;
    border: 2px solid #ff9400;
    background-color: #ff9400;
  }
  .d-link {
    color: #1396e3;
    font-size: 20px;
    display: flex;
  }
  #d1-comp1 {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-left: 0%;
  }
  #d1-comp1 > :last-child {
    margin-top: -2%;
  }
  #d1-comp2 {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-left: 0%;
  }
  #d1-comp2 > :last-child {
    margin-top: -2%;
  }

  .d2-comps {
    color: white;
    text-align: center;
    font-size: large;
  }
  .div2 img {
    object-fit: cover;
    width: 100%;
  }
  .d2-comps h1 {
    font-size: x-large;
    text-align: left;
  }
  .d2-comps h1 .line {
    border: 2px solid #ff9400;
    background-color: #ff9400;
    width: 125px;
  }
  .d2-comps p {
    width: 95%;
    font-size: medium;
    text-align: left;
    width: calc(100% - 10px);
  }
  .d2-btns {
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 150px;
  }

  .hdiv {
    font-size: x-large;
    text-align: center;
  }
  .d3-hr {
    margin-top: -30px;
    margin-left: 25%;
    width: 50%;
  }
  
  .div3 h3 {
    color: #015bb9;
    font-size: x-large;
    font-weight: 700;
  }
  .i2 {
    display: none;
  }
  .d-comps {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  .d-comps-flex{
    width: 100%;
  }
  .num-head{
    width: 100%;
  }
  .circle-img img {
    width: 85%;
    height: auto;
  }
  .d-comps-flex > img {
    width: 200px;
    height: auto;
    margin-left: 15%;
  }

  .div4 h3 {
    color: #015bb9;
    font-size: x-large;
    font-weight: 700;
  }
}

@media only screen and (min-width: 424px) and (max-width: 767px) {
  .div1 {
    display: flex;
    flex-direction: column;
  }
  .d1-comps h1 {
    font-size: x-large;
    color: black;
    width: 100%;
  }
  .d1-comps {
    width: 100%;
  }
  .d1-comps p {
    color: black;
    font-size: small;
  }
  .d1-comps .line {
    margin-top: 5px;
    width: 125px;
    border: 2px solid #ff9400;
    background-color: #ff9400;
  }
  .d1-comps hr {
    width: 85px;
    margin-top: -20px;
    border: 2px solid #ff9400;
    background-color: #ff9400;
  }
  .d-link {
    color: #1396e3;
    font-size: 20px;
    display: flex;
  }
  .arrow-div{
    margin-top: -13px;
}
  #d1-comp1 {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-left: 0%;
  }
  #d1-comp1 > :last-child {
    margin-top: -2%;
  }
  #d1-comp2 {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-left: 0%;
  }
  #d1-comp2 > :last-child {
    margin-top: -2%;
  }

  .d2-comps {
    color: white;
    text-align: center;
    font-size: large;
  }
  .div2 img {
    object-fit: cover;
    width: 100%;
  }
  .d2-comps h1 {
    font-size: x-large;
    text-align: left;
  }
  .d2-comps h1 .line {
    border: 2px solid #ff9400;
    background-color: #ff9400;
    width: 125px;
  }
  .d2-comps p {
    width: 95%;
    font-size: medium;
    text-align: left;
    width: calc(100% - 20px);
  }
  .d2-btns{
    margin-bottom: 15px;
    display: flex;
    gap: 2%;
  }

  .hdiv {
    font-size: x-large;
    text-align: center;
  }
  .d3-hr {
    margin-top: -30px;
    margin-left: 32%;
    width: 37%;
  }
  
  .div3 h3 {
    color: #015bb9;
    font-size: x-large;
    font-weight: 700;
  }
  .i2 {
    display: none;
  }
  .d-comps {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  .d-comps-flex{
    width: 100%;
  }
  .num-head{
    width: 100%;
  }
  .circle-img img {
    width: 40px;
    height: auto;
  }
  .d-comps-flex > img {
    width: 200px;
    height: auto;
    margin-left: 22%;
  }

  .div4 h3 {
    color: #015bb9;
    font-size: x-large;
    font-weight: 700;
  }
}

@media only screen and (min-width: 767px) and (max-width: 1024px) {
  .div1 {
    display: flex;
    flex-direction: column;
  }
  .d1-comps h1 {
    font-size: x-large;
    color: black;
    width: 100%;
  }
  .d1-comps {
    width: 100%;
  }
  .d1-comps p {
    color: black;
    font-size: small;
  }
  .d1-comps .line {
    margin-top: 5px;
    width: 125px;
    border: 2px solid #ff9400;
    background-color: #ff9400;
  }
  .d1-comps hr {
    width: 85px;
    margin-top: -20px;
    border: 2px solid #ff9400;
    background-color: #ff9400;
  }
  .d-link {
    color: #1396e3;
    font-size: 20px;
    display: flex;
  }
  .arrow-div{
       margin-top: -13px;
  }
  #d1-comp1 {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-left: 0%;
  }
  #d1-comp1 > :last-child {
    margin-top: -2%;
  }
  #d1-comp2 {
    display: flex;
    flex-direction: column;
    width: 90%;
    margin-left: 0%;
  }
  #d1-comp2 > :last-child {
    margin-top: -2%;
  }
  .tab-comps{
    display: flex;
    flex-direction: row;
  }
  .d2-comps {
    color: white;
    text-align: center;
    font-size: large;
  }
  .div2 img {
    object-fit: cover;
    width: 100%;
  }
  .d2-comps h1 {
    font-size: x-large;
    text-align: left;
  }
  .d2-comps h1 .line {
    border: 2px solid #ff9400;
    background-color: #ff9400;
    width: 125px;
  }
  .d2-comps p {
    width: 95%;
    font-size: medium;
    text-align: left;
    width: calc(100% - 20px);
  }
  .d2-btns{
    margin-bottom: 15px;
    display: flex;
    gap: 2%;
  }

  .hdiv {
    font-size: x-large;
    text-align: center;
  }
  .d3-hr {
    margin-top: -30px;
    margin-left: 40%;
    width: 20%;
  }
  .div3 h3 {
    color: #015bb9;
    font-size: xx-large;
    font-weight: 700;
  }

  .d-comps {
    display: flex;
    flex-direction: column;
    text-align: left;
  }
  .d-comps-flex{
    width: 100%;
  }
  .num-head{
    width: 100%;
  }
  
  /*2nd point under bussiness and engineer*/
 .d-comps:nth-child(2) .tab-comps:nth-child(2){
    flex-direction: row-reverse;
  }
  /*3rd point under bussiness and engineer*/
  .d-comps:nth-child(4) .tab-comps:nth-child(1){
    flex-direction: row-reverse;
  }

  .circle-img img {
    width: 45px;
    height: auto;
    height: auto;
  }
  .d-comps-flex > img {
    width: 200px;
    height: auto;
    margin-left:35%;
  }

  .div4 h3 {
    color: #015bb9;
    font-size: xx-large;
    font-weight: 700;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1440px) {

  .d-comps-flex {
    overflow: hidden;
    width: 100%;
  }
  .div1 {
    margin: auto;
    margin-top: 50px;
  }
  .d2-comps h1 {
    font-size: 42px;
    text-align: left;
  }
  .d2-comps p {
    font-size: 20px;
    text-align: left;
    width: calc(100% - 20px);
  }
  .div3 {
    width: 100%;
    margin: auto;
    margin-top: 50px;
  }
  .tab-comps{
    display: flex;
    flex-direction: row;
  }
  .div4 {
    width: 100%;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

@media only screen and (min-width: 1440px) and (max-width: 2560px) {
  .div1 {
    max-width: 1440px;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
    border: 1px solid transparent;
  }
  .div2{
    max-width: 2560px;
    margin: auto;
  }
  .div3 {
    max-width: 1440px;
    margin: auto;
    margin-top: 50px;
    
  }
  .darkLine{
    max-width: 1440px;
    margin: auto;
  }
  .tab-comps{
    width: 50%;
    display: flex;
    flex-direction: row;
  }
  .div4 {
    max-width: 1440px;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
  }
}

@media only screen and (min-width: 2560px) {
  .div1 {
    max-width: 1440px;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
    border: 1px solid transparent;
  }
  .div2{
    max-width: 1500px;
    margin: auto;
  }
  .div3 {
    max-width: 1440px;
    margin: auto;
    margin-top: 50px;  
  }
  .darkLine{
    max-width: 1440px;
    margin: auto;
  }
  .tab-comps{
    width: 50%;
    display: flex;
    flex-direction: row;
  }
  .div4 {
    max-width: 1440px;
    margin: auto;
    margin-top: 50px;
    margin-bottom: 50px;
  }
}