body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Poppins', sans-serif;
}

.CircularProgressbar .CircularProgressbar-path {
  stroke: #1a53b2 !important;
  stroke-linecap: round;
  /* background: linear-gradient(to right,#1A53B2,#0140AA) !important; */
  transition: stroke-dashoffset 0.5s ease 0s;
}

.popup-process {
  background-image: linear-gradient(to right, #0140aa 0%, #1a53b2 51%, #0140aa 100%) !important;
}

.cancel-btn {
  background-image: linear-gradient(to right, #ffffff 0%, #ffffff 51%, #ffffff 100%) !important;
  color: black !important;
}

.checked-Process-btn {
  background-color: grey;
}
.checked-Process-btn:hover {
  background-image: linear-gradient(to right, #0140aa 0%, #1a53b2 51%, #0140aa 100%);
}

.admin-Side-login-btn:hover {
  background-color: null !important;
}

.popup-cancel {
  background-image: linear-gradient(to right, #ff8008 0%, #ff8008 100%) !important;
}

.word-break td {
  /* word-break: break-all !important; */
}

.reset-pass-text{
  text-align: center;
}


