@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
.modal-wrapper{
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.23);
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    .modal-card{
        height: calc(100vh - 60px);
        max-width: 993px;
        width: 50%;
        padding: 24px 40px;
        background: #FFFFFF;
        border: 1px solid #000000;
        border-radius: 10px;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 4px;
            border-radius: 5px;
        }

        &::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb {
            background: #888;
            border-radius: 5px;
        }

        &::-webkit-scrollbar-thumb:hover {
            background: #555;
            border-radius: 5px;
        }

        .modal-top{
            display: flex;
            justify-content: flex-end;
            span{
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 35px;
                color: #000000;
                cursor: pointer;
            }
        }

        .modal-header{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 28px;
            line-height: 42px;
            color: #000000;
            display: flex;
            justify-content: space-between;
            align-items: center;
            img{
                height: 100%;
                width: 40%;
            }
        }



        .inputs-wrapper{
            .left{
                // margin-top: 16px;
                width: 50%;
                select{
                    border: 1px solid rgba(0, 0, 0, 0.23);
                    border-radius: 5px;
                    padding: 16.5px 14px;
                    outline: none;
                    width: 100%;
                }
                .left-sub{
                    input{
                        border: 1px solid rgba(0, 0, 0, 0.23);
                        border-radius: 5px;
                        padding: 16.5px 14px;
                        outline: none;
                        width: 100%;
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 18px;
                        color: #818181;
                        background: #FFFFFF;
                    }
                }
            }
            .right{
                display: flex;
                flex-direction: column;
                width: 40%;
                .right-sub{
                    display: flex;
                    flex-direction: column;
                    width: 100%;
                    select{
                        border: 1px solid rgba(0, 0, 0, 0.23);
                        border-radius: 5px;
                        padding: 16.5px 14px;
                        outline: none;
                        width: 100%;
                    }
                    input{
                        border: 1px solid rgba(0, 0, 0, 0.23);
                        border-radius: 5px;
                        padding: 16.5px 14px;
                        outline: none;
                        width: 100%;
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 18px;
                        color: #818181;
                        background: #FFFFFF;
                    }
                }
                .disabled-picker{
                    // user-select: none;
                    cursor: not-allowed;
                }
            }

            textarea{
                width: 100%;
                height: 100px;
                border: 1px solid rgba(0, 0, 0, 0.23);
                border-radius: 5px;
                padding: 16.5px 14px;
                resize: none;
                outline: none;
            }

            .inputs-calc-wrapper{
                display: flex;
                width: 45%;
                &:nth-last-child(1){
                    justify-content: flex-end;
                }
                input{
                    margin-left: 6px;
                    width: 35%;
                    border: 1px solid rgba(0, 0, 0, 0.23);
                    border-radius: 5px;
                    outline: none;
                    padding-left: 8px;
                }
                &.rate-wrapper{
                    span{
                        display: flex;
                        align-items: center;
                        margin-left: 6px;
                        width: 35%;
                        border: 1px solid rgba(0, 0, 0, 0.23);
                        border-radius: 5px;
                        padding-left: 8px;
                        select{
                            outline: none;
                            border: none;
                            border-right: 1px solid #707070;
                            margin-right: 3px;
                        }

                        .hour-rate-wrapper{
                            position: relative;
                            cursor: text;
                            .hour-rate{
                                position: absolute;
                                background: white;
                                width: 96%;
                                overflow: hidden;
                            }
                        }
                    }
                    input{
                        margin-left: 0px;
                        width: 100%;
                        border: none;
                        border-radius: 0px;
                        outline: none;
                        height: 100%;
                        padding-left: 2px;
                    }
                }
                &.right-aligned{
                    flex-direction: column;
                    width: 100%;
                    align-items: flex-end;
                    div{
                        display: flex;
                        justify-content: flex-end;
                    }
                    span{
                        font-family: 'Inter';
                        font-style: normal;
                        font-weight: 400;
                        font-size: 10px;
                        line-height: 18px;
                        color: #818181;
                    }
                }
            }
        }

        .separator{
            border: none;
            border-bottom: 1px solid #707070;
            margin: 16px 0;
        }

        .submit-btn {
          width: fit-content;
          margin: 0 auto;
        }

        .submit-cta{
            background: #3F52B6;
            border-radius: 5px;
            // font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 20px;
            line-height: 30px;
            color: #FFFFFF;
            padding: 5px 17px;
            width: fit-content;
            margin: 0 auto;
            cursor: pointer;
        }
    }

    .tnc-modal{
        padding: 44px;
        background: #FFFFFF;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 10px;
        overflow-y: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        max-width: 460px;

        &::-webkit-scrollbar {
            width: 4px;
            border-radius: 5px;
        }

        span{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 119.99%;
            text-align: center;
            label{
                cursor: pointer;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 400;
                font-size: 16px;
                line-height: 119.99%;
                text-align: center;
                display: flex;
                align-items: center;
                a{
                    margin-left: 4px;
                    text-decoration: none;
                    color: #0543AB;
                }
            }
            &:nth-child(2){
                margin: 12px 0;
            }
        }

        .cta-wrapper{
            display: flex;
            align-items: center;
            gap: 20px;
            button{
                width: fit-content;
                margin: 0;
                &.checked-Process-btn{
                    cursor: not-allowed;
                    user-select: none;
                }
            }
        }
    }
}

div[role=dialog]:has(.MuiPaper-root){
    z-index: 12000;
}

.css-j5h6pi-MuiPopper-root{
    z-index: 20000 !important;
}

.css-3dah0e-MuiModal-root-MuiDialog-root{
    z-index: 20000 !important;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
    .modal-wrapper{

        .modal-card{
            min-width: calc(100vw - 40px);
            .modal-top{

                span{

                }
            }

        }
        div:has(.rate-wrapper){
            flex-direction: column;
            gap: 12px;
            .rate-wrapper{
                width: 100% !important;
            }
        }

        .tnc-modal{
            min-width: calc(100vw - 40px);
            width: calc(100vw - 40px);
        }
    }
}

@media screen and (min-width: 767px) and (max-width: 1024px) {
    .modal-wrapper{

        .modal-card{
            min-width: calc(100vw - 40px);
            width: 85%;
            .modal-top{

                span{

                }
            }
        }
    }
}
