.no-data-wrapper{
    height: calc(100vh - 108px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 470px;
}
.no-data-wrapper>p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #000000;
    margin-top: 17px;
}
.no-data-wrapper>img{
    margin-top: -15%;
    max-width: 90vw;
}

.data-table-wrapper{
    margin-top: 28px;
}

.invoice-id{
    color: #1670C9;
    cursor: pointer;
}

.list-cta-dd-extra{
    position: relative;
    overflow: visible;
    .dd-pdf-cta{
        position: absolute;
        top: 20px;
        right: 28px;
        background: #FFFFFF;
        border: 1px solid #BFBFBF;
        border-radius: 5px;
        display: none;
        padding: 0px;
        padding-bottom: 15px;
        padding-top: 15px;
        z-index: 1;
        a{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            padding: 15px 24px;
            cursor: pointer;
            color: #FF4140;
            text-decoration: none;
            &:hover{
                background: #f7f7f7;
            }
        }
        div{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 21px;
            padding: 15px 24px;
            cursor: pointer;
            color: #0140AA;
            &:hover{
                background: #f7f7f7;
            }
        }
    }
    &:hover{
        cursor: pointer;
        .dd-pdf-cta{
            display: inherit;
        }
    }
}