.no-data-wrapper{
    height: calc(100vh - 108px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 470px;
}
.no-data-wrapper>p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #000000;
    margin-top: 17px;
}
.no-data-wrapper>img{
    margin-top: -15%;
    max-width: 90vw;
}

.data-table-wrapper{
    margin-top: 28px;
}

.invoice-id{
    color: #1670C9;
    cursor: pointer;
}

.admin-select-dd{
    border: 1px solid #000000;
    border-radius: 5px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #000000;
    width: 75%;
}

.btn-cta{
    background: #0543AB;
    border: 1px solid #A7A7A7;
    border-radius: 5px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 21px;
    color: #FFFFFF;
    cursor: pointer;
    padding: 4px 18px;
    &.disabled{
        cursor: not-allowed;
        background-color: grey;
    }
}

.status-dd{
    border: 1px solid #BFBFBF;
    border-radius: 5px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 38px;
    display: flex;
    align-items: center;
    padding: 8px 10px;
    color: #000000;
    margin-top: 6px;
    outline: none;
    option{
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 38px;
    }
}