.MuiButton-root.btn-rehire {
  background-color: #0543ab;
  text-transform: capitalize;
  align-items: center;
  height: 30px;
  border-radius: 4px;
  border: 1px solid #a7a7a7;
  width: 90px;
  color: white;
}

.MuiButton-root.btn-rehire:hover {
  background-color: #0543ab;
}

.MuiButton-root.btn-unhire {
  background-color: #f99600;
  text-transform: capitalize;
  align-items: center;
  height: 30px;
  border-radius: 4px;
  border: 1px solid #a7a7a7;
  width: 90px;
  color: white;
}
.MuiButton-root.btn-unhire:hover {
  background-color: #f99600;
}
