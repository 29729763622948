.review-gig-form-wrapper {
    display: flex;
    flex-direction: column;
    padding-top: calc(64px + 39px);
    margin: 0px 16px;
    .action-btns-top-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        .back-btn-top {
            border-radius: 4px;
            border-style: unset;
            border: 1px solid #f99600;
            background: unset;
            color: #f99600;
            font-style: normal;
            font-family: "Poppins";
            font-weight: 500;
            line-height: 10px;
            font-size: 14px;
            padding-bottom: 5.5px;
            padding-top: 5.5px;
            padding-left: 15.5px;
            padding-right: 15.5px;
            box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
            cursor: pointer;
        }
        .submit-btn-txt {
            padding-left: 24px;
            color: #0140aa;
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            // cursor: pointer;
            line-height: 24px;
        }
    }
    .description-container {
        border: 1px solid #dad7d7;
        border-radius: 10px;
        padding: 17px 14px 43px 25px;
        margin-top: 34px;
        // margin-right: 60px;
        display: flex;
        flex-direction: column;
        .eng-name-title {
            color: #000000;
            font-family: "Inter";
            font-weight: 700;
            font-size: 18px;
            line-height: 21.78px;
            padding-bottom: 10px;
        }
        .eng-name {
            color: #000000;
            font-family: "Inter";
            font-weight: 400;
            font-size: 16px;
            line-height: 19.36px;
            padding-bottom: 29px;
        }
        .project-title-heading-padding {
            padding-bottom: 14px;
        }
        .project-title-txt {
            color: #001e00;
            line-height: 20px;
            letter-spacing: 0.6px;
            padding-bottom: unset;
        }
    }
    .prvt-feedback-container {
        padding: 35px 0px 41px 0px;
        margin-top: 30px;
        .prvt-feedback-padding {
            padding-left: 25px;
        }
        .prvt-feedback-desc-btm-padding {
            padding-bottom: 29px;
        }
        .spacer {
            height: 1px;
            background-color: #dad7d7;
        }
        .reason-for-closing-pvt-padding {
            padding-top: 27px;
            padding-bottom: 19px;
        }
        .reason-for-closing-select {
            margin-left: 25px;
            width: calc(100% - 50px);
            max-width: 384px;
            padding: 14px 12px 14px 12px;
            font-family: "Inter";
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.6px;
            color: #001e00;
            border: 1px solid #7e7e7e;
            border-radius: 5px;
        }
        .please-specify-textarea {
            border: 1px solid #7e7e7e;
            border-radius: 5px;
            margin-left: 25px;
            margin-top: 24px;
            width: 384px;
            padding: 10px 12px 10px 12px;
            font-family: "Inter";
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.6px;
            counter-reset: #000000;
            ::placeholder {
                color: #a1a1a1;
                font-family: "Inter";
                font-size: 16px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0.6px;
            }
        }
        .how-likely-txt-padding {
            padding-top: 43px;
            padding-bottom: 22px;
        }
        .how-likely-heading-text {
            display: flex;
            flex-direction: row;
            margin-bottom: 25px;
            .not-likely-space {
                padding-right: 155px;
            }
        }
        .how-likely-row {
            max-width: 406.67px;
            width: calc(100% - 50px);
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            margin-left: 25px;
            .how-likely-item {
                display: flex;
                cursor: pointer;
                flex-direction: column;
                align-items: center;
                .radio-btn-txt {
                    font-family: "Inter";
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.6px;
                    color: #001e00;
                    padding-top: 5px;
                }
            }
        }
    }
    .public-feedback-container {
        padding: 35px 0px 31px 0px;
        margin-top: 30px;
        .public-feedback-padding {
            margin-left: 25px;
        }
        .pbl-fdbk-heading-txt {
            font-size: 20px;
            line-height: 24.2px;
        }
        .pbl-feedback-desc-btm-padding {
            padding-bottom: 29px;
        }
        .spacer {
            height: 1px;
            background-color: #dad7d7;
        }
        .feedback-to-eng-txt {
            margin-left: 31px;
            padding-top: 27px;
            padding-bottom: 10px;
        }
        .feedback-eng-row {
            display: flex;
            flex-direction: row;
            align-items: center;
            margin-left: 28.5px;
            .stars {
                span {
                    margin-right: 15px;
                }
            }
            .feedback-cat-name {
                padding-left: 24.5px;
                font-family: "Inter";
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.6px;
                color: #001e00;
            }
        }
        .total-txt {
            font-family: "Inter";
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.6px;
            color: #001e00;
            padding-left: 26px;
            padding-top: 35px;
            padding-bottom: 34px;
        }
        .share-exp-txt {
            font-family: "Inter";
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.6px;
            color: #001e00;
            padding-bottom: 20px;
            margin-left: 26px;
        }
        .share-exp-textarea {
            border: 1px solid #7e7e7e;
            border-radius: 5px;
            margin-left: 30px;
            margin-right: 28px;
            font-family: "Inter";
            font-size: 16px;
            font-weight: 400;
            line-height: 20px;
            letter-spacing: 0.6px;
            counter-reset: #000000;
            resize: none;
        }
    }
    .action-btns-btm-container {
        padding: 42.5px 0px 42.5px 30px;
        margin-top: 30px;
        flex-direction: row;
        .cancel-btn {
            border-radius: 5px;
            border-style: unset;
            border: 1px solid #FF8008;
            background: unset;
            color: #FF8008 !important;
            background-image: unset !important;
            font-style: normal;
            font-family: "Poppins";
            font-weight: 500;
            line-height: 24px;
            font-size: 14px;
            margin-left: 20.42px;
            padding: 5.5px 15.5px 5.5px 15.5px;
            filter: drop-shadow(0px 0px 20px #EEEEEE);
            cursor: pointer;
        }
        .submit-btn {
            border-radius: 5px;
            border-style: unset;
            // border: 1px solid #FF8008;
            background: linear-gradient(90deg, #0140AA 0%, #1A53B2 51%, #0140AA 100%);
            color: #ffffff !important;
            // background-image: unset !important;
            font-style: normal;
            font-family: "Poppins";
            font-weight: 500;
            line-height: 24.5px;
            font-size: 14px;
            padding: 5.5px 15.5px 5.5px 15.5px;
            box-shadow: 0px 0px 20px #EEEEEE;
            cursor: pointer;
        }
    }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
    .review-gig-form-wrapper {
        display: flex;
        flex-direction: column;
        padding-top: calc(64px + 39px);
        margin: 0px 16px;
        .action-btns-top-row {
            display: flex;
            flex-direction: row;
            align-items: center;
            .back-btn-top {
                border-radius: 4px;
                border-style: unset;
                border: 1px solid #f99600;
                background: unset;
                color: #f99600;
                font-style: normal;
                font-family: "Poppins";
                font-weight: 500;
                line-height: 10px;
                font-size: 14px;
                padding-bottom: 5.5px;
                padding-top: 5.5px;
                padding-left: 15.5px;
                padding-right: 15.5px;
                box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
                cursor: pointer;
            }
            .submit-btn-txt {
                padding-left: 24px;
                color: #0140aa;
                font-family: "Inter";
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                // cursor: pointer;
                line-height: 24px;
            }
        }
        .description-container {
            border: 1px solid #dad7d7;
            border-radius: 10px;
            padding: 0px;
            padding: 17px 14px;
            margin-top: 34px;
            // margin-right: 60px;
            display: flex;
            flex-direction: column;
            .eng-name-title {
                color: #000000;
                font-family: "Inter";
                font-weight: 700;
                font-size: 18px;
                line-height: 21.78px;
                padding-bottom: 10px;
            }
            .eng-name {
                color: #000000;
                font-family: "Inter";
                font-weight: 400;
                font-size: 16px;
                line-height: 19.36px;
                padding-bottom: 29px;
            }
            .project-title-heading-padding {
                padding-bottom: 14px;
            }
            .project-title-txt {
                color: #001e00;
                line-height: 20px;
                letter-spacing: 0.6px;
                padding-bottom: unset;
            }
        }
        .prvt-feedback-container {
            padding: 35px 0px 41px 0px;
            padding: 17px 14px;
            margin-top: 30px;
            .prvt-feedback-padding {
                padding-left: 0px;
            }
            .prvt-feedback-desc-btm-padding {
                padding-bottom: 29px;
            }
            .spacer {
                height: 1px;
                background-color: #dad7d7;
            }
            .reason-for-closing-pvt-padding {
                padding-top: 27px;
                padding-bottom: 19px;
            }
            .reason-for-closing-select {
                margin-left: 0px;
                width: calc(100%);
                max-width: 384px;
                padding: 14px 12px 14px 12px;
                font-family: "Inter";
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.6px;
                color: #001e00;
                border: 1px solid #7e7e7e;
                border-radius: 5px;
            }
            .please-specify-textarea {
                border: 1px solid #7e7e7e;
                border-radius: 5px;
                margin-left: 0px;
                margin-top: 24px;
                width: 384px;
                padding: 10px 12px 10px 12px;
                font-family: "Inter";
                font-size: 16px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0.6px;
                counter-reset: #000000;
                ::placeholder {
                    color: #a1a1a1;
                    font-family: "Inter";
                    font-size: 16px;
                    font-weight: 400;
                    line-height: 20px;
                    letter-spacing: 0.6px;
                }
            }
            .how-likely-txt-padding {
                padding-top: 43px;
                padding-bottom: 22px;
            }
            .how-likely-heading-text {
                display: flex;
                flex-direction: row;
                margin-bottom: 25px;
                .not-likely-space {
                    padding-right: 155px;
                }
            }
            .how-likely-row {
                max-width: 406.67px;
                width: calc(100% - 50px);
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                margin-left: 0px;
                .how-likely-item {
                    display: flex;
                    cursor: pointer;
                    flex-direction: column;
                    align-items: center;
                    .radio-btn-txt {
                        font-family: "Inter";
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        letter-spacing: 0.6px;
                        color: #001e00;
                        padding-top: 5px;
                    }
                }
            }
        }
        .public-feedback-container {
            padding: 35px 0px 31px 0px;
            padding: 17px 14px;
            margin-top: 30px;
            .public-feedback-padding {
                margin-left: 0px;
            }
            .pbl-fdbk-heading-txt {
                font-size: 20px;
                line-height: 24.2px;
            }
            .pbl-feedback-desc-btm-padding {
                padding-bottom: 29px;
            }
            .spacer {
                height: 1px;
                background-color: #dad7d7;
            }
            .feedback-to-eng-txt {
                margin-left: 0px;
                padding-top: 27px;
                padding-bottom: 10px;
            }
            .feedback-eng-row {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-left: 0px;
                .stars {
                    span {
                        margin-right: 5px;
                        font-size: 18px !important;
                    }
                }
                .feedback-cat-name {
                    padding-left: 0px;
                    font-family: "Inter";
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.6px;
                    color: #001e00;
                }
            }
            .total-txt {
                font-family: "Inter";
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.6px;
                color: #001e00;
                padding-left: 0px;
                padding-top: 35px;
                padding-bottom: 34px;
            }
            .share-exp-txt {
                font-family: "Inter";
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.6px;
                color: #001e00;
                padding-bottom: 20px;
                margin-left: 0px;
            }
            .share-exp-textarea {
                border: 1px solid #7e7e7e;
                border-radius: 5px;
                margin-left: 0px;
                margin-right: 28px;
                font-family: "Inter";
                font-size: 16px;
                font-weight: 400;
                line-height: 20px;
                letter-spacing: 0.6px;
                counter-reset: #000000;
                resize: none;
            }
        }
        .action-btns-btm-container {
            padding: 42.5px 0px 42.5px 30px;
            margin-top: 30px;
            flex-direction: row;
            .cancel-btn {
                border-radius: 5px;
                border-style: unset;
                border: 1px solid #FF8008;
                background: unset;
                color: #FF8008 !important;
                background-image: unset !important;
                font-style: normal;
                font-family: "Poppins";
                font-weight: 500;
                line-height: 24px;
                font-size: 14px;
                margin-left: 0px;
                padding: 5.5px 15.5px 5.5px 15.5px;
                filter: drop-shadow(0px 0px 20px #EEEEEE);
                cursor: pointer;
            }
            .submit-btn {
                border-radius: 5px;
                border-style: unset;
                // border: 1px solid #FF8008;
                background: linear-gradient(90deg, #0140AA 0%, #1A53B2 51%, #0140AA 100%);
                color: #ffffff !important;
                // background-image: unset !important;
                font-style: normal;
                font-family: "Poppins";
                font-weight: 500;
                line-height: 24.5px;
                font-size: 14px;
                padding: 5.5px 15.5px 5.5px 15.5px;
                box-shadow: 0px 0px 20px #EEEEEE;
                cursor: pointer;
            }
        }
    }
}