.admin-list-cta-dd{
    position: relative;
    overflow: visible;
    display: flex;
    justify-content: center;
    align-items: center;
    .admin-cta-list{
        display: none;
        position: absolute;
        z-index: 1299;
        top: 20px;
        right: 10px;
        width: 120px;
        background: #FFFFFF;
        border: 1px solid #BFBFBF;
        border-radius: 5px;
        width: 140px;
        button{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 17px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            border: none;
            background: none;
            cursor: pointer;
            padding: 6px 0;
            z-index: 1;
            width: 100%;
            &:hover{
                background: #d9d9d9;
            }

        }
    }
    &:hover{
        cursor: pointer;
        .admin-cta-list{
            display: flex;
            flex-direction: column;
        }
    }
}

.admin-list-cta-dd-bank{
    position: relative;
    overflow: visible;
    display: flex;
    justify-content: center;
    align-items: center;
    .admin-cta-list-bank{
        display: none;
        position: absolute;
        z-index: 1299;
        bottom: -22px;
        right: 10px;
        background: #FFFFFF;
        border: 1px solid #BFBFBF;
        border-radius: 5px;
        width: 140px;
        height: auto;
        button{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 17px;
            display: flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            border: none;
            background: none;
            cursor: pointer;
            padding: 6px 0;
            z-index: 1;
            width: 100%;
            &:hover{
                background: #d9d9d9;
            }

        }
    }
    &:hover{
        cursor: pointer;
        .admin-cta-list-bank{
            display: flex;
            flex-direction: column;
        }
    }
}


.search-sort-icon{
    display: flex;
}

@media only screen and (min-width: 1400px) and (max-width: 3000px) {
    .search-sort-icon{
        display: flex;
        margin-top: -55px;
    }
} 

.verified-name-ico{
    width: 18px;
}