.no-data-wrapper{
    height: calc(100vh - 108px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 470px;
}
.no-data-wrapper>p{
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 27px;
    color: #000000;
    margin-top: 17px;
}
.no-data-wrapper>img{
    margin-top: -15%;
    max-width: 90vw;
}

.data-table-wrapper{
    margin-top: 28px;
}

.invoice-id{
    color: #1670C9;
    cursor: pointer;
}

.list-cta-dd{
    position: relative;
    overflow: visible;
    .dd-pdf-cta{
        position: absolute;
        top: 20px;
        right: 28px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #FF4140;
        cursor: pointer;
        background: #FFFFFF;
        border: 1px solid #BFBFBF;
        border-radius: 5px;
        padding: 15px 24px;
        display: none;
        text-decoration: none;
        z-index: 1;
        &:hover{
            background: #f7f7f7;
        }
    }
    &:hover{
        cursor: pointer;
        .dd-pdf-cta{
            display: inherit;
        }
    }
}

@media screen and (min-width: 320px) and (max-width: 1023px) {
    .data-table-wrapper{
        margin-top: 28px;
        table{
            display: block;
            width: 100%;
            border-collapse: collapse;
            border-spacing: 0;
            min-width: 100%;
            overflow: scroll;
        }
    }
}