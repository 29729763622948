.applied-heading {
  font-family: Poppins;
  font-size: 21px;
  font-weight: 500;
  text-transform: capitalize;
  margin-top: 0px;
  margin-bottom: 10px;
}

.project-title-applied-gig {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.43;
  text-align: center;
  margin-top: 8px;
  font-family: 'Poppins', sansserif;
  margin: 0;
  color: #392722;
  padding-left: 10px;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.action-wrapper{
  position: relative;
}

.proposal-cta{
  cursor: pointer;
  right: 0;
  margin-left: 7px;
  margin-right: -33px;
}

.proposal-card-cta{
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  gap: 10px;
  color: #0140AA;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  text-decoration: none;
  margin-top: -10px !important;
  margin-bottom: 10px !important;
}

@media only screen and (min-width: 320px) and (max-width: 500px) {
.icon-applied{
  margin-top: 4px;
}
}

.all-contents-text{
  display: flex;
  margin-left: 25px;
  text-align: center;
}

.longer-text{
  width: 230px;
  margin-top: 2px;
}

@media only screen and (min-width: 320px) and (max-width: 380px) {
  .all-contents-text{
    display: flex;
    margin-left: 7px;
    text-align: center;
  }
  .longer-text{
    width: 230px;
    margin-top: 4px;
  }
}

@media only screen and (min-width: 380px) and (max-width: 500px) {
  .all-contents-text{
    display: flex;
    margin-left: 20px;
    text-align: center;
  }
  .longer-text{
    width: 230px;
    margin-top: 3px;
  }
}

@media only screen and (min-width: 500px) and (max-width: 1050px) {
  .all-contents-text{
    display: flex;
    margin-left: 5px;
    text-align: center;
  }
  .longer-text{
    width: 230px;
    margin-top: 3px;
  }
}

.applied-gig-text{
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.icon-text-appled-gig{
  display: flex;
  justify-content: center;
}

.no-record-text-table{
  font-size: 12.5px;
}