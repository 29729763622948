.heading-img {
  position: relative;
  text-align: center;
}
.abt-pic {
  width: 100%;
}
.abt-pic img {
  width: 100%;
}
.abt-cont {
  position: absolute;
  top: 0;
  width: 100%;
  text-align: center;
}
.abt-cont h1 {
  color: white;
  font-weight: 600;
  font-size: 38px;
  top: 0;
  margin-top: 25vh;
}
.heading-img hr {
  width: 175px;
  background-color: #ff9400;
  border: 2px solid #ff9400;
  margin-top: -20px;
}
.heading-img h4 {
  color: white;
  font-size: large;
  font-style: oblique;
}

.collab{
  margin-left: -150px;
}
.collab img{
  width: 600px;
  height: 450px;
}

.value-head {
  color: #ff9400;
  text-align: center;
  margin-bottom: 60px;
  margin-top: -50px;
}
.blueback {
  background-color: #0160bb;
  height: 150px;
  text-align: center;
  margin-bottom: -1%;
}
.blueback h1{
  position: absolute; 
  color: white;
  margin-left: 670px;
}
.blueback hr {
  border: 2px solid #ff9400;
  background-color: #ff9400;
}
#side-img {
  margin-top: 15px;
  width: 365px;
  height: 365px;
}
#side-img img{
  width:365px;
  height:365px;
  object-fit: cover;
}

.imgBorder{
  width:370px;
  height:370px;
  border: 4px solid #ff9400;
  background-color: #ff9400;
}

@media only screen and (min-width: 320px) and (max-width: 375px) {
  .RightArtwork {
    display: none;
  }
  .LeftDotArtwork {
    display: none;
  }
  .RightDotArtwork {
    display: none;
  }
  .heading-img{
    height: 110vh;
  }
  .abt-pic img {
    width: 100%;
    height: 110vh;
    object-fit: cover;
  }
  .blueback h1{
    position: absolute; 
    color: white;
    left: 0;
    margin-left: 25vw;
  }
  #side-img {
    width: 275px;
    height: 275px;
    margin: auto;
  }
  #side-img img{
    width:275px;
    height:275px;
  }
}

@media only screen and (min-width: 375px) and (max-width: 425px) {
  .RightArtwork {
    display: none;
  }
  .LeftDotArtwork {
    display: none;
  }
  .RightDotArtwork {
    display: none;
  }
  .heading-img{
    height: 110vh;
  }
  .abt-pic img {
    width: 100%;
    height: 110vh;
    object-fit: cover;
  }
  .blueback h1{
    position: absolute; 
    color: white;
    left: 0;
    margin-left: 25vw;
  }
  #side-img {
    width: 275px;
    height: 275px;
    margin: auto;
  }
  #side-img img{
    width:275px;
    height:275px;
  }
}

@media only screen and (min-width: 425px) and (max-width: 767px) {
  .RightArtwork {
    display: none;
  }
  .LeftDotArtwork {
    display: none;
  }
  .RightDotArtwork {
    display: none;
  }
  .collab{
    margin-left: auto;
  }
  .heading-img{
    height: 110vh;
  }
  .abt-pic img {
    width: 100%;
    height: 110vh;
    object-fit: cover;
  }
  .blueback h1{
    position: absolute; 
    color: white;
    left: 0;
    margin-left: 25vw;
  }
  #side-img {
    width: 275px;
    height: 275px;
    margin: auto;
  }
  #side-img img{
    width:275px;
    height:275px;
  }
}

@media only screen and (min-width: 767px) and (max-width: 1024px) {
  .RightArtwork {
    display: none;
  }
  .heading-img{
    height: 110vh;
  }
  .collab{
    margin-left: auto;
  }
  .abt-pic img {
    width: 100%;
    height: 110vh;
    object-fit: cover;
  }
  .blueback h1{
    position: absolute; 
    color: white;
    left: 0;
    margin-left: 42%;
  }
  #side-img {
    width: 275px;
    height: 275px;
    margin: auto;
  }
  #side-img img{
    width:275px;
    height:275px;
  }
  .collab{
    margin-left: 10%;
  }
}

@media only screen and (min-width: 1024px) and (max-width: 1440px) {

  .heading-img {
    position: relative;
    text-align: center;
  }
  .abt-pic {
    width: 100%;
  }
  .abt-pic img {
    width: 100%;
  }
  .abt-cont {
    position: absolute;
    top: 0;
    width: 100%;
    text-align: center;
  }
  .abt-cont h1 {
    color: white;
    font-weight: 600;
    font-size: 38px;
    top: 0;
    margin-top: 70px;
  }
  .heading-img hr {
    width: 175px;
    background-color: #ff9400;
    border: 2px solid #ff9400;
    margin-top: -20px;
  }
  .heading-img h4 {
    color: white;
    font-size: large;
    font-style: oblique;
  }
  
  .collab{
    margin-left: -150px;
  }
  .collab img{
    width: 600px;
    height: 450px;
  }
  
  .value-head {
    color: #ff9400;
    text-align: center;
    margin-bottom: 60px;
  }
  .blueback {
    background-color: #0160bb;
    height: 150px;
    text-align: center;
  }
  .blueback h1{
    position: absolute; 
    color: white;
    margin-left: 42%;
  }
  .blueback hr {
    border: 2px solid #ff9400;
    background-color: #ff9400;
  }
  #side-img {
    margin-top: 15px;
    width: 365px;
    height: 365px;
  }
  #side-img img{
    width:365px;
    height:365px;
    object-fit: cover;
  }
  
  .imgBorder{
    width:370px;
    height:370px;
    border: 4px solid #ff9400;
    background-color: #ff9400;
  }
}

 
@media only screen and (min-width: 1440px) and (max-width: 2560px) {
  .heading-img {
    position: relative;
    text-align: center;
  }
  .abt-pic {
    width: 100%;
  }
  .abt-pic img {
    width: 100%;
  }
  .abt-cont {
    position: absolute;
    top: 0;
    width: 100%;
    text-align: center;
  }
  .abt-cont h1 {
    color: white;
    font-weight: 600;
    font-size: 38px;
    top: 0;
    margin-top: 100px;
  }
  .heading-img hr {
    width: 175px;
    background-color: #ff9400;
    border: 2px solid #ff9400;
    margin-top: -20px;
  }
  .heading-img h4 {
    color: white;
    font-size: large;
    font-style: oblique;
  }
  
  .collab{
    margin-left: -150px;
  }
  .collab img{
    width: 600px;
    height: 450px;
  }
  
  .value-head {
    color: #ff9400;
    text-align: center;
    margin-bottom: 60px;
  }
  .blueback {
    background-color: #0160bb;
    height: 150px;
    text-align: center;
  }
  .blueback h1{
    position: absolute; 
    color: white;
    margin-left: 42%;
  }
  .blueback hr {
    border: 2px solid #ff9400;
    background-color: #ff9400;
  }
  #side-img {
    margin-top: 15px;
    width: 365px;
    height: 365px;
  }
  #side-img img{
    width:365px;
    height:365px;
    object-fit: cover;
  }
  
  .imgBorder{
    width:370px;
    height:370px;
    border: 4px solid #ff9400;
    background-color: #ff9400;
  }
}  
@media only screen and (min-width: 2560px){
  .aboutUs-container{
    max-width: 1500px;
    margin: auto;
  }

  .abt-cont h1 {
    color: white;
    font-weight: 600;
    font-size: 38px;
    top: 0;
    margin-top: 150px;
  }

}

.LeftArtwork,
.RightArtwork {
  width: 300px;
  height: 400px;
  border-radius: 75px;
  background: #0160bb;
  background: linear-gradient(90deg, #0160bb 25%, rgba(1, 64, 170, 1) 100%);
  position: absolute;
  transform: rotate(-30deg);
  right: -14%;
  top: 10%;
  z-index: -1;
}
.LeftDotArtwork {
  width: 500px;
  height: 400px;
  background: linear-gradient(90deg, #fff 18px, transparent 1%) center,
  linear-gradient(#fff 18px, transparent 1%) center, #cccccc;
  background-size: 22px 22px;
  position: absolute;
  left: 0;
  top: 0;
  z-index: -1;
}
.RightDotArtwork {
  width: 500px;
  height: 400px;
  background: linear-gradient(90deg, #fff 18px, transparent 1%) center,
  linear-gradient(#fff 18px, transparent 1%) center, #cccccc;
  background-size: 22px 22px;
  position: absolute;
  right: 0;
  top: 0;
  z-index: -1;
  overflow: hidden;
}