div {
  .main-container-url {
    border: 1px solid #c4c4c4;
    border-radius: 4px;
    display: flex;
    width: 100%;
    padding: 4px 12px;
    align-items: center;
    position: relative;
    flex-wrap: wrap;
    .profile-input-container-wrapper{
      display: flex;
      align-items: center;
    }
    .input-container {
      display: flex;
      align-items: center;
    }
    .label {
      position: absolute;
      top: -18px;
      padding: 0 8px;
      background: white;
      font-size: 10px;
      color: #7e84a3;
      font-weight: 400;
      font-family: 'Poppins', sansSerif;
      line-height: 1.4375em;
    }
    .url-copy-origin {
      color: #7e84a3;
      font: inherit;
      font-weight: 400;
      font-family: 'Poppins', sansSerif;
      line-height: 1.4375em;
      font-size: 16px;
      margin-left: 3px;
    }
    .test-feild-text {
      margin-left: 20px;
      width: 405px;
      height: 40px;
      border-radius: 4px;
      font-size: 16px;
    }
    img {
      margin-left: 8px;
      height: 20px;
      width: 20px;
    }
    .right-icon-btn {
      margin-top: 8px;
    }
                                // sx={{ color: '#0140AA', border: '1.5px solid #0140AA', lineHeight: '1', width: '40px', height: '25px', marginLeft: '8px' }}

    .MuiButton-root.url-copy-btn-profile {
      color: #0140aa;
      border: 1.5px solid #0140AA;
      line-height: 1;
      width: 40px;
      height: 25px;
      margin-left: 8px;
      text-transform: capitalize;
      font-size: 14px;
      cursor: pointer;
      padding: 6px 12px;
      border-radius: 4px;
    }
    .MuiButton-root.url-copied-profile{
      color: #fff;
      line-height: 1;
      width: 45px;
      height: 28px;
      margin-left: 8px;
      text-transform: capitalize;
      font-size: 13px;
      cursor: pointer;
      padding: 6px 12px;
      border-radius: 4px;
      background-color: #0140aa;
    }
  }
}

.error-msg-pro-url {
  color: #d32f2f;
  font-weight: 400;
  font-family: 'Poppins', sansserif;
  line-height: 1.66;
  text-align: left;
  margin-top: 3px;
  margin-left: 14px;
  font-size: 12px;
}

.tooltip-msg-url {
  display: flex;
  gap: 5;
  align-items: center;
}

@media only screen and (min-width: 320px) and (max-width: 400px) {
  div {
    .main-container-url {
      .input-container {
        display: table-column;
        padding-bottom: 10px;
      }
      .test-feild-text {
        width: 170px;
        margin-left: 0px;
        margin-top: -10px;
      }
      .right-icon-btn {
        margin-top: 40px;
        margin-left: -60px;
      }
      .url-copy-btn-profile {
        margin-top: 35px;
      }
    }
  }
}

@media only screen and (min-width: 400px) and (max-width: 422px) {
  div {
    .main-container-url {
      .input-container {
        display: table-column;
        padding-bottom: 10px;
      }
      .test-feild-text {
        width: 200px;
        margin-left: 0px;
        margin-top: -10px;
      }
      .right-icon-btn {
        margin-top: 40px;
        margin-left: -60px;
      }
      .url-copy-btn-profile {
        margin-top: 35px;
      }
    }
  }
}

@media only screen and (min-width: 422px) and (max-width: 510px) {
  div {
    .main-container-url {
      .input-container {
        display: table-column;
        padding-bottom: 10px;
      }
      .test-feild-text {
        width: 220px;
        margin-left: 0px;
        margin-top: -10px;
      }
      .right-icon-btn {
        margin-top: 40px;
        margin-left: -17px;
      }
      .url-copy-btn-profile {
        margin-top: 35px;
      }
    }
  }
}

@media only screen and (min-width: 510px) and (max-width: 610px) {
  div {
    .main-container-url {
      .input-container {
        display: table-column;
        padding-bottom: 10px;
      }
      .test-feild-text {
        width: 310px;
        margin-left: 0px;
        margin-top: -10px;
      }
      .right-icon-btn {
        margin-top: 40px;
      }
      .url-copy-btn-profile {
        margin-top: 35px;
      }
    }
  }
}

@media only screen and (min-width: 610px) and (max-width: 710px) {
  div {
    .main-container-url {
      .input-container {
        display: table-column;
        padding-bottom: 10px;
      }
      .test-feild-text {
        width: 405px;
        margin-left: 0px;
        margin-top: -10px;
      }
      .right-icon-btn {
        margin-top: 40px;
      }
      .url-copy-btn-profile {
        margin-top: 35px;
      }
    }
  }
}

@media only screen and (min-width: 710px) and (max-width: 767px) {
  div {
    .main-container-url {
      .input-container {
        display: table-column;
        padding-bottom: 10px;
      }
      .test-feild-text {
        width: 495px;
        margin-left: 0px;
        margin-top: -10px;
      }
      .right-icon-btn {
        margin-top: 40px;
      }
      .url-copy-btn-profile {
        margin-top: 35px;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 850px) {
  div {
    .main-container-url {
      .input-container {
        display: table-column;
        padding-bottom: 10px;
      }
      .test-feild-text {
        width: 310px;
        margin-left: 0px;
        margin-top: -10px;
      }
      .right-icon-btn {
        margin-top: 40px;
      }
      .url-copy-btn-profile {
        margin-top: 35px;
      }
    }
  }
}

@media only screen and (min-width: 850px) and (max-width: 899px) {
  div {
    .main-container-url {
      .input-container {
        display: table-column;
        padding-bottom: 10px;
      }
      .test-feild-text {
        width: 390px;
        margin-left: 0px;
        margin-top: -10px;
      }
      .right-icon-btn {
        margin-top: 40px;
      }
      .url-copy-btn-profile {
        margin-top: 35px;
      }
    }
  }
}

@media only screen and (min-width: 900px) and (max-width: 969px) {
  div {
    .main-container-url {
      .input-container {
        display: table-column;
        padding-bottom: 10px;
      }
      .test-feild-text {
        width: auto;
        margin-left: 0px;
        margin-top: -10px;
      }
      .right-icon-btn {
        margin-top: 40px;
      }
      .url-copy-btn-profile {
        margin-top: 35px;
      }
    }
  }
}

@media only screen and (min-width: 969px) and (max-width: 1100px) {
  div {
    .main-container-url {
      .test-feild-text {
        width: auto;
        margin-left: 2px;
      }
      .right-icon-btn {
        margin-top: 5px;
      }
    }
  }
}

@media only screen and (min-width: 1100px) and (max-width: 1200px) {
  div {
    .main-container-url {
      .test-feild-text {
        width: auto;
        margin-left: 2px;
      }
      .right-icon-btn {
        margin-top: 5px;
      }
    }
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1300px) {
  div {
    .main-container-url {
      .test-feild-text {
        width: auto;
        margin-left: 2px;
      }
      .right-icon-btn {
        margin-top: 5px;
      }
    }
  }
}

@media only screen and (min-width: 1299px) and (max-width: 1340px) {
  div {
    .main-container-url {
      .test-feild-text {
        width: auto;
        margin-left: 2px;
      }
      .right-icon-btn {
        margin-top: 5px;
      }
    }
  }
}

@media only screen and (min-width: 1340px) and (max-width: 1400px) {
  div {
    .main-container-url {
      .test-feild-text {
        width: 265px;
        margin-left: 2px;
      }
      .right-icon-btn {
        margin-top: 5px;
      }
    }
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1500px) {
  div {
    .main-container-url {
      .test-feild-text {
        width: 248px;
        margin-left: 2px;
      }
      .right-icon-btn {
        margin-top: 5px;
      }
    }
  }
}

@media only screen and (min-width: 1500px) and (max-width: 1580px) {
  div {
    .main-container-url {
      .test-feild-text {
        width: 300px;
        margin-left: 17px;
      }
      .right-icon-btn {
        margin-top: 5px;
      }
    }
  }
}

.profile-icon-drag{
  margin-top: 7px;
}

.new-post-editor-profile {
  min-height: 130px;
}

.public-DraftEditorPlaceholder-inner {
  color: #7e84a3;
  font-size: 14px;
  font-family: 'Poppins', sansSerif;
  font-weight: 400;
  line-height: 0.4375em;
}

.profile-icon-drag{
  margin-top: 7px;
}

@media only screen and (min-width: 320px) and (max-width: 900px) {
  .company-details-items {
    display: flex;
    flex-direction: column-reverse;
    margin-left: 28px;
  }
  .company-detail-grid-data {
    margin-top: 37px;
  }
  .text-company-icon {
    font-weight: 600;
    font-size: 19px;
    text-align: center;
  }
}

@media only screen and (min-width: 900px) and (max-width: 2600px) {
  .company-details-items {
    display: flex;
    margin-left: 28px;
  }
  .company-detail-grid-data {
    margin-top: 22px;
  }
  .avtar_profile {
    margin-right: 53px;
    margin-top: 40px;
  }
  .text-company-icon {
    font-weight: 600;
    font-size: 19px;
    margin-right: 48px;
    text-align: center;
    margin-top: 18px;
  }
}

.error-msg-my-profile{
  color: #d32f2f;
  font-family: Poppins;
  font-size: 12px;
  font-weight: 400;
  line-height: 1.66px;
  margin-top: 13px;
 }

 .maximum-limit-my-pro{
  color: red;
 }

 .verified-name-ico{
  width: 18px;
}
