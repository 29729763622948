.cta-wrapper-verify{
    display: flex;
    align-items: center;
    gap: 20px;
    .verify-btn-wrapper{
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 6px;
        height: 100%;
        .verify-btn{
            background: #0140AA;
            border-radius: 4px;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 24px;
            color: #FFFFFF;
            height: 100%;
            border: none;
            padding: 0 20px;
            cursor: pointer;
            &.disabled{
                background: #C1C1C1;
                cursor: not-allowed;
            }
        }
        img{
            height: 20px;
        }
    }
}