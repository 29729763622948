.container {
  max-width: 100%;
  padding-inline: 16px;
  margin-inline: auto;
}

.comingsoon_wrap {
  background: linear-gradient(135deg, #ffffff 35%, transparent 35%) -10px 0,
    linear-gradient(225deg, #ffffff 35%, transparent 35%) -10px 0,
    linear-gradient(315deg, #ffffff 35%, transparent 35%),
    linear-gradient(45deg, #ffffff 35%, transparent 35%);
  background-size: 20px 20px;
  background-color: #f4f4f4;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.cm_logo {
  height: auto;
  width: 250px;
}

.comingsoon_text,
.comingsoon_des {
  font-size: 2.2rem;
  font-weight: 600;
  letter-spacing: 1px;
  color: #0140aa;
  /* font-family: Georgia, 'Times New Roman', Times, serif; */
  line-height: 1.5;
  padding: 0;
  margin: 0;
  margin-top: 2rem;
  text-align: center;
}

.comingsoon_des {
  font-size: 1.2rem;
  font-weight: 400;
  font-family: inherit;
  line-height: 0.5;
  margin-top: 35px;
  color: #b8b8b8;
  text-align: center;
}

.form__content {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  width: 100%;
  margin-top: 55px;
}

.comingsoon_desss {
  width: 60%;
}

@media (min-width: 992px) {
  .comingsoon_desss {
    width: 50%;
  }
}

@media (min-width: 1200px) {
  .comingsoon_desss {
    width: 30%;
  }
}

.button1 Button {
  padding: 15px 10px;
}

.button1 .remove {
  visibility: hidden;
  margin-top: 3px;
  font-size: 12px;
  margin-bottom: 2px;
}

.socialmedia_cm {
  display: flex;
  align-items: center;
  gap: 30px;
  margin-top: 4rem;
  list-style-type: none;
  padding-left: 0;
}

.socialmedia_cm li,
.socialmedia_cm li a {
  font-size: 18px;
  width: 35px;
  height: 35px;
  border: 1px dashed #3a3a3a;
  border-radius: 2px;
  color: #3a3a3a;
  padding: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
}

.socialmedia_cm li:hover {
  background-color: #e2e2e2;
  border-color: #e2e2e2;
  box-shadow: none;
}

@media only screen and (max-width: 600px) {
  .comingsoon_text {
    font-size: 2rem;
  }

  .comingsoon_des {
    font-size: 1rem;
    line-height: 1.5;
  }
}

.comingsoon_wrap .MuiOutlinedInput-input.MuiInputBase-input {
  background: #fff;
}

.comingsoon_wrap .web-icon {
  width: 22px;
  height: 22px;
}

.paraspa {
  font-size: 1rem;
  font-weight: 300;
  text-align: center;
  color: #616161;
}

.tst {
  margin-top: 20px;
  color: orange;
}
