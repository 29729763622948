@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
.modal-wrapper{
    height: 100vh;
    width: 100vw;
    position: absolute;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.23);
    z-index: 10000;
    display: flex;
    justify-content: center;
    align-items: center;
    .modal-card{
        height: calc(100vh - 60px);
        max-width: 993px;
        width: 50%;
        padding: 24px 40px;
        background: #FFFFFF;
        border: 1px solid #000000;
        border-radius: 10px;
        overflow-y: auto;

        &::-webkit-scrollbar {
            width: 4px;
            border-radius: 5px; 
        }
        
        &::-webkit-scrollbar-track {
            background: #f1f1f1;
            border-radius: 5px; 
        }
        
        &::-webkit-scrollbar-thumb {
            background: #888; 
            border-radius: 5px; 
        }
        
        &::-webkit-scrollbar-thumb:hover {
            background: #555; 
            border-radius: 5px; 
        }

        .modal-top{
            display: flex;
            justify-content: flex-end;
            span{
                font-family: 'Inter';
                font-style: normal;
                font-weight: 400;
                font-size: 35px;
                color: #000000;
                cursor: pointer;
            }
        }

        .modal-header{
            font-family: 'Inter';
            font-style: normal;
            font-weight: 400;
            font-size: 28px;
            line-height: 42px;
            color: #000000;
            display: flex;
            justify-content: space-between;
            align-items: center;
            img{
                height: 100%;
                width: 54%;
            }
            .eft-header-info{
                font-size: 13px;
                margin: 0 20px;
                margin-top: 20px;
                margin-bottom: -28px;
                width: 100%;
                font-weight: bold;
                font-style: italic;
            }
        }


        .confirm-cta{
            background: #3F52B6;
            border-radius: 5px;
            color: #FFFFFF;
            padding: 5px 17px;
            width: fit-content;
            margin: 40px auto;
            cursor: pointer;
            border: none;
            font-family: 'Poppins',sansSerif;
            font-weight: 500;
            font-size: 0.875rem;
            line-height: 1.75;
            text-transform: uppercase;
            &.disabled{
                cursor: not-allowed;
                background: #7E84A3;
            }
        }


        .etf-input-wrapper{
            margin: 0 20px;
            margin-top: 40px;
            p{
                margin: 0;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 300;
                font-size: 14px;
                line-height: 27px;    
                color: #000000;
                text-transform: uppercase;
            }
            span{
                display: flex;
                align-items: center;
                justify-content: space-between;
                input{
                    border: 1px solid #000000;
                    border-radius: 10px;
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 30px;
                    padding: 6px;
                    color: #000000;
                    width: calc(100% - 48px);
                    background: white;
                }
                img{
                    height: 35px;
                    cursor: pointer;
                    &.hidden{
                        display: none;
                    }
                    &.visible{
                        display: inherit;
                    }
                }
            }
        }
    }
}

.css-j5h6pi-MuiPopper-root, .css-1mtsuo7{
    z-index: 20000 !important;
}

.css-3dah0e-MuiModal-root-MuiDialog-root{
    z-index: 20000 !important;
}

@media screen and (min-width: 320px) and (max-width: 767px) {
    .modal-wrapper{
        
        .modal-card{
            min-width: calc(100vw - 40px);

        }
    }
}