.proposal-page-wrapper{
    margin-top: 38px;
    background: #FFFFFF;
    border: 1px solid #DAD7D7;
    border-radius: 10px;
    padding: 36px;
    margin-bottom: 28px;
    overflow: auto;
    table{
        min-width: 600px;
        tr{
            th{
                display: flex;
            }
            td{
                div{
                    margin-top: -13px;
                    }
                a{
                    color: #0543AC;
                    text-decoration: none;
                }
            }
        }
    }
}