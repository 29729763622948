.reviews-wrapper {
    border: 1px solid #dad7d7;
    border-radius: 10px;
    padding: 20px;
    margin-top: 30px;
    margin-bottom: 30px;
    .reviews-heading {
        font-family: "Poppins";
        color: #000000;
        font-weight: 700;
        font-size: 18px;
        line-height: 35px;
        margin: 8px 0px 18px 0px;
    }
    .reviews-heading-gig-applied-eng {
        margin: 8px 0px 6px 0px;
    }
    .completed-gigs-txt {
        font-family: "Poppins";
        color: #000000;
        font-weight: 500;
        font-size: 14px;
        line-height: 35px;
    }
    .completed-gigs-txt-underline {
        border: 1px solid #000000;
        width: 125px;
    }
    .spacer {
        border: 1px solid #b4b1b1;
    }
    .review-gig-name {
        font-family: "Poppins";
        font-weight: 400;
        font-size: 14px;
        line-height: 35px;
        color: #000000;
        margin: 14px 0px 5px 0px;
    }
    .stars-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        .stars {
            span {
                margin-right: 10px;
            }
        }
        .stars-count-txt {
            font-family: "Inter";
            font-weight: 700;
            font-size: 14px;
            line-height: 20px;
            color: #001e00;
            margin-left: 12px;
        }
    }
    .feedback-txt {
        font-family: "Inter";
        font-style: italic;
        font-weight: 400;
        font-size: 14px;
        line-height: 28px;
        color: #000000;
        margin: 5px 0px 14px 0px;
    }
    .see-more {
        font-family: "Poppins";
        font-weight: 400;
        font-size: 14px;
        line-height: 35px;
        color: #0140aa;
        cursor: pointer;
    }
}
.reviews-wrapper-bottom-padding {
    margin-bottom: 40px;
}