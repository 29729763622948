.project-title-saved-gig {
  font-size: 16px;
  font-weight: 600;
  line-height: 1.43;
  text-align: center;
  margin-top: 8px;
  font-family: 'Poppins', sansserif;
  margin: 0;
  color: #392722;
  padding-left: 10px;
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.saved-gig-text{
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.no-data-gig{
  margin-top: 110px;
  justify-content: center;
  align-items: center;
  text-align: center;
}

.no-record-text-table{
  font-size: 12.5px;
}