@media only screen and (min-width: 320px) and (max-width: 900px) {
  .company-details-items {
    display: flex;
    flex-direction: column-reverse;
    margin-left: 16px;
  }
  .company-detail-grid-data {
    margin-top: 37px;
  }
  .text-company-icon {
    font-weight: 600;
    font-size: 19px;
    text-align: center;
  }
}

@media only screen and (min-width: 900px) and (max-width: 2600px) {
  .company-details-items {
    display: flex;
    margin-left: 28px;
  }
  .company-detail-grid-data {
    margin-top: 22px;
  }
  .avtar_profile {
    margin-right: 53px;
    margin-top: 40px;
  }
  .text-company-icon {
    font-weight: 600;
    font-size: 19px;
    margin-right: 48px;
    text-align: center;
    margin-top: 18px;
  }
}

@media only screen and (min-width: 320px) and (max-width: 2000px) {
.location-items{
  margin-left: 11px;
}
}
