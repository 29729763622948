.details-wrapper{
    margin-top: 38px;
    background: #FFFFFF;
    border: 1px solid #DAD7D7;
    border-radius: 10px;
    padding: 36px;
    margin-bottom: 28px;
    .details-heading{
        display: flex;
        align-items: center;
        justify-content: space-between;
        span{
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 700;
            font-size: 22px;
            line-height: 38px;
            color: #000000;
            display: flex;
            align-items: center;
            img{
                cursor: pointer;
            }
        }
        .label-wrapper{
            display: flex;
            align-items: center;
            a{
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
                margin-left: -1px;
            }
        }
        .status{
            color: #F9D100;
            &.Paid{
                color: #63A46C;
            }
            &.Rejected{
                color: #FF4140;
            }
            &.Pending{
                color: #F9D100;
            }
        }
    }

    .data-info-wrapper{
        display: flex;
        justify-content: space-between;
        .details-left{
            width: 33%;
        }
        .details-right{
            width: 33%;
            p{
                text-align: right;
            }
        }
    }

    .details-table{
        overflow: auto;
        .container {  
            display: grid;
            min-width: 650px;
            padding: 0;
            margin-top: 38px;
            grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
            grid-template-rows: 1fr 1fr 1fr 1fr;
            gap: 0px 0px;
            grid-auto-flow: row;
            grid-template-areas:
              "GigName Description Hourly-Rate Hours-Worked Amount"
              "GigText DescText HourText HwText AmountText"
              ". . . SummaryText Summary"
              ". . . TotalText Total";
            div{
                border: 1px solid #BFBFBF;
                overflow-wrap: anywhere;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
            }
            .Summary { grid-area: Summary; }
            
            .Total { grid-area: Total; }
            
            .GigName { grid-area: GigName; }
            
            .Description { grid-area: Description; }
            
            .Hourly-Rate { grid-area: Hourly-Rate; }
            
            .Hours-Worked { grid-area: Hours-Worked; }
            
            .Amount { grid-area: Amount; }
            
            .GigText { grid-area: GigText;
                span{
                    height: 54px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 10vw;
                    display: block;
                    padding-top: 15px;
                    cursor: help;
                }
            }
            
            .DescText { grid-area: DescText;
                span{
                    height: 54px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 10vw;
                    display: block;
                    padding-top: 15px;
                    cursor: help;
                }
            }
            
            .HourText { grid-area: HourText; }
            
            .HwText { grid-area: HwText; }
            
            .AmountText { grid-area: AmountText; }

            .Rejection-Reason { grid-area: Rejection-Reason; }

            .RRText { grid-area: RRText;
                span{
                    height: 54px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    width: 10vw;
                    display: block;
                    padding-top: 15px;
                    cursor: help;
                }
            }
    
            .SummaryText { 
                grid-area: SummaryText;
                border: none;
                display: flex;
                justify-content: flex-end;
                padding-right: 12px;
                p{
                    text-align: right;
                }
            }
            .TotalText {
                grid-area: TotalText;
                border: none;
                display: flex;
                justify-content: flex-end;
                padding-right: 12px;
            }


            &.rejected-grid-container{
                display: grid;
                padding: 0;
                margin-top: 38px;
                grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
                grid-template-rows: 1fr 1fr 1fr 1fr;
                gap: 0px 0px;
                grid-auto-flow: row;
                grid-template-areas:
                  "GigName Description Hourly-Rate Hours-Worked Rejection-Reason Amount"
                  "GigText DescText HourText HwText RRText AmountText"
                  ". . . . SummaryText Summary"
                  ". . . . TotalText Total";
            }
        }
          
          
    }

    .cta-container{
        display: flex;
        justify-content: flex-end;
        margin-top: 12px;
    }
}

.edit-icon-engineer-side{
    margin-top: -7px;
}