.see-more-text {
  display: contents;
  width: 100%;
}

.read-or-hide {
  color: #1976d2;
  cursor: pointer;
}

.stars-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  .stars {
      span {
          margin-right: 10px;
      }
  }
  .stars-count-txt {
      font-family: "Inter";
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      color: #001e00;
      margin-left: 12px;
  }
}

.btns-wrapper{
  display: flex;
  justify-content: space-around;
  .btns-cta {
    background: #0140AA;
    border: 1px solid #0140AA;
    border-radius: 4px;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;
    color: #ffffff;
    padding: 2px 25px;
    cursor: pointer;
    // max-width: 40%;
    &.reject{
      background: #ffffff;
      color: #0140AA;
    }
    &.verified{
      background: #ADB9CD;
      border: 1px solid #ADB9CD;
      cursor: not-allowed;
    }
    &.rejected{
      background: rgba(249, 150, 0, 0.5);
      border: 1px solid rgba(249, 150, 0, 0.5);
      cursor: not-allowed;
    }
  }
}


.verify-btn-cta {
  background: #007fed;
  box-shadow: 0px 0px 20px #eeeeee;
  border-radius: 5px;
  border: none;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #ffffff;
  padding: 8px 25px;
  margin: 0 18px;
  cursor: pointer;
}

.verified-name-ico {
  margin-left: 6px;
}

@media only screen and (min-width: 899px) and (max-width: 1100px) {
  .name-title-overview-align {
    margin-left: 86px;
  }
}

@media only screen and (min-width: 1000px) and (max-width: 1100px) {
  .name-title-overview-align {
    margin-left: 65px;
  }
}

@media only screen and (min-width: 1098px) and (max-width: 1200px) {
  .name-title-overview-align {
    margin-left: 65px;
  }
}

@media only screen and (min-width: 1198px) and (max-width: 1300px) {
  .name-title-overview-align {
    margin-left: 50px;
  }
}

@media only screen and (min-width: 1298px) and (max-width: 1365px) {
  .name-title-overview-align {
    margin-left: 10px;
  }
}

.text-city-overflow {
  margin-left: 10px;
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  margin-top: 4px;
}

@media only screen and (min-width:300px) and (max-width:500px) {
  .availability-skill-items{
  margin-left: -23px;
  width: 107%;
}
}

@media only screen and (min-width:500px) and (max-width:800px) {
  .availability-skill-items{
    margin-left: -25px;
    width: 282%;
  }
}
.verified-name-ico{
  width: 18px;
}

.gig-details-page-loader{
  margin-top: 150px;
}
