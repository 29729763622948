.view-feedback-form-wrapper {
    display: flex;
    flex-direction: column;
    padding-top: calc(64px + 39px);
    margin: 0px 16px;
    .action-btns-top-row {
        display: flex;
        flex-direction: row;
        align-items: center;
        .back-btn-top {
            border-radius: 4px;
            border-style: unset;
            border: 1px solid #f99600;
            background: unset;
            color: #f99600;
            font-style: normal;
            font-family: "Poppins";
            font-weight: 500;
            line-height: 10px;
            font-size: 14px;
            padding-bottom: 5.5px;
            padding-top: 5.5px;
            padding-left: 15.5px;
            padding-right: 15.5px;
            box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
            cursor: pointer;
        }
        .page-heading-txt {
            padding-left: 24px;
            color: #0140aa;
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 20px;
            // cursor: pointer;
            line-height: 24px;
        }
    }
    .type-of-feedback-txt {
        font-family: "Poppins";
        font-weight: 500;
        font-size: 18px;
        line-height: 35px;
        color: #0140aa;
        margin-top: 40px;
        margin-bottom: 25px;
    }
    .feedback-container {
        border: 1px solid #dad7d7;
        border-radius: 10px;
        padding: 25px 35px 30px 31px;
        margin-top: 35.4px;
        // margin-right: 53px;
        // margin-left: 34px;
        display: flex;
        flex-direction: column;
        .star-rating-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            .posted-on {
                display: flex;
                justify-content: end;
                font-family: "Inter";
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.6px;
                color: #4b524b;
                margin-top: 10px;
            }
            .star-rating-column {
                display: flex;
                flex-direction: column;
                .feedback-to-type-txt {
                    font-family: "Poppins";
                    font-weight: 700;
                    font-size: 16px;
                    line-height: 20px;
                    margin-bottom: 20px;
                    margin-top: 10px;
                    color: #000000;
                }
                .star-rating-row {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                    // margin-left: 28.5px;
                    .stars {
                        display: flex;
                        span {
                            margin-right: 15px;
                        }
                    }
                    .rating-cat-name {
                        padding-left: 20.5px;
                        font-family: "Inter";
                        font-weight: 400;
                        font-size: 16px;
                        line-height: 20px;
                        letter-spacing: 0.6px;
                        color: #001e00;
                    }
                }
            }
        }
        .total-txt {
            font-family: "Inter";
            font-weight: 700;
            font-size: 16px;
            line-height: 20px;
            letter-spacing: 0.6px;
            color: #001e00;
            padding-top: 23px;
            padding-bottom: 14px;
        }
        .feedback-txt {
            font-family: "Poppins";
            font-weight: 400;
            font-style: italic;
            font-size: 16px;
            line-height: 28.5px;
            letter-spacing: 0.6px;
            color: #000000;
        }
        .spacer {
            border: 1px solid #9a9a9a;
            margin-top: 32px;
            margin-bottom: 30px;
        }
        .spacer-pvt {
            border: 1px solid #9a9a9a;
            margin-top: 33px;
            margin-bottom: 17px;
        }
        .reason-for-closing {
            margin-top: 12px;
            font-family: "Poppins";
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #000000;
        }
        .how-likely-txt {
            font-family: "Inter";
            font-weight: 400;
            font-size: 16px;
            line-height: 20px;
            color: #001e00;
            margin-top: 28px;
            margin-bottom: 22px;
        }
        .how-likely-heading-text {
            display: flex;
            flex-direction: row;
            margin-bottom: 25px;
            .how-likely-pointers {
                font-family: "Inter";
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.6px;
                color: #001e00;
            }
            .not-likely-space {
                padding-right: 155px;
            }
        }
        .how-likely-row {
            width: 406.67px;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            // margin-left: 25px;
            .how-likely-item {
                display: flex;
                cursor: pointer;
                flex-direction: column;
                align-items: center;
                .radio-btn-txt {
                    font-family: "Inter";
                    font-weight: 400;
                    font-size: 14px;
                    line-height: 20px;
                    letter-spacing: 0.6px;
                    color: #001e00;
                    padding-top: 5px;
                }
            }
        }
    }
    .remove-top-padding {
        margin-top: 0px;
    }
    .publish-btn {
        width: min-content;
        border-radius: 5px;
        border-style: unset;
        background: linear-gradient(90deg, #0140aa 0%, #1a53b2 51%, #0140aa 100%);
        color: #ffffff !important;
        font-style: normal;
        font-family: "Poppins";
        font-weight: 500;
        line-height: 24.5px;
        font-size: 14px;
        padding: 5.5px 28px 5.5px 28px;
        box-shadow: 0px 0px 20px #eeeeee;
        margin-top: 35px;
        cursor: pointer;
        &.inactive{
            cursor: not-allowed;
        }
    }
}

@media screen and (min-width: 320px) and (max-width: 480px) {
    .view-feedback-form-wrapper {
        display: flex;
        flex-direction: column;
        padding-top: calc(64px + 39px);
        margin: 0px 16px;
        .action-btns-top-row {
            display: flex;
            flex-direction: row;
            align-items: center;
            .back-btn-top {
                border-radius: 4px;
                border-style: unset;
                border: 1px solid #f99600;
                background: unset;
                color: #f99600;
                font-style: normal;
                font-family: "Poppins";
                font-weight: 500;
                line-height: 10px;
                font-size: 14px;
                padding-bottom: 5.5px;
                padding-top: 5.5px;
                padding-left: 15.5px;
                padding-right: 15.5px;
                box-shadow: 0px 2px 6px rgba(0, 0, 0, 0.16);
                cursor: pointer;
            }
            .page-heading-txt {
                padding-left: 24px;
                color: #0140aa;
                font-family: "Inter";
                font-style: normal;
                font-weight: 600;
                font-size: 20px;
                // cursor: pointer;
                line-height: 24px;
            }
        }
        .type-of-feedback-txt {
            font-family: "Poppins";
            font-weight: 500;
            font-size: 18px;
            line-height: 35px;
            color: #0140aa;
            margin-top: 40px;
            margin-bottom: 25px;
        }
        .feedback-container {
            border: 1px solid #dad7d7;
            border-radius: 10px;
            padding: 25px 35px 30px 31px;
            padding: 17px 14px;
            margin-top: 35.4px;
            margin-right: 0px;
            // margin-left: 34px;
            display: flex;
            flex-direction: column;
            .star-rating-wrapper {
                display: flex;
                flex-direction: column-reverse;
                justify-content: space-between;
                .posted-on {
                    display: flex;
                    justify-content: flex-start;
                    margin-bottom: 16px;
                    font-family: "Inter";
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 20px;
                    letter-spacing: 0.6px;
                    color: #4b524b;
                    margin-top: 10px;
                }
                .star-rating-column {
                    display: flex;
                    flex-direction: column;
                    .feedback-to-type-txt {
                        font-family: "Poppins";
                        font-weight: 700;
                        font-size: 16px;
                        line-height: 20px;
                        margin-bottom: 20px;
                        margin-top: 10px;
                        color: #000000;
                    }
                    .star-rating-row {
                        display: flex;
                        flex-direction: row;
                        align-items: center;
                        // margin-left: 28.5px;
                        .stars {
                            span {
                                margin-right: 5px;
                                font-size: 18px !important;
                            }
                        }
                        .rating-cat-name {
                            padding-left: 20.5px;
                            font-family: "Inter";
                            font-weight: 400;
                            font-size: 14px;
                            line-height: 20px;
                            letter-spacing: 0.6px;
                            color: #001e00;
                        }
                    }
                }
            }
            .total-txt {
                font-family: "Inter";
                font-weight: 700;
                font-size: 16px;
                line-height: 20px;
                letter-spacing: 0.6px;
                color: #001e00;
                padding-top: 23px;
                padding-bottom: 14px;
            }
            .feedback-txt {
                font-family: "Poppins";
                font-weight: 400;
                font-style: italic;
                font-size: 16px;
                line-height: 28.5px;
                letter-spacing: 0.6px;
                color: #000000;
            }
            .spacer {
                border: 1px solid #9a9a9a;
                margin-top: 32px;
                margin-bottom: 30px;
            }
            .spacer-pvt {
                border: 1px solid #9a9a9a;
                margin-top: 33px;
                margin-bottom: 17px;
            }
            .reason-for-closing {
                margin-top: 12px;
                font-family: "Poppins";
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                color: #000000;
            }
            .how-likely-txt {
                font-family: "Inter";
                font-weight: 400;
                font-size: 16px;
                line-height: 20px;
                color: #001e00;
                margin-top: 28px;
                margin-bottom: 22px;
            }
            .how-likely-heading-text {
                display: flex;
                flex-direction: row;
                margin-bottom: 25px;
                .how-likely-pointers {
                    font-family: "Inter";
                    font-weight: 400;
                    font-size: 16px;
                    line-height: 20px;
                    letter-spacing: 0.6px;
                    color: #001e00;
                }
                .not-likely-space {
                    padding-right: 155px;
                }
            }
            .how-likely-row {
                width: 406.67px;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                // margin-left: 25px;
                .how-likely-item {
                    display: flex;
                    cursor: pointer;
                    flex-direction: column;
                    align-items: center;
                    .radio-btn-txt {
                        font-family: "Inter";
                        font-weight: 400;
                        font-size: 14px;
                        line-height: 20px;
                        letter-spacing: 0.6px;
                        color: #001e00;
                        padding-top: 5px;
                    }
                }
            }
        }
        .remove-top-padding {
            margin-top: 0px;
        }
        .publish-btn {
            width: min-content;
            border-radius: 5px;
            border-style: unset;
            background: linear-gradient(90deg, #0140aa 0%, #1a53b2 51%, #0140aa 100%);
            color: #ffffff !important;
            font-style: normal;
            font-family: "Poppins";
            font-weight: 500;
            line-height: 24.5px;
            font-size: 14px;
            padding: 5.5px 28px 5.5px 28px;
            box-shadow: 0px 0px 20px #eeeeee;
            margin-top: 35px;
            cursor: pointer;
        }
    }
}