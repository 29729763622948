.eng-dashboard-text{
    margin-left: 3px;
}

.discover-gig-icon{
    margin-left: -3px;
}

.AppliedGigs-gig-icon{
    margin-left: -2px;
}

.SavedGigs-gig-icon{
    margin-left: -4px;
}

.ChatsBusiness-gig-icon{
    margin-left: -1px;
}

.business-dashboard-text{
    margin-left: 4px;
}